<div class="overflow-auto w-100">
  <mat-card class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>
        Compliance Remediation
      </mat-card-title>
      <mat-card-subtitle>GPO policies can be used to ensure that all computers within an organization adhere to specific compliance requirements.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="loading">
        <span class="fs1 fw3 mr-2"> Loading...</span>
      </ng-container>
      <ng-container *ngIf="!loading">
        <table class="rounded-corners table w-70p">
          <thead>
            <tr>
              <th class="header-color"></th>
              <th class="header-color">Operating System</th>
              <th class="header-color">Download GPO Policy</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let gpo of gpoPolicies; let i = index;">
              <td width="5%"><img [src]="winLogo" class="providers-logo" [alt]="gpo.os"></td>
              <td>{{gpo.os}}</td>
              <td>
               <button  mat-raised-button color="primary" 
                        (click)="selectedGpo = gpo;modalService.open('disclaimer')">
                <mat-icon class="mat-18">file_download</mat-icon> Download
              </button></td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
<s-modal id="disclaimer">
  <mat-card class="modal w600">
    <button type="button" class="float-right mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Back"
            (click)="isAgreed = false;modalService.close('disclaimer')">
      <mat-icon>close</mat-icon>
    </button>
    <mat-card-header>
    <mat-card-title><b>Compliance Remediation Script Disclaimer:</b></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="disclaimer-content">
        <p>
          This PowerShell script is intended for the purpose of automating compliance remediation tasks within your organization's IT infrastructure.
          By executing this script, you acknowledge and agree to the following:
        </p>
        <ol>
          <li>The purpose of this script is to bring systems or configurations into compliance with established organizational policies and standards.</li>
          <li>While every effort has been made to ensure the script's accuracy and safety, it is provided "as is" without any warranties or guarantees of any kind.</li>
          <li>The author(s) of this script shall not be held liable for any unintended consequences, data loss, or disruptions that may occur as a result of running this script.</li>
          <li>You should thoroughly review and understand the functionality of this script and its potential impact on your systems and network environment before executing it.</li>
          <li>You are responsible for taking appropriate precautions, including creating backups and verifying the script's behavior in a non-production environment before deploying it in a production setting.</li>
          <li>This script may make changes to system settings, configurations, or data. Ensure you have proper authorization and adhere to your organization's change control processes and policies when using this script.</li>
          <li>It is advisable to consult with your organization's compliance and security teams, as well as seek appropriate approvals, before running this script in a production environment.</li>
        </ol>
        <p >
          By proceeding with the execution of this script, you confirm that you have read and understood this disclaimer, and you accept full responsibility for its use.
          If you do not agree with these terms or are unsure about the script's effects, do not proceed with its execution.
        </p>
        <mat-checkbox class="mr20" id="agree-checkbox"  name="agreeCheckbox"  color="primary"
              [(ngModel)]="isAgreed" >I agree
        </mat-checkbox >
      </div>
    </mat-card-content>
    <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
      <button class="mat-raised-button mr-3" (click)="isAgreed = false;modalService.close('disclaimer')" mat-button>Cancel</button>
      <button class="mat-raised-button" mat-button color="primary" (click)="downloadPolicy()"
        [disabled]="!isAgreed">Download</button>
    </mat-card-footer>
  </mat-card>
</s-modal>