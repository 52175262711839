/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Extension } from '../models/extension';
import { ExtensionCreate } from '../models/extension-create';

@Injectable({
  providedIn: 'root',
})
export class ExtensionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiExtensionGet
   */
  static readonly GetAllApiExtensionGetPath = '/api/Extension/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiExtensionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiExtensionGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<Extension>> {

    const rb = new RequestBuilder(this.rootUrl, ExtensionService.GetAllApiExtensionGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Extension>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiExtensionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiExtensionGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<Extension> {

    return this.getAllApiExtensionGet$Response(params).pipe(
      map((r: StrictHttpResponse<Extension>) => r.body as Extension)
    );
  }

  /**
   * Path part for operation updateApiExtensionPut
   */
  static readonly UpdateApiExtensionPutPath = '/api/Extension/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiExtensionPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiExtensionPut$Response(params: {
    body: Extension
  }): Observable<StrictHttpResponse<Extension>> {

    const rb = new RequestBuilder(this.rootUrl, ExtensionService.UpdateApiExtensionPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Extension>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiExtensionPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiExtensionPut(params: {
    body: Extension
  }): Observable<Extension> {

    return this.updateApiExtensionPut$Response(params).pipe(
      map((r: StrictHttpResponse<Extension>) => r.body as Extension)
    );
  }

  /**
   * Path part for operation createApiExtensionPost
   */
  static readonly CreateApiExtensionPostPath = '/api/Extension/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiExtensionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiExtensionPost$Response(params: {
    body: ExtensionCreate
  }): Observable<StrictHttpResponse<Extension>> {

    const rb = new RequestBuilder(this.rootUrl, ExtensionService.CreateApiExtensionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Extension>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiExtensionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiExtensionPost(params: {
    body: ExtensionCreate
  }): Observable<Extension> {

    return this.createApiExtensionPost$Response(params).pipe(
      map((r: StrictHttpResponse<Extension>) => r.body as Extension)
    );
  }

  /**
   * Path part for operation getApiExtensionIdGet
   */
  static readonly GetApiExtensionIdGetPath = '/api/Extension/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiExtensionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiExtensionIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Extension>> {

    const rb = new RequestBuilder(this.rootUrl, ExtensionService.GetApiExtensionIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Extension>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiExtensionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiExtensionIdGet(params: {
    id: string;
  }): Observable<Extension> {

    return this.getApiExtensionIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Extension>) => r.body as Extension)
    );
  }

  /**
   * Path part for operation deleteApiExtensionIdDelete
   */
  static readonly DeleteApiExtensionIdDeletePath = '/api/Extension/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiExtensionIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiExtensionIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ExtensionService.DeleteApiExtensionIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiExtensionIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiExtensionIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiExtensionIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
