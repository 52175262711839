import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Asset} from '../../api/models/asset';
import {VulnerabilityService} from '../../api/services/vulnerability.service';
import {MatSidenav} from '@angular/material/sidenav';
import {CompanyService} from '../../api/services/company.service';
import {LoaderService} from '../../_services/loader.service';
import {BaseRequestService} from '../../_services/base.service';
import {Subscription} from 'rxjs';
import {CommonService} from '../../_services/common.services';
import {Sort} from '@angular/material/sort';
import {ModalService} from '../../_services/modal.service';
import {MyToastrService} from '../../_services/toastr.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {IntegrationActionsService} from '../../_services/integration-actions.service';

@Component({
  selector: 'app-networks-scan',
  templateUrl: './networks-scan.component.html',
  styleUrls: ['./networks-scan.component.scss']
})
export class NetworksScanComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  @Input() mode: any;
  @Input() currentCompany: any;
  @Output() dataBack = new EventEmitter();
  currentAssets: any;
  networkScanQuery: any = {};
  sortedData: any = [];
  asortedData: any = [];
  GsortedData: any = [];
  selectAll = false;
  tabIndex = 0;
  rmFilter: any;
  prodHash: any = {};
  currentNs: any;
  hostList: any;
  vulsList: any;
  currentGNs: any;
  companyList: any;
  currentGRP: any;
  noAuthvulsColOptions: any = {};
  currentAsset: Asset = {};
  severityHtmlCols = {
    Critical: `<span class=" fw4  px-1 badge scritical">CRITICAL</span>`,
    High: `<span class=" fw4  px-1 badge shigh">HIGH</span>`,
    Medium: `<span class=" fw4  px-1 badge smedium">MEDIUM</span>`,
    Low: `<span class=" fw4  px-1 badge slow">LOW</span>`,
    Info: `<span class=" fw4  px-1 badge sinfo">INFO</span>`
  };
  networkScan: any;
  globalnetworkScan: any;
  initialDescription: any = '';
  actionData: any = {};

  private refEve: Subscription;
  private actEve: Subscription;
  sortDirection: any;

  constructor(
    private loaderService: LoaderService,
    public modalService: ModalService,
    public vulnerabilityService: VulnerabilityService,
    public companyService: CompanyService,
    private baseService: BaseRequestService,
    private toast: MyToastrService,
    private confirmDialog: ConfirmDialogService,
    public cs: CommonService,
    public integrationActionService: IntegrationActionsService,
  ) {
    this.refEve = cs.rmRefreshEvent.subscribe((value: any) => {
      this.initRP();
    });
    this.actEve = integrationActionService.integrationActionEvent.subscribe((value: any) => {
      this.loaderService.display(true, 'Preparing data...');
      if (this.tabIndex) { this.processVulsData(); } else { this.processAssetsData(); }
    });
  }

  processVulsData(): void {
    setTimeout(() => {
      if (!this.sortedData.length) { return; }
      const len = this.sortedData.filter((x: any) => x.selected);
      if (len.length && len.length === 1) {
        this.actionData.uuId = len[0].asset_name;
        this.actionData.ticketId = len[0].ticketId;
        this.actionData.summary = `${len[0].asset_name} - Total: ${len[0].total} Vulnerabilities\n`;
        this.actionData.subject = `Severity: Critical-${len[0].count.Critical}, High-${len[0].count.High}, Medium-${len[0].count.Medium}, Low-${len[0].count.Low}\n`;
        this.initialDescription = '';
        this.initialDescription += `Severity: Critical-${len[0].count.Critical}, High-${len[0].count.High}, Medium-${len[0].count.Medium}, Low-${len[0].count.Low}\n`;
        this.initialDescription += '-----------------------------------------------------------------------------------\n';
        this.initialDescription += '\n';
        if (len[0].vulnerabilities && len[0].vulnerabilities.length) {
          this.initialDescription += `Vulnerabilities:`;
          len[0].vulnerabilities.forEach((aValue: any) => {
            this.initialDescription += '\n';
            this.initialDescription += '---------------------------------------------------------------------\n';
            this.initialDescription += `Vulnerability ID: ${aValue.vul_id}, \n`;
            this.initialDescription += `CVSS Score: ${aValue.cvss_score}, \n`;
            this.initialDescription += `Severity: ${aValue.severity}, \n`;
            this.initialDescription += `Port: ${aValue.port}, \n`;
            this.initialDescription += '---------------------------------------------------------------------\n';
            this.initialDescription += '\n';
          });
        }
        this.integrationActionService.integrationActionShow = true;
        setTimeout(() => {
          this.actionData.initialDescription = this.initialDescription;
          this.integrationActionService.integrationActionPopup.next({value: true});
          this.loaderService.display(false);
        }, 3000);
      } else {
        this.loaderService.display(false);
        this.toast.sToast('error', 'Please select one asset, network scan finding');
      }
    }, 2000);
  }

  processAssetsData(): void {
    setTimeout(() => {
      if (!this.sortedData.length) { return; }
      const len = this.sortedData.filter((x: any) => x.selected);
      if (len.length && len.length === 1) {
        this.actionData.uuId = len[0].vul_id;
        this.actionData.ticketId = len[0].ticketId;
        this.actionData.summary = `${len[0].vul_id} - Title: ${len[0].title} - Severity: ${len[0].severity}`;
        this.actionData.subject = `Score: ${len[0].score} - Affected Assets Count: ${len[0].total}`;
        this.initialDescription = '';
        this.initialDescription += `Score: ${len[0].score} - Affected Assets Count: ${len[0].total}\n`;
        this.initialDescription += '-----------------------------------------------------------------------------------\n';
        this.initialDescription += '\n';
        this.initialDescription += `Affected Assets Count: ${len[0].total}, \n`;
        this.initialDescription += '-----------------------------------------------------------------------------------\n';
        if (len[0].assets && len[0].assets.length) {
          this.initialDescription += `Assets Details:`;
          len[0].assets.forEach((aValue: any) => {
            this.initialDescription += '\n';
            this.initialDescription += '---------------------------------------------------------------------\n';
            this.initialDescription += `Asset Name: ${aValue.name}, \n`;
            this.initialDescription += `Port: ${aValue.port}, \n`;
            this.initialDescription += `Reference: ${aValue.ref}, \n`;
            this.initialDescription += `Ticket ID: ${aValue.ticketId}, \n`;
            this.initialDescription += '---------------------------------------------------------------------\n';
            this.initialDescription += '\n';
          });
        }
        this.integrationActionService.integrationActionShow = true;
        setTimeout(() => {
          this.actionData.initialDescription = this.initialDescription;
          this.integrationActionService.integrationActionPopup.next({value: true});
          this.loaderService.display(false);
          }, 3000);
      } else {
        this.loaderService.display(false);
        this.toast.sToast('error', 'Please select one vulnerability, network scan finding');
      }
    }, 2000);
  }

  ngOnInit(): void {
    this.initRP();
  }

  initRP(): void {
    if (this.mode === 'global') {
      this.getGlobalNetworkscan();
    } else if (this.mode === 'company') {
      this.getTab(this.tabIndex);
    }
  }

  selectAllToggle(): void {
    this.sortedData.forEach((obj: any) => {
      obj.selected = this.selectAll;
    });
  }

  getCompanyNetworkscan(params: any): void {
    this.loaderService.display(true, 'Getting network scan');
    this.baseService.doRequest(`/api/company/${this.currentCompany._id}/getNetworkVulsData`, 'post', params).subscribe((result: any) => {
      console.log(result);
      this.loaderService.display(false);
      result.sort((a: any, b: any) => {
        const c = a.score;
        const d = b.score;
        if (c < d) {
          return 1;
        } else if (c > d) {
          return -1;
        } else {
          return 0;
        }
      });
      this.networkScan = result;
      this.sortedData = this.networkScan.slice();
      this.dataBack.next(this.sortedData);
    });
  }

  getCompanyAssetLevelNetworkscan(params: any): void {
    this.loaderService.display(true, 'Getting asset level network scan');
    this.baseService.doRequest(`/api/company/${this.currentCompany._id}/getAssetLevelNetworkVulsData`,
      'post', params).subscribe((result: any) => {
      console.log(result);
      this.loaderService.display(false);
      result.sort((a: any, b: any) => {
        const c = a.total;
        const d = b.total;
        if (c > d) {
          return -1;
        } else if (c < d) {
          return 1;
        } else {
          return 0;
        }
      });
      this.networkScan = result;
      this.sortedData = this.networkScan.slice();
      this.dataBack.next(this.sortedData);
    });
  }

  getGlobalNetworkscan(): void {
    this.loaderService.display(true, 'Getting network scan');
    this.baseService.doRequest(`/api/company/dummy/getGlobalNetworkVulsData`,
      'post', {}).subscribe((result: any) => {
      console.log(result);
      result.sort((a: any, b: any) => {
        const c = a.severity;
        const d = b.severity;
        if (c < d) {
          return -1;
        } else if (c > d) {
          return 1;
        } else {
          return 0;
        }
      });
      this.loaderService.display(false);
      result.map((s:any) => s.company = (s.companies && s.companies.length) ? s.companies?.length : 0)
      this.globalnetworkScan = result;
      this.GsortedData = this.globalnetworkScan.slice();
      this.dataBack.next(this.sortedData);
    });
  }

  sortData(sorts: Sort): any {
    const sort:any = sorts;
    this.sortDirection = sort.direction;
    let sortOrder = ['Critical', 'High', 'Medium', 'Low', 'Info'];
    (sort.direction === '') ? (this.sortDirection === 'asc') ? sort.direction = 'desc' : 'asc' : null;
    const data = (this.mode === 'global') ? this.globalnetworkScan.slice() : this.networkScan.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    if(this.mode === 'global'){
      this.GsortedData = data.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active) {
          return (sortOrder.indexOf(sort.active) === -1) ? this.cs.compare(a[sort.active], b[sort.active], isAsc) : this.cs.compare(a['count'][sort.active], b['count'][sort.active], isAsc);
        }
      });
    } else {
      this.sortedData = data.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active) {
          return (sortOrder.indexOf(sort.active) === -1) ? this.cs.compare(a[sort.active], b[sort.active], isAsc) : this.cs.compare(a['count'][sort.active], b['count'][sort.active], isAsc);
        }
      });
    }
  }

  viewCompanyDetails(gNScan: any): void {
    gNScan.companies.sort((a: any, b: any) => {
                const c = a.companyName;
                const d = b.companyName;
                if (c < d) {
                  return -1;
                } else if (c > d) {
                  return 1;
                } else {
                  return 0;
                }
              });
    gNScan.companies.forEach( (obj: any) => {
      obj.assets.sort((a: any, b: any) => {
                const c = a.name;
                const d = b.name;
                if (c < d) {
                  return -1;
                } else if (c > d) {
                  return 1;
                } else {
                  return 0;
                }
              });
    });
    this.currentGRP = gNScan;
    this.sidenav.open();
  }

  close(back?: any): void {
    if (back) { return; }
    this.sidenav.close();
  }

  showHosts(data: any): void {
    console.log(data.assets);
    this.currentNs = undefined;
    this.hostList = [];
    setTimeout(() => {
      this.hostList = data.assets;
      this.currentNs = data;
      this.getAllEvidence();
      this.sidenav.open();
    });
    this.sidenav.open();
  }
  getAllEvidence(): void {
    this.currentNs.assets.forEach((asset: any) => {
      if (asset.evidence && asset.evidence.productRef) {
        this.loaderService.display(true, `Getting ${asset.name} evidence....`);
        this.baseService.doRequest(`/api/installedprogram/${asset.evidence.productRef}`,
          'get').subscribe((obj: any) => {
            this.loaderService.display(false);
            this.prodHash[obj._id] = obj;
        });
      }
    });
  }

  totalCallbackCheck($event: any): void { // @ts-ignore
    if (this[$event.id]) { // @ts-ignore
      this[$event.id].hideTable = ($event.value === 0);
    } else {
      console.log($event.id + ' not available');
    }
  }

  showCompanies(data: any): void {
    console.log(data.companies);
    this.currentNs = undefined;
    this.companyList = [];
    this.hostList = [];
    setTimeout(() => {
      this.companyList = data.companies;
      this.currentNs = data;
    });
    this.sidenav.open();
  }
  showAssets(data: any, index: number): void {
    this.currentNs.companies[index].currentGNs = undefined;
    this.hostList = [];
    setTimeout(() => {
      this.currentNs.companies[index].currentGNs = Object.assign({}, data);
      this.hostList = data.assets;
    });
    this.sidenav.open();
  }

  showVuls(data: any): void {
    this.currentNs = undefined;
    this.companyList = [];
    this.vulsList = [];
    setTimeout(() => {
      this.vulsList = data.vulnerabilities;
      this.currentNs = data;
    });
    this.sidenav.open();
  }

  hasSelected(): boolean {
    const len = this.sortedData.filter((x: any) => x.selected);
    if (len && len.length) {
      return true;
    } else {
      return false;
    }
  }

  isAllSelected(): boolean {
    if (this.selectAll) {
      return true;
    }
    const len = this.sortedData.filter((x: any) => x.selected).length;
    if (this.sortedData.length === len) {
      return true;
    }
    return false;
  }
  ngOnDestroy(): void {
    this.sortedData = [];
    this.refEve.unsubscribe();
    this.actEve.unsubscribe();
  }

  getTab($event: number): void {
    this.tabIndex = $event;
    const params = {companyid: this.currentCompany._id};
    if ($event === 0) {
       this.getCompanyNetworkscan(params);
    } else if ($event === 1) {
      this.getCompanyAssetLevelNetworkscan(params);
    }
  }
}
