import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseRequestService } from '../../_services/base.service';
import { LoaderService } from '../../_services/loader.service';
import { MyToastrService } from '../../_services/toastr.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-attack-surface',
  templateUrl: './attack-surface.component.html',
  styleUrls: ['./attack-surface.component.scss']
})
export class AttackSurfaceComponent implements OnInit, OnDestroy {
  @Input() currentCompany: any;
  @ViewChild('navi', { static: false }) tabContainer: ElementRef;
  @ViewChild('targetip', { static: false }) targetip: ElementRef;
  @ViewChild('emails', { static: false }) emails: ElementRef;
  @ViewChild('usernames', { static: false }) usernames: ElementRef;
  @ViewChild('subdomains', { static: false }) subdomains: ElementRef;
  domain = '';
  currentDomain: any;
  data: any = {};
  showUpper = true;
  summary: any = [];
  errMsg: any = '';
  intervalId: any;
  objectKeys = Object.keys;
  mxHostsCustomCols: any = [
    {
      col: 'tls', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    }, {
      col: 'starttls', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },];
  constructor(private baseService: BaseRequestService, private loaderService: LoaderService, private toast: MyToastrService) { }

  ngOnInit(): void {
    console.log('Reset asm info');
    this.currentDomain = undefined;
    this.domain = '';
    this.data = undefined;
    this.errMsg = '';
    const asm = localStorage.getItem('asm');
    if (asm && asm.indexOf('{') > -1) {
      try {
        const asmData = JSON.parse(asm);
        if (this.currentCompany._id === asmData.com._id) {
          this.data = asmData.d;
          this.currentDomain = asmData.c;
          this.domain = this.currentDomain.domain;
        }
      } catch (e) {
        console.log(e);
      }
    }
    this.processData();
  }
  processData(): void {
    if (this.data && this.data.target_ips) {
      this.data.s3buckets[0] = this.data.s3buckets[0].filter((str: any) => str !== '');
      this.data.guessed_emails[0] = this.data.guessed_emails[0].filter((str: any) => str !== '');
      this.data.emails[0] = (this.data.emails && this.data.emails.length)
        ? this.data.emails[0].filter((str: any) => str !== '') : [];
      this.data.hashes[0] = (this.data.hashes && this.data.hashes.length)
        ? this.data.hashes[0].filter((str: any) => str !== '') : [];
      this.data.usernames[0] = (this.data.usernames && this.data.usernames.length)
        ? this.data.usernames[0].filter((str: any) => str !== '') : [];
      this.data.employees[0] = (this.data.employees && this.data.employees.length)
        ? this.data.employees[0].filter((str: any) => str !== '') : [];
      this.data.subdomains[0] = (this.data.subdomains && this.data.subdomains.length)
        ? this.data.subdomains[0].filter((str: any) => str !== '') : [];
      this.data.creds[0] = (this.data.creds && this.data.creds.length)
        ? this.data.creds[0].filter((str: any) => str !== '') : [];


      let ports: any = [];
      let vuls: any = [];
      this.data.target_ips.forEach((obj: any) => {
        obj['Port/Protocol'].split(',').forEach((port: any) => {
          if (port && port !== '') { ports.push(port); }
        });
        obj.Vulnerabities.split(',').forEach((vul: any) => {
          if (vul && vul !== '') { vuls.push(vul); }
        });
      });
      ports = [...new Set(ports)];
      vuls = [...new Set(vuls)];
      this.summary = [
        { title: 'Vulnerabilities', img: '/assets/images/network/bug.svg', count: vuls.length },
        { title: 'Open Ports', img: '/assets/images/network/padlock.svg', count: ports.length },
        { title: 'Target IPs', img: '/assets/images/target-ip.png', count: this.data.target_ips.length },
        {
          title: 'Emails',
          img: '/assets/images/emails.png',
          count: (this.data.emails[0].length && this.data.emails[0][0]) ? this.data.emails[0].length : 0
        },
        {
          title: 'Usernames',
          img: '/assets/images/usernames.png',
          count: (this.data.usernames[0].length && this.data.usernames[0][0]) ? this.data.usernames[0].length : 0
        },
        {
          title: 'Subdomains',
          img: '/assets/images/subdomains.png',
          count: (this.data.subdomains[0].length && this.data.subdomains[0][0]) ? this.data.subdomains[0].length : 0
        }
      ];
    } else if (this.data && this.data.status === 'Completed' && !this.data.target_ips) {
      this.errMsg = 'No data found!';
    } else {
      this.errMsg = '';
    }
  }

  scanDomain(): void {
    this.errMsg = '';
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/attacksurfacemapper/start_asm`, 'post',
      { website: this.domain }).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status === 'success') {
          this.currentDomain = { domain: this.domain, status: 'Running' };
          this.intervalId = setInterval(() => { this.getDomainDetails(); }, 30000);
          this.getDomainDetails();
        } else {
          this.currentDomain = undefined;
          this.toast.sToast('error', result.error);
        }
      });
  }

  getDomainDetails(): void {
    this.baseService.doRequest(`/api/attacksurfacemapper/get_asm`, 'post',
      { website: this.domain }).subscribe((result: any) => {
        if (result.status === 'Running') {
          this.currentDomain.status = result.status;
        } else if (result.status === 'Completed') {
          this.data = result; this.currentDomain.status = result.status;
          localStorage.setItem('asm', JSON.stringify({ d: this.data, c: this.currentDomain, com: this.currentCompany }));
          if (this.intervalId !== undefined && this.intervalId !== null) {
            clearInterval(this.intervalId);
          }
          this.processData();
        } else {
          this.toast.sToast('error', result.msg, result.status);
          this.errMsg = result.msg;
          if (this.intervalId !== undefined && this.intervalId !== null) {
            clearInterval(this.intervalId);
          }
        }
      });
  }

  navigateTarget($event: any): void {
    try {
      if ($event.key === 'Target IPs' || $event.key === 'Vulnerabilities' || $event.key === 'Open Ports') {
        this.targetip.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.key === 'Emails') {
        this.emails.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.key === 'Usernames') {
        this.usernames.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.key === 'Subdomains') {
        this.subdomains.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    } catch (err) { }
  }

  backToScan(): void {
    this.currentDomain = undefined;
    this.domain = '';
    this.data = undefined;
    this.errMsg = '';
    localStorage.removeItem('asm');
  }

  redirectUrl(url: any): void {
    url = (url.startsWith('http://') || url.startsWith('https://')) ? url : `https://${url}`;
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    if (this.intervalId !== undefined && this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  }
}
