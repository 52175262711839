<div class="remedia-container">
  <mat-sidenav-container class="remedia-sidenav-container" (backdropClick)="close('backdrop')">
    <mat-sidenav-content class="h-100vh">
      <mat-tab-group (selectedTabChange)="tabFun($event)">
        <mat-tab label="Scan Jobs">
          <ng-template matTabContent>
            <app-s-table aria-label="Agents" role="table" [sTableOptions]="jobTableOptions"
              (refreshCallback)="getJobs()" (sortCallback)="jobssortCall($event)"
              (hyperlinkCallback)="jobslinkCall($event)" (filterCallback)="jobsfilterCall($event)"
              (colFilterCallback)="colFilterCall($event)" (actionCallback)="jobsactionCall($event)"
              (pageCallback)="jobspageCall($event)" (totalCallback)="totalCallbackCheck($event)"
              (globalActionCallback)="jobsglobalActionCall($event)" (timerCallback)="jobtimerCallData($event)">
            </app-s-table>
          </ng-template>
        </mat-tab>
        <mat-tab label="Scheduler Report Jobs" *ngIf="!baseService.showAssessment">
          <ng-template matTabContent>
            <app-s-table aria-label="Agents" role="table" [sTableOptions]="jobTableOptions"
              (refreshCallback)="getJobs()" (sortCallback)="jobssortCall($event)"
              (hyperlinkCallback)="jobslinkCall($event)" (filterCallback)="jobsfilterCall($event)"
              (colFilterCallback)="colFilterCall($event)" (actionCallback)="jobsactionCall($event)"
              (pageCallback)="jobspageCall($event)" (totalCallback)="totalCallbackCheck($event)"
              (globalActionCallback)="jobsglobalActionCall($event)" (timerCallback)="jobtimerCallData($event)">
            </app-s-table>
          </ng-template>
        </mat-tab>
        <mat-tab label="Patch Jobs" *ngIf="!hidePatchJob  && !baseService.showAssessment">
          <ng-template matTabContent>
            <app-patch-jobs [currentCompany]="currentCompany"></app-patch-jobs>
          </ng-template>
        </mat-tab>
        <mat-tab label="Agent Event Logs" *ngIf="false">
          <ng-template matTabContent>
            <app-agent-event-logs [currentCompany]="currentCompany"></app-agent-event-logs>
          </ng-template>
        </mat-tab>
        <mat-tab label="Azure Active Directory Jobs" *ngIf="isAzureAD">
          <ng-template matTabContent>
            <div class="infoccns mb-3" *ngIf="showNotes && showAzureReAuth">
              <button mat-raised-button (click)="verifyReauthenticate()"
                    class="mat-primary float-right mr-1 mt-2">Verify If Re-authenticated
                  </button>
              <div class="flex-column">
                <h4 class="m-0"><b>Note:</b></h4>
                <h4 class="t-b">
                  If Azure Active Directory Job status is failed, click <a href="javascript:"
                    (click)="azureADReauthentication('popup')">here</a> for Azure AD<b> Re-authentication</b>.
                </h4>
              </div>
            </div>
            <app-s-table aria-label="AzureJobs" role="table" [sTableOptions]="azureJobTableOptions"
              (refreshCallback)="getAzureJobs()" (sortCallback)="azureJobsortCall($event)" (timerCallback)="adJobstimerCallData($event)"
              (hyperlinkCallback)="azureJoblinkCall($event)" (filterCallback)="azureJobfilterCall($event)"
              (colFilterCallback)="azurecolFilterCall($event)" (actionCallback)="azureJobactionCall($event)"
              (pageCallback)="azureJobpageCall($event)" (totalCallback)="totalCallbackCheck($event)"
              (globalActionCallback)="azureJobglobalActionCall($event)">
            </app-s-table>
          </ng-template>
        </mat-tab>
        <!-- <mat-tab label="Offline Vulnerability Scan Jobs" *ngIf="!baseService.showAssessment">
          <ng-template matTabContent>
            <app-offline-vulnerability-scan-jobs [currentCompany]="currentCompany"></app-offline-vulnerability-scan-jobs>
          </ng-template>
        </mat-tab> -->
      </mat-tab-group>
    </mat-sidenav-content>
    <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p z-index-150">
      <mat-card class="w-100">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
          (click)="currentJob = undefined; snav.close();"><mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw2 mr-4">Job Details</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflow-auto"
          *ngIf="currentJob && currentJob.job_data
         && (currentJob.job_data.job_message || currentJob.job_data.report_job_message || currentJob.job_data.azuread_job_message)">
          <app-job-details [currentJob]="currentJob"></app-job-details>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>

<s-modal id="jobAzureCredential">
  <form #addAgt="ngForm">
    <div class="mat-card" class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="600">
      <mat-card class="modal mat-elevation-z0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
          (click)="modalService.close('jobAzureCredential');"><mat-icon>close</mat-icon>
        </button>
        <div *ngIf="cView === 0">
          <mat-card-header>
            <mat-card-title>Global Admin Access</mat-card-title>
          </mat-card-header>
          <mat-card-content class="h200">
            <p class="pt-2 d-flex flex-row align-items-center mt-4">
              <mat-checkbox class="px-1 mr-2" color="primary" id="permissionsCheck" name="permissionsCheck"
                [(ngModel)]="permissions">
                Please confirm that the user has Global Admin Access with valid permissions
              </mat-checkbox>
            </p>
          </mat-card-content>
          <div class="mat-actions">
            <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
              <div class="row mr-1">
                <div class="col-lg-12">
                  <button mat-raised-button (click)="cView=1" *ngIf="permissions"
                    class="mat-primary float-right ml-10">Next
                  </button>
                  <button mat-stroked-button color="warn" (click)="modalService.close('jobAzureCredential')"
                    class="float-right mr-1">
                    Cancel
                  </button>
                </div>
              </div>
            </mat-card-footer>
          </div>
        </div>
        <div *ngIf="cView === 1">
          <div class="mat-actions">
            <mat-card-header>
              <mat-card-title *ngIf="showAuthNotes">For Re-authentication</mat-card-title>
              <mat-card-title *ngIf="!showAuthNotes">Microsoft API Consent</mat-card-title>
            </mat-card-header>
            <mat-card-content class="m-h-400">
              <p class="fs--14" *ngIf="showAuthNotes">
                <b>For a CSP account, Please follow the below steps for Azure AD integration with Microsoft Azure
                  portal:</b> <br><br>
                Step 1: Log in to Microsoft partner center -> go to the Customer List -> Select the customer whose data
                is not loading in CyberCNS.<br><br>
                Step 2: Click on Azure Active Directory for the particular customer which will redirect to the Microsoft
                Azure portal.<br><br>
                Step 3: Log in to Microsoft Azure using your credentials provided in CyberCNS integration.<br><br>
                Step 4: Search for Azure Active Directory & navigate Enterprise Application, Select the CyberCNS
                application from the enterprise application.<br><br>
                Step 5: Choose Permissions and click Grant admin consent for all customers. You will be prompted to
                enter the credentials please use the same credentials that you have added with CyberCNS.<br><br>
                Once you enter the credentials, it will register the application with Microsoft. Once done please click
                next and then click on Verify If Re-authenticated
                from the CyberCNS portal for the Azure AD data to populate.<br><br><br>

                <b>For a Non-CSP account, Please follow the below steps for Azure AD integration:</b> <br><br>
                Step 1: Make sure the credentials used for integration have global admin permissions.<br><br>
                Step 2: In the same CyberCNS browser window, please log in to the Azure portal in the adjacent
                tab.<br><br>
                Step 3: In Cybercns' Azure AD integration, click on "Save." It will prompt you to enter the credentials
                and make sure you select the consent checkbox before accepting.<br><br>
                Once done please click next and then click on Verify If Re-authenticated from the CyberCNS portal for the Azure AD data to populate.
              </p>
              <p class="fs--14" *ngIf="!showAuthNotes">
                Step 1: To provide consent, use the “Customer Tenant Global Administrator” user.(Please note that this
                administrator should be at Customer Tenant level and not the Account Global Admin).<br><br>
                Step 2: Log in with your customer administrator account and grant all permissions by enabling the
                checkbox “Consent on behalf of your organization”. Accept all permissions.<br><br>
                Step 3: Once done click next and then click on Verify If Re-authenticated for Azure AD data to populate.<br><br><br>
              </p>
            </mat-card-content>
            <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
              <div class="row mr-1">
                <div class="col-lg-12">
                  <button mat-raised-button (click)="azureADReauthentication('nonpopup')"
                    class="mat-primary float-right ml-10">Next
                  </button>
                  <button mat-stroked-button color="default" (click)="cView=0" class="float-right mr-1">
                    Back
                  </button>
                </div>
              </div>
            </mat-card-footer>
          </div>
        </div>
      </mat-card>
    </div>
  </form>
</s-modal>