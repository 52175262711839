<mat-tab-group *ngIf="currentAgent && currentAgent.agent_type !== 'External Scan Agent'">
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="DiscoveryIPRanges"> IP Ranges</span> 
    </ng-template>
    <ng-template matTabContent>
      <app-iprange [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-iprange>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="DiscoverySNMPCredentials">   SNMP Credentials</span>
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <app-snmp-credentials [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-snmp-credentials>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="DiscoveryActiveDirectory"> Active Directory Credentials</span> 
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <app-ad-credentials [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-ad-credentials>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="DiscoveryMasterCredentials">  Master Credentials</span>
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <app-asset-credentials [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-asset-credentials>
      <br>
      <div class="inp mb-3">
      <br>
      <p>
       <b>Prerequisites for Master Credentials:</b> <br>
        <b>Windows machine</b> <br>
        1.SMB should be enabled (port: 445)<br>
        <b>Linux based OS</b><br>
        1.ssh access should be enabled for the end machine (asset)<br>
        2.User should have the sudo privileges on the asset.<br>
        3.User should have access to sudo command without a password. <br>
        <b>Darwin based OS(MAC)</b> <br>
        1.ssh should be enabled in the end point machine. <br>
        2.User should have the sudo privileges.<br>
        3.User should have access to sudo command without a password. <br>
        <b>VMware based OS</b> <br>
        1.ssh should be enabled in the end point machine.<br>
        2.User should have access to sudo command without a password.
      </p>
      </div>
    </ng-template>
  </mat-tab>
  
  <mat-tab *ngIf="currentAgent && currentAgent.agent_type === 'Probe'">
    <ng-template mat-tab-label>
    <span id="ProcessCount">Performance Management</span>
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <div class="d-flex align-items-center fs1-2 mb-2">
        <span class="ms-Icon ms-font-lg px-2 ms-Icon--SkypeCircleCheck text-success"></span>
        <span class="mb-1">Maximum Allowed Process:</span>
      </div>
      <div class="infoccns_new mt-2 ml-3 mb-3">
        <p>
          Note: <br>
          <span class="ml-4">This tab allows you to allocate the required number of processes to be executed at a time, when a scan is triggered.</span><br>
          <span class="ml-4">This is based on the system configuration where the agent is installed.</span><br>
          <span class="ml-4">(Ex - 4 Core Processor - 16 Processes)</span><br>
          <span class="ml-4">Initially when the setting is empty / when the value is set to zero, the agent will run with according to the system configuration i.e. X Core Processes - 4 threads.</span>
        </p>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 mb-2 ml-2" appearance="outline">
          <mat-label>Count</mat-label>
          <input matInput name="count"
                 [(ngModel)]="maxThreadCount" type="number" placeholder=""  min="0">
          <mat-hint>Ex. Min 0 </mat-hint>
        </mat-form-field>
        <button mat-raised-button color="primary" class="float-right mb-2" [disabled]="maxThreadCount==='' || maxThreadCount===null" type="button" (click)="updateProcessCount()">Save</button>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="currentAgent && currentAgent.agent_type === 'Probe'">
    <ng-template mat-tab-label>
    <span id="bruteforceSettings">Brute Force Setting</span>
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <div class="d-flex align-items-center fs1-2 mb-2">
        <span class="mb-1 ml-3">Brute Force Setting:</span>
      </div>
      <!-- <div class="infoccns_new mt-2 ml-3 mb-3">
        <p>
          Note: <br>
          <span class="ml-4">This tab allows you to allocate the required number of processes to be executed at a time, when a scan is triggered.</span><br>
          <span class="ml-4">This is based on the system configuration where the agent is installed.</span><br>
          <span class="ml-4">(Ex - 4 Core Processor - 16 Processes)</span>
        </p>
      </div> -->
      <div class="col-lg-8">
        <div class="row w-100 py-4">
          <span class="col-lg-4">Enable Brute Force Setting</span>
          <mat-slide-toggle (change)="bruteForceEnabled = $event.checked" [(ngModel)]="bruteForceEnabled"
            name="bruteforceSettings" matTooltip="Enable Brute Force Setting">
          </mat-slide-toggle>
        </div>
        <mat-form-field class="w-100 mb-2" appearance="outline" *ngIf="bruteForceEnabled">
          <mat-label>Brute Force Ports</mat-label>
          <mat-chip-list #portList aria-label="port selection">
            <mat-chip class="allowedg" *ngFor="let port of bruteForcePorts" [selectable]="selectable" [removable]="removable"
                      (removed)="removePort(port)">
              {{port.name}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input type="text" trim="blur" autocomplete="off" name="excluded"
                   placeholder="Enter a port or port range and press comma or enter to add." id="Ports" [matChipInputFor]="portList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="addPort($event)">
          </mat-chip-list>
          <mat-hint>Ex. 22, 443-450</mat-hint>
        </mat-form-field>
        <button mat-raised-button color="primary" class="float-right mb-2" type="button" (click)="updateBruteForcePorts()">Save</button>
      </div>
    </ng-template>
  </mat-tab>

</mat-tab-group>
<app-iprange *ngIf="currentAgent && currentAgent.agent_type === 'External Scan Agent'"
  [currentAgent]="currentAgent" [currentCompany]="currentCompany" ></app-iprange>
