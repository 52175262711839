import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, NavigationError, Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {CommonService} from '../../_services/common.services';

@Component({
  selector: 'app-networkvuls',
  templateUrl: './networkvuls.component.html',
  styleUrls: ['./networkvuls.component.scss']
})
export class NetworkvulsComponent implements OnInit {
  @Input() solution: any;
  assets: any = [];
  sortedData: any = [];
  constructor(private cs: CommonService) { }

  ngOnInit(): void {
    this.assets = this.solution.vulnerabilities;
    this.sortedData = this.assets.slice();
    console.log(this.assets);
  }

  sortData(sort: Sort): any {
    let sortOrder = ['Critical', 'High', 'Medium', 'Low', 'Info'];
    const data = this.assets.slice();
    if (sort.active === 'severity') {
      if (sort.direction === 'desc') {
        sortOrder = ['Info', 'Low', 'Medium', 'High', 'Critical'];
      }
      this.sortedData.sort((a: any, b: any) => {
        return sortOrder.indexOf(a[sort.active]) - sortOrder.indexOf(b[sort.active]);
      });
      return;
    }
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active) {
          return this.cs.compare(a[sort.active], b[sort.active], isAsc);
      }
    });
  }
}
