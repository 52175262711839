import {Component, Input, OnInit} from '@angular/core';
import {ConfirmDialogService} from 'src/app/_services/confirmdialog.service';
import {CommonService} from 'src/app/_services/common.services';
import {ModalService} from 'src/app/_services/modal.service';
import {LoaderService} from 'src/app/_services/loader.service';
import {MyToastrService} from 'src/app/_services/toastr.service';
import {NotificationRulesService} from '../../api/services/notification-rules.service';
import {BaseRequestService} from '../../_services/base.service';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-alerts-notification',
  templateUrl: './alerts-notification.component.html',
  styleUrls: ['./alerts-notification.component.scss']
})

export class AlertsNotificationComponent implements OnInit {
  @Input() currentCompany: any;
  @Input() mode: any;
  selectedIndex = 0;
  alertList: any = [];
  currentNR: any;
  notiTableOptions: any;
  rulecurrentPage = 0;
  rulefilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  notificationRulesData: any = [];
  addEditRules = false;
  notificationRule: any = [];
  integrations: any = [];
  iHash: any = {};
  integrationMappingHash: any = {};
  mappedIntegrations: any = [];
  selectedIntegrations: any = [];
  selectedIntegrationsCredsHash: any = {};
  inputActionData: any = {};
  step = 0;
  ruleName: any;
  Objectkeys = Object.keys;
  constructor(public modalService: ModalService, public toast: MyToastrService,
              private baseService: BaseRequestService, private aS: AuthenticationService,
              private loaderService: LoaderService, public confirmDialog: ConfirmDialogService,
              private notificationRulesService: NotificationRulesService,
              public commonService: CommonService) {
    this.notiTableOptions = {
      columns: [
        {
          header: 'Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Last Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        }, /*{
          header: 'Integration Rule',
          columnDef: 'integrationRule',
          filter: '',
          cell: '(element: any) => `${element.integrationRule}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        }, {
          header: 'Alert Rules',
          columnDef: 'alertRules',
          filter: '',
          cell: '(element: any) => `${element.alertRules}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        }, {
          header: 'Company',
          columnDef: 'companyRef.name',
          filter: '',
          cell: '(element: any) => `${element.companyRef.name}`',
          order: 5,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        }*/],
      sortOptions: {active: 'u', direction: 'desc'},
      faClass: 'NotificationRules',
      _pageData: [],
      tableOptions: {
        title: 'Notification Rules',
        isServerSide: false,
        selectText: 'rules',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        add: false,
        columnSearch: false,
        compareData: false
      }
    };
    if (this.aS.hasPermission('notificationrules', 'update')){
      this.notiTableOptions.tableOptions.actionMenuItems.unshift({text: 'Edit', icon: 'edit', callback: 'editFn', isGlobal: false});
    }
    if (this.aS.hasPermission('notificationrules', 'delete')) {
      this.notiTableOptions.tableOptions.actionMenuItems.push({text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false});
    }
  }

  ruleshowHideLoading(status: any): void {
    const data = Object.assign({}, this.notiTableOptions);
    this.notiTableOptions = {};
    this.notiTableOptions = data;
    this.notiTableOptions.tableOptions.loading = status;
  }

  ruleeditFn(idata: any): void {
    this.clean();
    this.currentNR = idata; let selectedEvents: any = []; let selectedIntegrations: any; selectedIntegrations = [];
    this.notificationRule = Object.assign([], this.alertList);
    this.ruleName = idata.name;
    if (this.notificationRule && this.notificationRule.length) {
      const filter1 = this.currentNR.alertRules.map((x: any) => x.rules).map((x: any) => x.map((y: any) => y.eventId));
      if (filter1.length) {
        const filter2 = [].concat.apply([], filter1.filter((x: any) => x));
        selectedEvents = [].concat.apply([], filter2.filter((x: any) => x));
      } else {
        selectedEvents = [].concat.apply([], filter1.filter((x: any) => x));
      }
      selectedIntegrations = this.currentNR.integrationRule.map((x: any) => x.integrationName);
      this.notificationRule.forEach((sec: any) => {
        sec.alerts.forEach((rule: any) => {
          rule.events.forEach((eve: any) => {
            eve.selected = selectedEvents.includes(eve.id);
          });
          rule.eventGroups.forEach((r: any) => {
            const ids = r.events.map((x: any) => x.id);
            ids.forEach((id: any) => {
              if (selectedEvents.includes(id)) {
                r.value = id;
              }
            });
          });
        });
      });
      this.mappedIntegrations.forEach((obj: any) => {
        this.currentNR.integrationRule.forEach((integ: any) => {
          if (integ.integrationName === obj.integrationName) {
            obj.actionData = integ; delete obj.actionData.integrationName; delete obj.actionData.integrationId;
          }
        });
        obj.selected = selectedIntegrations.includes(obj.integrationName);
      });
      this.addEditRules = true;
    }
  }
  ruledeleteFn(idata: any): void {
    this.deleteNotificationRule(idata);
  }
  ruleactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.ruleeditFn(dataRow);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.ruledeleteFn(dataRow);
    }
  }
  rulefilterCall(idata: any): void {
    console.log(idata);
    this.ruleshowHideLoading(true);
    setTimeout(() => {
      this.ruleshowHideLoading(false);
    }, 1000);
  }

  rulepageCall(idata: any): void {
    console.log(idata);
    this.ruleshowHideLoading(true);
    setTimeout(() => {
      this.ruleshowHideLoading(false);
    }, 1000);
  }

  ruleaddTableData(): void {
    this.clean();
    this.ruleName = ''; this.currentNR = undefined;
    this.notificationRule = Object.assign([], this.alertList);
    this.notificationRule.forEach((obj: any) => {
      obj.alerts.forEach((al: any) => {
        al.events.forEach((ev: any) => {
          ev.selected = true;
        });
      });
    });
    this.getIntegrationMapping();
    setTimeout(() => { this.addEditRules = true; }, 1000);
  }

  ngOnInit(): void {
    this.notiTableOptions.pageData = [];
    this.getAlertNotification();
    this.getNotificationRulesData();
    this.getAllIntegrations();
    this.getIntegrationMapping();
  }

  getAlertNotification(): void {
    this.loaderService.display(true, 'Getting notification rules');
    this.notificationRulesService.notificationRulesgetAlertingRulesApiNotificationrulesIdGetAlertingRulesPost(
      {id: 'dummy', body: {}}).subscribe((result: any) => {
      this.loaderService.display(false);
      result.forEach((obj: any) => {
        obj.alerts.forEach((alert: any) => {
          alert.eventGroups = [];
          const groups: any = [...new Set(alert.events.map((x: any) => x.group))];
          groups.forEach((grp: any) => {
            if (grp) {
              alert.eventGroups.push({name: grp, events: []})
            }
          })
          alert.events.forEach((event: any) => {
            if (event.group) {
              alert.eventGroups.forEach((grp: any) => {
                if (grp.name === event.group) {
                  grp.events.push(event);
                }
              });
            }
          });
          alert.events = alert.events.filter((eve: any) => !eve.group);
        });
    });
    this.alertList = result;
    this.notificationRule = Object.assign([], result);
    });
  }

  getNotificationRulesData(): void {
    this.notificationRulesData = [];
    let qry: any; qry = { query: { bool: { must: [{exists: {field: 'alertRules.rules.eventId'}}] } } };
    if (this.currentCompany && this.currentCompany._id) {
      qry.query.bool.must.push({match: {'companyRef.id.keyword': this.currentCompany._id + ''}});
    }
    if (this.mode === 'global') {
      qry = { query: { bool: { must: [{exists: {field: 'alertRules.rules.eventId'}}],
          must_not: [{exists: {field: 'companyRef'}}]} } };
    }
    this.ruleshowHideLoading(true);
    this.loaderService.display(true, 'Getting NotificationRules data');
    this.notiTableOptions.serverSide = {
      service: 'notificationRulesService', fn: 'getAllApiNotificationrulesGet', q: qry
    };
    const q = JSON.stringify(qry);
    const skip = 0;
    const limit = 1000;
    let sort: any = [{}];
    if (this.notiTableOptions.sortOptions && this.notiTableOptions.sortOptions.direction
      && this.notiTableOptions.sortOptions.direction !== '') {
      const orderArr = [ 'integrationRule', '_id' ];
      if (orderArr.indexOf(this.notiTableOptions.sortOptions.active) > -1) {
        sort[0][this.notiTableOptions.sortOptions.active + '.keyword'] = { order: this.notiTableOptions.sortOptions.direction };
      } else {
        sort[0][this.notiTableOptions.sortOptions.active] = { order: this.notiTableOptions.sortOptions.direction };
      }
    }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['c', 'u', '_id', 'name', 'integrationRule', 'alertRules']);
    this.notificationRulesService.getAllApiNotificationrulesGet(
      {q, skip, limit, sort, fields}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.notificationRulesData = result.data;
      this.notiTableOptions.pageData = result.data;
      this.notiTableOptions.tableOptions.pagezie = result.total;
      this.ruleshowHideLoading(false);
    });
  }

  getIntegrationMapping(): void {
    if (this.mode === 'global') {
      this.loaderService.display(true, 'Getting all integrations...');
      this.baseService.doRequest(`/api/integrations/`, 'get').subscribe((result: any) => {
        this.loaderService.display(false);
        result.forEach((obj: any) => {
          obj.integrationName = obj.name;
        });
        result.sort((a: any, b: any) => {
          const c = a.name; const d = b.name;
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
        this.mappedIntegrations = result;
      });
    } else {
      if (!this.currentCompany || !this.currentCompany._id ) {
        return;
      }
      /*const query = {query: {bool: {must: [{match: {'sourceCompanyId.keyword': this.currentCompany._id + ''}}]}}};
      const q = JSON.stringify(query);
      const skip = 0;
      const limit = 1000;
      this.loaderService.display(true);
      this.baseService.doRequest(`/api/integrations/companyMapping`,
        'get', null, {q, skip, limit}).subscribe((result: any) => {
        this.loaderService.display(false);
        this.mappedIntegrations = result.data;
      });*/
      this.baseService.doRequest(`/api/integrations/getcompanymappings`, 'post',
        {companyid: this.currentCompany._id}).subscribe((result: any) => {
         this.loaderService.display(false);
         this.mappedIntegrations = result;
      });
    }
  }

  getAllIntegrations(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/integrations/`, 'get').subscribe((result: any) => {
      this.loaderService.display(false);
      result.forEach((obj: any) => {
        // this.integrationMappingHash[obj.credentialId] = obj;
        this.iHash[obj.name] = obj;
      });
      result.sort((a: any, b: any) => {
        const c = a.name; const d = b.name;
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
      this.integrations = result;
    });
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  selectDeselectAll(status: boolean): void {
    this.notificationRule.forEach((obj: any) => {
      obj.alerts.forEach((al: any) => {
        al.events.forEach((ev: any) => {
          ev.selected = status;
        });
      });
    });
  }

  deleteNotificationRule(notificationRules: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this notification rule ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.notificationRulesService.deleteApiNotificationrulesIdDelete({id: notificationRules._id })
          .subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => { this.getNotificationRulesData(); }, 3000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }

  validationRemediateCheck(): void {
    let selected = false;
    let companySelected = false; let assetSelected = false; let prodSelected = false;
    this.notificationRule.forEach( (sec: any) => {
      sec.alerts.forEach((rule: any) => {
        rule.events.forEach((eve: any) => {
          if (eve.selected) { selected = true; }
          if (!companySelected) {
            companySelected = (eve.name === 'Remediation By Company' && eve.selected);
          }
          if (!assetSelected) {
            assetSelected = (eve.name === 'Remediation By Asset' && eve.selected);
          }
          if (!prodSelected) {
            prodSelected = (eve.name === 'Remediation By Product' && eve.selected);
          }
        });
      });
    });
    if (!selected) {
      this.toast.sToast('error', `Please choose atleast one event`);
      return;
    }
    const remSelected = [companySelected, assetSelected, prodSelected];
    const len =  remSelected.filter((x: any) => !x).length;
    if (len < 2) {
      this.toast.sToast('error', `Please choose either 'Remediation By Company' or 'Remediation By Asset' or 'Remediation By Product'`);
      return;
    }
    this.nextStep();
  }

  getSelectedCreds(): void {
    let gotVal = 0;
    this.selectedIntegrations = [];
    this.selectedIntegrationsCredsHash = {};
    const selectedIntegrations = this.mappedIntegrations.filter((x: any) => x.selected);
    if (!selectedIntegrations.length) {
      this.nextStep();
      return;
    }
    selectedIntegrations.forEach((item: any) => {
      this.loaderService.display(true, `Getting ${item.integrationName} credentials...`);
      this.baseService.doRequest(`/api/integrations/getcredentials?integrationName=${item.integrationName}`,
        'get').subscribe((result: any) => {
        this.loaderService.display(false);
        gotVal += 1;
        if (result.data && result.data.length) {
          item.credentialId = result.data[0]._id;
          this.selectedIntegrations.push(item);
        } else {
          this.toast.sToast('error', `Please add ${item.integrationName} integration.`);
        }
        this.selectedIntegrationsCredsHash[item.integrationName] = result.data;
        if (gotVal === this.Objectkeys(this.selectedIntegrationsCredsHash).length) {
          this.getActionParams();
        }
      });
    });
  }

  getActionParams(): void {
    let gotVal = 0;
    if (this.mode !== 'global') {
      this.selectedIntegrations = this.mappedIntegrations.filter((x: any) => x.selected);
    }
    if (!this.selectedIntegrations.length) {
      this.nextStep();
      return;
    }
    this.selectedIntegrations.forEach((item: any) => {
      this.loaderService.display(true, `Getting ${item.integrationName}`);
      this.baseService.doRequest(
        `/api/integrations/action_params?integrationName=${item.integrationName}&actionName=${this.iHash[item.integrationName].notificationfunction}`,
        'get').subscribe((result: any) => {
        this.loaderService.display(false);
        result.parameters = result.parameters.filter((x: any) => x.notification);
        item.actionResult = result;
        gotVal += 1;
        if (gotVal === this.selectedIntegrations.length) {
          this.nextStep(); this.processFields(this.selectedIntegrations[0].actionResult, 0);
        }
      });
    });
  }
  processFields(result: any, index: number): void {
    if (!result || !result.parameters) { return; } this.selectedIndex = index;
    // this.selectedIntegrations[index].formElements = [];
    this.selectedIntegrations[index].actionData =
      (this.selectedIntegrations[index].actionData) ? this.selectedIntegrations[index].actionData : [];
    const tmpAP: { label: any; key: any; required: any; example?: any; type: any; }[] = [];
    result.parameters.forEach(async (obj: any) => {
      const tmpFormField = (this.selectedIntegrations[index].formElements)
        ? this.selectedIntegrations[index].formElements.filter((x: any) => x.key === obj.name)
        : [];
      const tFF: any = {label: obj.description, key: obj.name, required: obj.required, example: obj.example, type: obj.schema.type};
      tFF.callback = (obj.callback);
      if (obj.source && !obj.depends) {
        if (tmpFormField && tmpFormField.length && tmpFormField[0].options && tmpFormField[0].options.length) {
          tFF.options = tmpFormField[0].options;
        } else {
          this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
            const requestParams: any = {};
            obj.subParams = result;
            if (subObj.parameters) {
              subObj.parameters.forEach((pObj: any) => {
                requestParams[pObj.name] = pObj.example;
              });
            }
            this.loaderService.display(true, `Executing ${subObj.name}`);
            this.executeAction(subObj, requestParams).then((subOut: any) => {
              if (subOut.status === 'failed' && obj.required) {
                this.toast.sToast('error', subOut.data);
              } else {
                this.processParams(subOut.data).then((edata: any) => {
                  this.loaderService.display(false);
                  obj.subOut = edata;
                  tFF.options = [];
                  edata.forEach((val: any) => {
                    tFF.options.push({key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue] + ''});
                  });
                });
              }
            });
          });
        }
      } else if (obj.source && obj.depends && this.selectedIntegrations[index].actionData[obj.depends[0]]) {
        this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
          const requestParams: any = {};
          obj.subParams = result;
          const sources: any = this.selectedIntegrations[index].actionParams.filter((x: any) => x.source && x.source.mappedValue);
          if (subObj.parameters) {
            subObj.parameters.forEach((pObj: any) => {
              requestParams[pObj.name] = pObj.example;
              const mValue = sources.filter((x: any) => x.source.mappedValue === pObj.name);
              if (mValue.length) {
                requestParams[pObj.name] = this.selectedIntegrations[index].actionData[mValue[0].name];
              }
            });
          }
          // requestParams[obj.source.parameter] = this.selectedIntegrations[index].actionData[obj.depends[0]];
          this.loaderService.display(true, `Executing ${subObj.name}`);
          this.executeAction(subObj, requestParams).then((subOut: any) => {
            if (subOut.status === 'failed' && obj.required) {
              this.toast.sToast('error', subOut.data);
            } else {
              this.processParams(subOut.data).then((edata: any) => {
                this.loaderService.display(false);
                obj.subOut = edata;
                tFF.options = [];
                edata.forEach((val: any) => {
                  tFF.options.push({key: val[subObj.DisplayFields[0]], value: val[obj.source.mappedValue] + ''});
                });
              });
            }
          });
        });
      }
      tmpAP.push(tFF);
      /*if (obj.maptokey) {
        this.selectedIntegrations[index].actionData[obj.name] =
          this.integrationMappingHash[this.selectedIntegrations[index].credentialId][obj.maptokey];
      }*/
      if (this.inputActionData[obj.name] && !this.selectedIntegrations[index].actionData[obj.name]) {
        this.selectedIntegrations[index].actionData[obj.name] = this.inputActionData[obj.name];
      } else if (!this.inputActionData[obj.name] && this.selectedIntegrations[index].actionData[obj.name]) {
        this.selectedIntegrations[index].actionData[obj.name] = this.selectedIntegrations[index].actionData[obj.name];
      } else {
        this.selectedIntegrations[index].actionData[obj.name] = '';
      }
    });
    this.selectedIntegrations[index].formElements = tmpAP.slice();
    this.selectedIntegrations[index].actionParams = result.parameters;
  }

  actionChange($event: any): void {
    if ($event && $event !== '') {
      this.processFields(this.selectedIntegrations[this.selectedIndex].actionResult, this.selectedIndex);
    }
  }

  getSubActionParams(actionName: string): Promise<any> {
    return this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.selectedIntegrations[this.selectedIndex].integrationName}&actionName=${actionName}`, 'get').toPromise();
  }

  executeAction(param: any, requestparams: any): Promise<any> {
    const params: any = {
      integrationName: this.selectedIntegrations[this.selectedIndex].integrationName,
      integrationId: this.selectedIntegrations[this.selectedIndex].credentialId,
      params: { action: {name: param.name, destination: param.destination, verb: param.verb}, params: {requestparams} }
    };
    return this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params).toPromise();
  }

  async processParams(data: any): Promise<any> {
    const value: any = [];
    const drow = await data.row_values;
    if (!drow) { return value; }
    drow.forEach((obj: any) => {
      const obj2: any = {};
      data.column_definitions.forEach((obj1: any, index: number) => {
        const key = this.Objectkeys(obj1)[0];
        obj2[key] = obj[index];
      });
      value.push(obj2);
    });
    return value;
  }

  clean(): void {
    this.selectedIntegrations = [];
    this.step = 0;
    this.selectedIndex = 0;
  }

  saveNotificationRule(): void {
    this.loaderService.display(true);
    let notificationRules: any = {};
    notificationRules = {
      name: this.ruleName,
      integrationRule: [],
      alertRules: [],
    };
    if (this.mode !== 'global') {
      notificationRules.companyRef = { id: this.currentCompany._id, name: this.currentCompany.name };
    }
    this.selectedIntegrations.forEach((obj: any) => {
      const integrationObj = Object.assign({}, obj.actionData);
      integrationObj.integrationName = obj.integrationName;
      integrationObj.integrationId = (obj.credentialId) ? obj.credentialId : '';
      notificationRules.integrationRule.push(integrationObj);
    });
    this.notificationRule.forEach((sec: any) => {
      let alertObj: any = {}; alertObj = { sectionId: sec.sectionId, rules: [] };
      sec.alerts.forEach((rule: any) => {
        let ruleObj: any = {}; ruleObj = { ruleId: rule.ruleId, eventId: [] };
        rule.events.forEach((eve: any) => {
          if (eve.selected) { ruleObj.eventId.push(eve.id); }
        });
        rule.eventGroups.forEach((eve: any) => {
          if (eve.value) { ruleObj.eventId.push(eve.value); }
        })
        alertObj.rules.push(ruleObj);
      });
      notificationRules.alertRules.push(alertObj);
    });
    if (this.currentNR && this.currentNR._id) {
      notificationRules._id = this.currentNR._id;
      this.notificationRulesService.updateApiNotificationrulesPut({body: notificationRules})
        .subscribe((res: any) => {
        this.toast.sToast('success', 'Notification rule updated successfully!');
        setTimeout(() => {
          this.addEditRules = false;
          this.getNotificationRulesData();
          this.loaderService.display(false);
          }, 2000);
      });
    } else {
      this.notificationRulesService.createApiNotificationrulesPost({body: notificationRules})
        .subscribe((result: any) => {
        if (result && result.c === null && result.u === null) {
          this.toast.sToast('error', result._id); return;
        }
        this.toast.sToast('success', 'Notification rule saved successfully!');
        setTimeout(() => {
          this.addEditRules = false;
          this.getNotificationRulesData();
          this.loaderService.display(false);
          }, 2000);
      });
    }
  }
}
