import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent implements OnInit {
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  @ViewChild('inte', {static: true}) inte: MatSidenav;
  @Input() currentCompany: any;
  @Input() mode: any;
  alertsColOptions: any;
  alertsQuery: any = {};
  eventsQuery: any = {};
  currentAlert: any = {};
  dynamicColumns: any = [];
  dynamicEventsColumns: any = [];
  eventsColOptions: any = {};
  eventColumns: any = [];
  eventsInteColOptions: any = {};
  eventInteColumns: any = [];
  eventsInteQuery: any = {};
  currentAlertInte: any;
  constructor(public aS: AuthenticationService) {
  }

  totalCallbackCheck($event: any): void { // @ts-ignore
    if (this[$event.id]) { // @ts-ignore
      this[$event.id].hideTable = ($event.value === 0);
    } else {
      console.log($event.id + ' not available');
    }
  }

  viewEvents(idata: any): void {
    this.currentAlert = idata.row;
    this.snav.open();
    this.getEventsData();
  }

  close(status?: any): void {
    if (status) {
      return;
    }
    this.snav.close();
  }

  viewInteEvents(idata: any): void {
    this.currentAlertInte = idata.row;
    this.inte.open();
    this.getEventsInteData();
  }

  closeInte(status?: any): void {
    if (status) {
      return;
    }
    this.inte.close();
  }
  
  ngOnInit(): void {
    this.dynamicColumns = [
      {
        col: 'u', header: 'Last updated', id:'AlertsLastUpdated', colFilters: {type: 'text', hKey: false}, hyperLinkCall: {
          filter: 'utcToLocale'
        }
      },
      {
        col: 'status', header: 'Status', id:'AlertsStatus', isKeyword: true,
        htmlCols: {
          Open: `<span class=" fw4 ml-3 px-1 badge badge-danger">Open</span>`,
          Closed: `<span class=" fw4 ml-3 px-1 badge badge-success">Closed</span>`
        },
        colFilters: {
          type: 'select', hKey: false,
          options: [{value: 'Open', name: 'Open'}, {value: 'Closed', name: 'Closed'}]
        },
      },
      {col: 'name', header: 'Name', id:'AlertsName', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'description', header: 'Description', id:'AlertsDescription', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'assetRef.name', header: 'Asset Name', id:'AlertsAssetName', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
    ];

    this.eventColumns = [
      {col: 'u', header: 'Last updated', id:'AlertsLastUpdated', colFilters: {type: 'text', hKey: false}, hyperLinkCall: { filter: 'utcToLocale'}
      },
      {
        col: 'status', header: 'Status',
        htmlCols: {
          Open: `<span class=" fw4 ml-3 px-1 badge badge-danger">Open</span>`,
          Closed: `<span class=" fw4 ml-3 px-1 badge badge-success">Closed</span>`
        },
        colFilters: {
          type: 'select', hKey: false,
          options: [{value: 'Open', name: 'Open'}, {value: 'Closed', name: 'Closed'}]
        }, isKeyword: true,
      },
      {col: 'name', header: 'Name', id:'AlertsName', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'description', header: 'Description', id:'AlertsDescription', colFilters: {type: 'text', isKeyword: true, hKey: true}, noFilter: true},
    ];

    this.eventInteColumns = [
      {
        col: 'integrationName',
        header: 'Integration Name',
        id:'AlertsIntegrationName',
        colFilters: {type: 'text', hKey: true}, isKeyword: true,
        noFilter: true
      },
      {col: 'ticketId', header: 'Ticket No', id:'AlertsTicketNo', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {
        col: 'ticketStatus', header: 'Status',
        htmlCols: {
          Open: `<span class=" fw4 ml-3 px-1 badge badge-danger">Open</span>`,
          Closed: `<span class=" fw4 ml-3 px-1 badge badge-success">Closed</span>`
        },
        colFilters: {
          type: 'select', hKey: false,
          options: [{value: 'Open', name: 'Open'}, {value: 'Closed', name: 'Closed'}]
        }, isKeyword: true,
      },
    ];

    this.getAlertsData();
  }

  getAlertsData(): void {
    if (this.mode === 'company' && this.currentCompany && this.currentCompany._id) {
      this.alertsQuery = {
        query: {
          bool: {
            must: [{match: {'companyRef.id.keyword': this.currentCompany._id}},
              {exists: {field: 'description'}}, {exists: {field: 'name'}}, {exists: {field: 'status'}}],
            should: [
              {match: {'status.keyword': 'Open'}}, {match: {'status.keyword': 'Closed'}}],
            must_not: [{exists: {field: 'alertsRef'}}]
          }
        }
      };
    } else {
      this.dynamicColumns.push(
        {col: 'companyRef.name', header: 'Company Name', colFilters: {type: 'text', hKey: true},  isKeyword: true, noFilter: true},
      );
      this.alertsQuery = {
        query: {
          bool: {
            must: [{exists: {field: 'description'}}, {exists: {field: 'name'}}, {exists: {field: 'status'}}],
            should: [{match: {'status.keyword': 'Open'}}, {match: {'status.keyword': 'Closed'}}],
            must_not: [{exists: {field: 'alertsRef'}}]
          }
        }
      };
    }
    this.alertsColOptions = {};
    setTimeout(() => {
      this.alertsColOptions = {
        id: 'alertsColOptions',
        title: 'Alerts',
        refresh: true,
        cFilter: true,
        pSize: 200,
        pList: [1, 5, 10, 25, 50, 100, 200],
        pagination: true,
        loading: true,
        customCols: true,
        faClass: 'Alerts',
        serverSide: {
          service: 'alertsService', fn: 'getAllApiAlertsGet', q: this.alertsQuery, sort: [{u: {order: 'desc'}}]
        },
        lMsg: 'Getting alerts...',
        local: false,
        dataList: [],
        columns: this.dynamicColumns
      };
    });
  }

  getEventsData(): void {
    this.eventsQuery = {
      query: {
        bool: {
          must: [
            {match: {'alertsRef.id.keyword': this.currentAlert._id + ''}},
            {exists: {field: 'description'}}, {exists: {field: 'name'}}, {exists: {field: 'status'}},
            {exists: {field: 'alertsRef'}}], should: [{match: {'status.keyword': 'Open'}},
            {match: {'status.keyword': 'Closed'}}]
        }
      }
    };
    this.eventsColOptions = {};
    setTimeout(() => {
      this.eventsColOptions = {
        id: 'eventsColOptions',
        title: 'Events',
        refresh: true,
        cFilter: true,
        pSize: 5,
        pList: [1, 5, 10, 25, 50, 100, 200],
        pagination: true,
        loading: true,
        customCols: true,
        faClass: 'Alerts',
        serverSide: {
          service: 'eventsService', fn: 'getAllApiEventsGet', q: this.eventsQuery, sort: [{u: {order: 'desc'}}]
        },
        lMsg: 'Getting alerts...',
        local: false,
        dataList: [],
        columns: this.eventColumns
      };
    });
  }

  getEventsInteData(): void {
    this.eventsInteQuery = {
      query: {
        bool: {
          must: [
            {match: {'alertRef.id.keyword': this.currentAlertInte?.alertsRef?.id + ''}},
            {exists: {field: 'ticketId'}},
            {exists: {field: 'alertRef.id'}},
          ]
        }
      }
    };
    this.eventsInteColOptions = {};
    setTimeout(() => {
      this.eventsInteColOptions = {
        id: 'eventsInteColOptions',
        title: 'Notifications',
        refresh: true,
        cFilter: true,
        pSize: 5,
        pList: [1, 5, 10, 25, 50, 100, 200],
        pagination: true,
        loading: true,
        customCols: true,
        faClass: 'Alerts',
        serverSide: {
          service: 'notificationTicketsService', fn: 'getAllApiNotificationticketsGet', q: this.eventsInteQuery, sort: [{u: {order: 'desc'}}]
        },
        lMsg: 'Getting notification...',
        local: false,
        dataList: [],
        columns: this.eventInteColumns
      };
    });
  }
}
