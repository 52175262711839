import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-pill-card',
  templateUrl: './pill-card.component.html',
  styleUrls: ['./pill-card.component.scss']
})

export class PillCardComponent implements OnInit {
  @Input() chartData: any;
  @Input() pilltype: any;
  @Output() callbackCountFilter = new EventEmitter();
  inArray = ['critical', 'high', 'medium', 'low', 'compliant', 'active', 'inactive', 'not logged-in > 30d',
    'non compliant', ];
  complianceIgs: any = [
    {name: 'Default', value: 'all'},
    {name: 'IG1', value: 'ig1'},
    {name: 'IG2', value: 'ig2'},
    {name: 'IG3', value: 'ig3'}
  ]
  constructor() { }

  ngOnInit(): void {
    this.chartData.selectedIg = 'all';
    setTimeout(() => {
      if (this.chartData.compliance) { this.updateCompliance({value: this.chartData.compliance}); }
    }, 3000);
  }

  updateCompliance($event: any): void {
    const selectedC = this.chartData.complianceData.filter((x: any) => x.value === $event.value);
    if(($event.value === 'cis' || $event.value ==='cis_8_0') && this.chartData.selectedIg !== 'all'){
      const compliance = selectedC[0][this.chartData.selectedIg].compliant; // selectedC[0].data.filter((x: any) => x.compliance);
      const noncompliance = selectedC[0][this.chartData.selectedIg].non_compliant; // selectedC[0].data.filter((x: any) => !x.compliance);
      this.chartData.data[0].value = noncompliance; // noncompliance.length;
      this.chartData.data[1].value = compliance; // compliance.length;
      this.chartData.count = noncompliance; // noncompliance.length ; // + compliance.length
    } else {
      const compliance = selectedC[0].compliant; // selectedC[0].data.filter((x: any) => x.compliance);
      const noncompliance = selectedC[0].non_compliant; // selectedC[0].data.filter((x: any) => !x.compliance);
      this.chartData.data[0].value = noncompliance; // noncompliance.length;
      this.chartData.data[1].value = compliance; // compliance.length;
      this.chartData.count = noncompliance; // noncompliance.length ; // + compliance.length
    }
  }

  updateIg($event: any): void {
    this.chartData.selectedIg = $event.value;
    this.updateCompliance({value: this.chartData.compliance})
  }
  setFilter(chartData: any, name: string): void {
    this.callbackCountFilter.next({value: chartData, key: name, ig: this.chartData.selectedIg});
  }
}
