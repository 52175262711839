/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ADusergroups } from '../models/a-dusergroups';
import { ADusergroupsCreate } from '../models/a-dusergroups-create';
import { AssetAdgroupsGetResp } from '../models/asset-adgroups-get-resp';

@Injectable({
  providedIn: 'root',
})
export class ADusergroupsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiADusergroupsGet
   */
  static readonly GetAllApiADusergroupsGetPath = '/api/ADusergroups/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiADusergroupsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiADusergroupsGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<AssetAdgroupsGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, ADusergroupsService.GetAllApiADusergroupsGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssetAdgroupsGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiADusergroupsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiADusergroupsGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<AssetAdgroupsGetResp> {

    return this.getAllApiADusergroupsGet$Response(params).pipe(
      map((r: StrictHttpResponse<AssetAdgroupsGetResp>) => r.body as AssetAdgroupsGetResp)
    );
  }

  /**
   * Path part for operation updateApiADusergroupsPut
   */
  static readonly UpdateApiADusergroupsPutPath = '/api/ADusergroups/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiADusergroupsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiADusergroupsPut$Response(params: {
    body: ADusergroups
  }): Observable<StrictHttpResponse<ADusergroups>> {

    const rb = new RequestBuilder(this.rootUrl, ADusergroupsService.UpdateApiADusergroupsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADusergroups>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiADusergroupsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiADusergroupsPut(params: {
    body: ADusergroups
  }): Observable<ADusergroups> {

    return this.updateApiADusergroupsPut$Response(params).pipe(
      map((r: StrictHttpResponse<ADusergroups>) => r.body as ADusergroups)
    );
  }

  /**
   * Path part for operation createApiADusergroupsPost
   */
  static readonly CreateApiADusergroupsPostPath = '/api/ADusergroups/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiADusergroupsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiADusergroupsPost$Response(params: {
    body: ADusergroupsCreate
  }): Observable<StrictHttpResponse<ADusergroups>> {

    const rb = new RequestBuilder(this.rootUrl, ADusergroupsService.CreateApiADusergroupsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADusergroups>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiADusergroupsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiADusergroupsPost(params: {
    body: ADusergroupsCreate
  }): Observable<ADusergroups> {

    return this.createApiADusergroupsPost$Response(params).pipe(
      map((r: StrictHttpResponse<ADusergroups>) => r.body as ADusergroups)
    );
  }

  /**
   * Path part for operation getApiADusergroupsIdGet
   */
  static readonly GetApiADusergroupsIdGetPath = '/api/ADusergroups/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiADusergroupsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiADusergroupsIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ADusergroups>> {

    const rb = new RequestBuilder(this.rootUrl, ADusergroupsService.GetApiADusergroupsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADusergroups>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiADusergroupsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiADusergroupsIdGet(params: {
    id: string;
  }): Observable<ADusergroups> {

    return this.getApiADusergroupsIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ADusergroups>) => r.body as ADusergroups)
    );
  }

  /**
   * Path part for operation deleteApiADusergroupsIdDelete
   */
  static readonly DeleteApiADusergroupsIdDeletePath = '/api/ADusergroups/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiADusergroupsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiADusergroupsIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ADusergroupsService.DeleteApiADusergroupsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiADusergroupsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiADusergroupsIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiADusergroupsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
