import { DOCUMENT } from '@angular/common';
import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { TableSettingsService } from '../api/services';
import { AuthenticationService } from './authentication.service';
import { BaseRequestService } from './base.service';
import { CommonService } from './common.services';
@Injectable({
    providedIn: 'root'
})
export class ThemingService {
    constructor(@Inject(DOCUMENT) private document: Document, public baseService: BaseRequestService,
    public commonService: CommonService, public authService: AuthenticationService,
    private tableSettingsService: TableSettingsService,) {}

    changeMode: any = {
        tableId: false
    };

    changeTheme(event: any, noapi?: any): void {
        //darkMode 
        if (event.checked) {
            this.document.body.classList.remove('light-mode-theme');
            this.document.body.classList.add('dark-mode-theme');
        } else {
            this.document.body.classList.remove('dark-mode-theme');
            this.document.body.classList.add('light-mode-theme');
        }
        if (!noapi) {
            const reqData: any = Object.assign({}, this.changeMode);
            reqData.userId = this.authService.currentUser?.email;
            reqData.tableId = (event.checked) ? 'true' : 'false';
            const method: any = (reqData._id) ? 'put' : 'post';
            this.baseService.doRequest(`/api/tablesettings/`, method, reqData).subscribe((res: any) => { });
        }
    }
    getThemeMode(): void {
        const query = { query: { bool: { must: [{ match:{'userId.keyword': this.authService.currentUser?.email} },{ exists: { field: 'userId' } }, { exists: { field: 'tableId' } }], must_not: [{ exists: { field: 'settings' } }] } } };
        const q = JSON.stringify(query);
        const skip = 0;
        const limit = 1;
        this.tableSettingsService.getAllApiTablesettingsGet({ q, skip, limit }).subscribe((result: any) => {
            if (result && result.data && result.data.length) {
                const resData: any = result.data[0];
                resData.tableId = (result.data[0]?.tableId === 'true') ? true : false
                this.changeMode = resData;
                this.changeTheme({ checked: resData.tableId }, true);
            } else {
                this.changeTheme({ checked: false }, true);
            }
        });
    }
}
