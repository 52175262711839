/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ADrole } from '../models/a-drole';
import { ADroleCreate } from '../models/a-drole-create';
import { AssetAdrolesGetResp } from '../models/asset-adroles-get-resp';

@Injectable({
  providedIn: 'root',
})
export class ADroleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiADroleGet
   */
  static readonly GetAllApiADroleGetPath = '/api/ADrole/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiADroleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiADroleGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<AssetAdrolesGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, ADroleService.GetAllApiADroleGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssetAdrolesGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiADroleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiADroleGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<AssetAdrolesGetResp> {

    return this.getAllApiADroleGet$Response(params).pipe(
      map((r: StrictHttpResponse<AssetAdrolesGetResp>) => r.body as AssetAdrolesGetResp)
    );
  }

  /**
   * Path part for operation updateApiADrolePut
   */
  static readonly UpdateApiADrolePutPath = '/api/ADrole/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiADrolePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiADrolePut$Response(params: {
    body: ADrole
  }): Observable<StrictHttpResponse<ADrole>> {

    const rb = new RequestBuilder(this.rootUrl, ADroleService.UpdateApiADrolePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADrole>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiADrolePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiADrolePut(params: {
    body: ADrole
  }): Observable<ADrole> {

    return this.updateApiADrolePut$Response(params).pipe(
      map((r: StrictHttpResponse<ADrole>) => r.body as ADrole)
    );
  }

  /**
   * Path part for operation createApiADrolePost
   */
  static readonly CreateApiADrolePostPath = '/api/ADrole/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiADrolePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiADrolePost$Response(params: {
    body: ADroleCreate
  }): Observable<StrictHttpResponse<ADrole>> {

    const rb = new RequestBuilder(this.rootUrl, ADroleService.CreateApiADrolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADrole>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiADrolePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiADrolePost(params: {
    body: ADroleCreate
  }): Observable<ADrole> {

    return this.createApiADrolePost$Response(params).pipe(
      map((r: StrictHttpResponse<ADrole>) => r.body as ADrole)
    );
  }

  /**
   * Path part for operation getApiADroleIdGet
   */
  static readonly GetApiADroleIdGetPath = '/api/ADrole/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiADroleIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiADroleIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ADrole>> {

    const rb = new RequestBuilder(this.rootUrl, ADroleService.GetApiADroleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADrole>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiADroleIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiADroleIdGet(params: {
    id: string;
  }): Observable<ADrole> {

    return this.getApiADroleIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ADrole>) => r.body as ADrole)
    );
  }

  /**
   * Path part for operation deleteApiADroleIdDelete
   */
  static readonly DeleteApiADroleIdDeletePath = '/api/ADrole/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiADroleIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiADroleIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ADroleService.DeleteApiADroleIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiADroleIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiADroleIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiADroleIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
