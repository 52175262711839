/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Unquotedservice } from '../models/unquotedservice';
import { UnquotedserviceCreate } from '../models/unquotedservice-create';
import { UnquotedserviceGetResp } from '../models/unquotedservice-get-resp';

@Injectable({
  providedIn: 'root',
})
export class UnquotedserviceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiUnquotedserviceGet
   */
  static readonly GetAllApiUnquotedserviceGetPath = '/api/Unquotedservice/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiUnquotedserviceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiUnquotedserviceGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }

): Observable<StrictHttpResponse<UnquotedserviceGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, UnquotedserviceService.GetAllApiUnquotedserviceGetPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UnquotedserviceGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllApiUnquotedserviceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiUnquotedserviceGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }

): Observable<UnquotedserviceGetResp> {

    return this.getAllApiUnquotedserviceGet$Response(params).pipe(
      map((r: StrictHttpResponse<UnquotedserviceGetResp>) => r.body as UnquotedserviceGetResp)
    );
  }

  /**
   * Path part for operation updateApiUnquotedservicePut
   */
  static readonly UpdateApiUnquotedservicePutPath = '/api/Unquotedservice/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiUnquotedservicePut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiUnquotedservicePut$Response(params: {
    body: Unquotedservice
  }

): Observable<StrictHttpResponse<Unquotedservice>> {

    const rb = new RequestBuilder(this.rootUrl, UnquotedserviceService.UpdateApiUnquotedservicePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unquotedservice>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateApiUnquotedservicePut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiUnquotedservicePut(params: {
    body: Unquotedservice
  }

): Observable<Unquotedservice> {

    return this.updateApiUnquotedservicePut$Response(params).pipe(
      map((r: StrictHttpResponse<Unquotedservice>) => r.body as Unquotedservice)
    );
  }

  /**
   * Path part for operation createApiUnquotedservicePost
   */
  static readonly CreateApiUnquotedservicePostPath = '/api/Unquotedservice/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiUnquotedservicePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiUnquotedservicePost$Response(params: {
    body: UnquotedserviceCreate
  }

): Observable<StrictHttpResponse<Unquotedservice>> {

    const rb = new RequestBuilder(this.rootUrl, UnquotedserviceService.CreateApiUnquotedservicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unquotedservice>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApiUnquotedservicePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiUnquotedservicePost(params: {
    body: UnquotedserviceCreate
  }

): Observable<Unquotedservice> {

    return this.createApiUnquotedservicePost$Response(params).pipe(
      map((r: StrictHttpResponse<Unquotedservice>) => r.body as Unquotedservice)
    );
  }

  /**
   * Path part for operation getApiUnquotedserviceIdGet
   */
  static readonly GetApiUnquotedserviceIdGetPath = '/api/Unquotedservice/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiUnquotedserviceIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiUnquotedserviceIdGet$Response(params: {
    id: string;
  }

): Observable<StrictHttpResponse<Unquotedservice>> {

    const rb = new RequestBuilder(this.rootUrl, UnquotedserviceService.GetApiUnquotedserviceIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unquotedservice>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiUnquotedserviceIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiUnquotedserviceIdGet(params: {
    id: string;
  }

): Observable<Unquotedservice> {

    return this.getApiUnquotedserviceIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Unquotedservice>) => r.body as Unquotedservice)
    );
  }

  /**
   * Path part for operation deleteApiUnquotedserviceIdDelete
   */
  static readonly DeleteApiUnquotedserviceIdDeletePath = '/api/Unquotedservice/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiUnquotedserviceIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiUnquotedserviceIdDelete$Response(params: {
    id: string;
  }

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UnquotedserviceService.DeleteApiUnquotedserviceIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApiUnquotedserviceIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiUnquotedserviceIdDelete(params: {
    id: string;
  }

): Observable<any> {

    return this.deleteApiUnquotedserviceIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
export { Unquotedservice };

