<s-modal id="integrationActions">
  <form #integrationActionForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="integrationActionsWidth">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Integration Actions</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 w-100" *ngIf="sView === 'getIntegrations'">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="onDismiss()"> <mat-icon>close</mat-icon> </button>
        <div class="mat-title">Integrations and Actions</div>
        <mat-card-content class="py-4 my-4">
          <form #actionForm="ngForm">
            <mat-form-field class="w-70p">
              <mat-label>Integration Mapping Credential</mat-label>
              <mat-select placeholder="Choose integration credential" id="integrationMapping" (ngModelChange)="getActions($event)"
                          name="integrationCredentials" [(ngModel)]="integration.credentialId">
                <mat-option *ngFor="let item of credListData" [value]="item.credentialId">
                  <span>
                    <img class="mat-24" *ngIf="item.integrationName && integrationHash[item.integrationName] && integrationHash[item.integrationName].img"
                         src="/assets/images/providers/provider-{{ integrationHash[item.integrationName].img }}.png">
                  </span>
                  <span class="ml-1">{{item.destCompanyName}}</span>
                  <span class="ml-1"> - </span>
                  <span class="ml-1">{{item.credentialName}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-70p" appearance="outline">
              <mat-label>Actions</mat-label>
              <mat-select [multiple]="false" id="integrationActionSelect" placeholder="Choose action" [(ngModel)]="integration.action"
                         name="actions" [disabled]="!currentActions || !currentActions.length">
                <mat-option *ngFor="let item of currentActions" [id]="item" [value]="item">
                  {{item | camelToHuman: true}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-70p" appearance="outline" *ngIf="defaultActionData">
              <mat-label>Choose Action Template</mat-label>
              <mat-select [multiple]="false" id="integrationActionSelect" required="" placeholder="Choose action template" [(ngModel)]="actionTemplate" name="actionTemplate" [disabled]="!currentActions || !currentActions.length">
                <mat-option value="">Manual Action Template</mat-option>
                <mat-option value="default">Default Action Template</mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-stroked-button class="mat-warn mr-2" (click)="onDismiss()" id="cancel11">Cancel</button>
          <button (click)="getAction()" id="intActionNext" [disabled]="!integration.action" mat-raised-button
          class="" color="primary">Next</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="modal mat-elevation-z0 w-100" *ngIf="sView === 'actionParams'">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="onDismiss()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Execute {{integration.action | camelToHuman:true}} Action</div>
        <mat-card-content class="py-4 my-4">
          <div class="d-flex align-items-center w-100">
              <app-dynamic-form class="col-lg-12" (cancelCallBack)="cancel()" (saveCallBack)="save($event)"
                [isSaveBtn]="false" [isCloseBtn]="false"
                *ngIf="integration.actionData && formElements && formElements.length"
                (valueUpdate)="actionChange($event)"
                [listOfFormElements]="formElements" [Valuesoutput]="integration.actionData">
              </app-dynamic-form>
          </div>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-stroked-button class="mr-2" (click)="sView = 'getIntegrations'" id="cancel12">Back</button>
          <button (click)="saveAction()" [disabled]="!integration.actionData || !disabledSubmit" mat-raised-button
                  id="intActionSubmit" class="" color="primary">Submit</button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
