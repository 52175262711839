import {Component, Input, OnInit} from '@angular/core';
import {SnmpV3Credentials} from '../../../api/models/snmp-v-3-credentials';
import {LoaderService} from '../../../_services/loader.service';
import {AgentService} from '../../../api/services/agent.service';
import {SnmpV3CredentialsService} from '../../../api/services/snmp-v-3-credentials.service';
import {ModalService} from '../../../_services/modal.service';
import {CommonService} from '../../../_services/common.services';
import {MyToastrService} from '../../../_services/toastr.service';
import {ConfirmDialogService} from '../../../_services/confirmdialog.service';
import {BaseRequestService} from '../../../_services/base.service';
import {Agent} from '../../../api/models/agent';
import {AuthenticationService} from '../../../_services/authentication.service';
import {CompanyService} from '../../../api/services/company.service';


@Component({
  selector: 'app-snmpv3',
  templateUrl: './snmpv3.component.html',
  styleUrls: ['./snmpv3.component.scss']
})

export class Snmpv3Component implements OnInit {
  @Input() currentCompany: any;
  @Input() currentAgent: any;
  addSnmpv3Width = 600;
  currentAsset: any;
  snmpv3: any = {};
  isLoading = false;
  snmpv3TableOptions: any;
  snmpv3currentPage = 0;
  snmpv3filterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  netmaskList: any = [];
  agentHash: any = {};
  agentList: any[] = [];
  currentSnmpv3Cred: any;
  probeList: any = [];
  assetTags: any = [];
  agentRef: any = {};

  authProtocol = [
    { value: '', text: 'No Protocol' },
    { value: 'md5', text: 'MD5' },
    { value: 'sha', text: 'SHA' }
  ];

  privacyProtocol = [
    { value: '', text: 'No Protocol' },
    { value: 'aes128', text: 'AES128' },
    { value: 'aes256', text: 'AES256' },
    { value: 'des', text: 'DES' }
  ];

  constructor(public baseService: BaseRequestService, public modalService: ModalService,
              public toast: MyToastrService, private loaderService: LoaderService,
              public confirmDialog: ConfirmDialogService, public commonService: CommonService,
              public agentService: AgentService, private aS: AuthenticationService,
              private companyService: CompanyService,
              public snmpV2CredentialsService: SnmpV3CredentialsService) {
    this.netmaskList = this.commonService.getSubnets();
    this.snmpv3TableOptions = {
      columns: [
        {
        header: 'Name',
        columnDef: 'name',
        filter: '',
        cell: '(element: any) => `${element.name}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }, {
        header: 'Security Name',
        columnDef: 'securityName',
        filter: '',
        cell: '(element: any) => `${element.securityName}`',
        order: 1,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'Auth Protocol',
        columnDef: 'authProtocol',
        filter: '',
        cell: '(element: any) => `${element.authProtocol}`',
        order: 2,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'Privacy Protocol',
        columnDef: 'privacyProtocol',
        filter: '',
        cell: '(element: any) => `${element.privacyProtocol}`',
        order: 3,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: 'Created',
        columnDef: 'c',
        filter: 'utcToLocale',
        cell: '(element: any) => `${element.c}`',
        order: 4,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false,
      },
      {
        header: 'Updated',
        columnDef: 'u',
        filter: 'utcToLocale',
        cell: '(element: any) => `${element.u}`',
        order: 5,
        visible: false,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }],
      sortOptions: {active: 'name', direction: 'asc'},
      faClass: 'SnmpV3Credentials',
      _pageData: [],
      tableOptions: {
        id: 'snmpv3TableOptions',
        title: 'SNMP v3',
        isServerSide: true,
        selectText: 'snmpv3(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {text: 'Copy to Probe', icon: 'content_copy', callback: 'deleteFn', isGlobal: true, hidelocal: true},
          {
          text: 'Edit',
          icon: 'edit',
          callback: 'editFn',
          isGlobal: false
        }, {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true}],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        filterDownload: false,
        add: (this.aS.hasPermission('snmpv3credentials', 'create')),
        columnSearch: false,
        compareData: false,
        service: 'snmpV3CredentialsService',
        collection: 'SnmpV3Credentials'
      }
    };
  }

  changeAgent(agent: string): void {
    this.snmpv3.agentRef.name = this.agentHash.get(agent);
  }

  updateSnmpv3(snmpv3: any): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.snmpV2CredentialsService.updateApiSnmpv3CredentialsPut({body: snmpv3}).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.toast.sToast('success', 'Updated successfully');
      this.isLoading = false;
      setTimeout(() => {
        this.getSnmpv3();
      }, 2000);
      this.modalService.close('addSnmpv3');
    });
  }

  saveSnmpv3(): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.snmpv3.companyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
    this.snmpV2CredentialsService.createApiSnmpv3CredentialsPost({body: this.snmpv3})
      .subscribe((result: SnmpV3Credentials) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = true;
        if (result._id) {
          this.modalService.close('addSnmpv3');
          this.toast.sToast('success', 'Snmpv3 added successfully!');
          setTimeout(() => {
            this.getSnmpv3();
          }, 2000);
          this.isLoading = false;
        } else {
          this.toast.sToast('error', JSON.stringify(result));
          this.isLoading = false;
        }
      });
  }

  deleteRange(snmpv3: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this Snmpv3 ' + snmpv3.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.snmpV2CredentialsService.deleteApiSnmpv3CredentialsIdDelete({id: snmpv3._id}).subscribe((result: any) => {
          this.toast.sToast('success', 'Removed successfully');
          setTimeout(() => {
            this.getSnmpv3();
          }, 2000);
        });
      }
    });
  }

  ngOnInit(): void {
    this.snmpv3TableOptions.pageData = [];
    this.getSnmpv3();
    this.getAgents();
  }

  snmpv3showHideLoading(status: any): void {
    const data = Object.assign({}, this.snmpv3TableOptions);
    this.snmpv3TableOptions = {};
    this.snmpv3TableOptions = data;
    this.snmpv3TableOptions.tableOptions.loading = status;
  }

  snmpv3globalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Delete') {
      this.deleteIPRanges(idata);
    } else if (idata.action.text === 'Copy to Probe') {
      this.isLoading = false;
      this.currentSnmpv3Cred = idata.row;
      this.openCopyToProbe();
    }
    // this.snmpv2showHideLoading(true);
    // setTimeout(() => {
    //   this.snmpv2showHideLoading(false);
    // }, 1000);
  }

  openCopyToProbe(): void {
    this.agentRef = {id: '', name: '', agents: []};
    this.probeList = this.probeList.filter((x: any) => x && x._id);
    this.agentRef.agents = this.probeList.filter((x: any) => x._id !== this.currentAgent._id);
    if (!this.agentRef.agents || !this.agentRef.agents.length) {
      this.toast.sToast('error', 'No other probes to copy!');
    } else {
      this.modalService.open('copySnmpv3ToProbe');
    }
  }

  copyToSelectProbe(agentRef: any): void {
    this.isLoading = true;
    const param: any = {
      src_agent_id: this.currentAgent._id, dest_agent_id: agentRef.id, clone_records: []
    };
    this.currentSnmpv3Cred.forEach((obj: any) => {
      param.clone_records.push(obj._id);
    });
    this.companyService.migrateAgentSettingsApiCompanyIdCloneAgentSettingsPost({id: 'imaws', body: param})
    .subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.toast.sToast('success', 'Copied successfully!');
      this.isLoading = false;
      this.toast.sToast('success', 'Copied successfully!');
      this.closeCopyProbe();
    });
  }

  closeCopyProbe(): void {
    this.currentSnmpv3Cred = []; this.agentRef = {id: '', name: '', agents: []};
    this.modalService.close('copySnmpv3ToProbe');
  }

  deleteIPRanges(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to remove the selected SNMPv3 entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, 'Deletion in progress...');
        idata.row.forEach((obj: any, index: number) => {
          this.snmpV2CredentialsService.deleteApiSnmpv3CredentialsIdDelete({id: obj._id}).subscribe((result: any) => {
            if (index === idata.row.length - 1) {
              this.loaderService.display(false);
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => { this.getSnmpv3(); }, 2000);
            }
          });
        });
      }
    });
  }

  snmpv3sortCall(idata: any): void {
    this.snmpv3TableOptions.sortOptions = idata;
    this.getSnmpv3();
  }

  snmpv3editFn(idata: any): void {
    console.log(idata);
    this.snmpv3 = idata;
    this.modalService.open('addSnmpv3');
  }

  snmpv3deleteFn(idata: any): void {
    this.deleteRange(idata);
  }

  snmpv3actionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.snmpv3editFn(dataRow);
    }
    if (idata.action.text === 'Copy to Probe') {
      this.isLoading = false;
      this.currentSnmpv3Cred = [idata.row];
      this.openCopyToProbe();
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.snmpv3deleteFn(dataRow);
    }
  }

  snmpv3filterCall(idata: any): void {
    const fields: any[] = [];
    this.snmpv3TableOptions.columns.forEach((obj: { columnDef: any; }) => {
      if (obj.columnDef !== 'c' && obj.columnDef !== 'u')
      fields.push(obj.columnDef);
    });
    this.snmpv3filterQuery = (idata && idata.length > 0) ? {
      multi_match: {
        query: idata,
        type: 'phrase_prefix',
        fields
      }
    } : undefined;
    this.getSnmpv3();
  }

  snmpv3pageCall(event: any): void {
    this.snmpv3TableOptions.tableOptions.pageSize = event.pageSize;
    this.snmpv3currentPage = event.pageIndex;
    this.getSnmpv3();
  }

  snmpv3addTableData(): void {
    this.snmpv3 = {
    authPass: '',
    privacyPass: '',
    authProtocol: '',
    securityName: '',
    privacyProtocol: '',
    name: '',
      agentRef: {id: '', name: ''}
    };
    this.snmpv3.agentRef.id = (this.currentAgent) ? this.currentAgent._id : this.agentList[0]._id;
    this.snmpv3.agentRef.name = (this.currentAgent) ? this.currentAgent.name : this.agentList[0].name;
    this.modalService.open('addSnmpv3');
  }

  snmpv3timerCallData(idata: any): void {
    this.getSnmpv3();
  }

  getAgents(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.commonService.getAgents(this.currentCompany._id).then((v2: any) => {
        this.commonService.sortFn(v2.data, 'name').then((val: any) => {
          this.agentList = val.filter((x: any) => x._id);
          this.probeList = val.filter((x: any) => x?._id);
          this.agentHash = new Map(this.agentList.map((i: any) => [i._id, i.name]));
        });
      });
    } else {
      setTimeout(() => { this.getAgents(); }, 1000);
    }
  }

  getSnmpv3(): void {
    this.loaderService.display(true, 'Getting SNMP v3');
    this.snmpv3showHideLoading(true);
    let params;
    this.snmpv3TableOptions.serverSide = {
      service: 'snmpV2CredentialsService', fn: 'getAllApiSnmpv3CredentialsGet', q: {
        query: {
          bool: {
            must: [
              {match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
              {exists: {field: 'securityName'}}],
            must_not: [{exists:{field: 'assetRef.id.keyword'}}]
          }
        }
      }
    };
    params = {
      query: {
        bool: {
          must: [
            {match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
            {exists: {field: 'securityName'}}],
          must_not: [{exists:{field: 'assetRef.id.keyword'}}]
        }
      }
    };
    if (this.currentAgent) { // @ts-ignore
      params.query.bool.must.push({match: {'agentRef.id.keyword': this.currentAgent._id + ''}});
    }
    if (this.snmpv3filterQuery && this.snmpv3filterQuery.multi_match) {
      // @ts-ignore
      params.query.bool.must.push(this.snmpv3filterQuery);
    }
    let sort: any = [{}];
    if (this.snmpv3TableOptions.sortOptions) {
      const orderArr = ['name'];
      if (orderArr.indexOf(this.snmpv3TableOptions.sortOptions.active) !== -1) {
        sort[0][this.snmpv3TableOptions.sortOptions.active + '.keyword'] = {order: this.snmpv3TableOptions.sortOptions.direction};
      } else { 
       sort[0][this.snmpv3TableOptions.sortOptions.active] = {order: this.snmpv3TableOptions.sortOptions.direction};
    }
  }
    const q = JSON.stringify(params);
    const skip = this.snmpv3currentPage;
    sort = JSON.stringify(sort);
    const limit = this.snmpv3TableOptions.tableOptions.pageSize;
    this.snmpV2CredentialsService.getAllApiSnmpv3CredentialsGet({q, skip, limit,sort})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.data.length) {
          this.snmpv3TableOptions.pageData = result.data;
          this.snmpv3TableOptions.tableOptions.pageTotal = result.total;
          this.snmpv3showHideLoading(false);
        } else {
          this.snmpv3TableOptions.pageData = [];
          this.snmpv3TableOptions.tableOptions.pageTotal = 0;
          this.snmpv3showHideLoading(false);
        }
      });
  }
}
