<div class="remedia-container">
  <mat-sidenav-container class="h-100vh remedia-sidenav-container">
    <mat-sidenav-content>
      <app-s-table aria-label="NotificationRules" role="table" [sTableOptions]="notiTableOptions" *ngIf="!addEditRules"
        (filterCallback)="rulefilterCall($event)" (actionCallback)="ruleactionCall($event)"
        (refreshCallback)="getNotificationRulesData()" (pageCallback)="rulepageCall($event)"
        (addCallback)="ruleaddTableData()" (timerCallback)="getNotificationRulesData()"></app-s-table>
      <ng-container *ngIf="addEditRules">
        <mat-card>
          <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
            (click)="addEditRules = !addEditRules; getNotificationRulesData();" mat-icon-button="">
            <mat-icon>close</mat-icon>
          </button>
          <mat-card-header>
            <mat-card-title>Ticketing Template</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <form #tagsForm="ngForm">
              <div class="row">
                <mat-form-field floatPlaceholder="auto" class="mb-2 ml-3 w-30p" appearance="outline">
                  <mat-label>Template Name</mat-label>
                  <input matInput [(ngModel)]="rule.name" name="ruleName" autocomplete="off" id="NotificationRuleName"
                    type="text" required placeholder="Enter rule name">
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-element w-30p ml-4">
                  <mat-select #companySelect [multiple]="true" required class="" placeholder="Select companies"
                    name="Company" (openedChange)="closeCurrentCompany($event)" [(ngModel)]="rule.companies"
                    [formControl]="companyCtrl"
                    (selectionChange)="rule.companies = ($event.value.includes('*')) ? ['*'] : $event.value">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="companyFilterCtrl" [placeholderLabel]="'Search Company'"
                        [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option matTooltip="All Companies" value="*">All Companies</mat-option>
                    <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async"
                      [value]="company._id">
                      {{company.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="w-30p ml-4 mb-2" appearance="outline">
                  <mat-label>Choose Integrations</mat-label>
                  <mat-select name="integrations" required [(ngModel)]="rule.integrations" multiple
                    placeholder="Choose Integrations">
                    <mat-option *ngFor="let integ of mappedIntegrations" [value]="integ.name">{{integ.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-card>
                <mat-toolbar class="table-tools" role="heading">
                  <span class="fs1"> Events
                  </span>
                  <button class="px-1 ml-2 lh-25" mat-stroked-button type="button" matTooltip="Add New Event"
                    color="primary" (click)="addEvent()" id="addEvent">
                    <mat-icon class="mat-18 mb-1">add</mat-icon> Add
                  </button>
                </mat-toolbar>
                <ng-container class="mt-2" *ngFor="let item of rule.rules; let i = index">
                  <div class="row">
                    <mat-expansion-panel class="mt-2 ml-3 w-90" [expanded]="step === i" (opened)="setStep(i)"
                      hideToggle>
                      <mat-expansion-panel-header>
                        <mat-panel-title class="mt-2">Event <span *ngIf="item.subtype_name"> -
                            {{item.subtype_name}}</span></mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row">
                        <mat-form-field floatPlaceholder="auto" class="w-30p ml-3" appearance="outline">
                          <mat-select name="eventType{{i}}" id="eventType{{i}}" required [(ngModel)]="item.type"
                            placeholder="Event of type" (selectionChange)="changeEventType($event.value,i, false)">
                            <mat-option [value]="key" *ngFor="let key of Objectkeys(rulesData)">{{key}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field floatPlaceholder="auto" class="w-30p ml-4" appearance="outline">
                          <mat-select id="eventSubType{{i}}" name="eventSubType{{i}}" required
                            [(ngModel)]="item.subtype" (selectionChange)="changeSubType($event.value,i, item.type, false)"
                            [placeholder]="'Select Sub Types'">
                            <mat-option [value]="key.id" *ngFor="let key of item.subTypeList">{{key.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="d-flex flex-row mb-3">
                        <div class="w-70p pr-4">
                          <div class="mr-3 fs1 mb-2 w150">Title</div>
                          <div>
                            <mat-form-field floatPlaceholder="auto" class="w-100 fs--8 mb-2" appearance="outline">
                              <input matInput required [(ngModel)]="item.summary_template" name="title{{i}}"
                                name="title{{i}}" type="text" required
                                placeholder="Enter default description template">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="w-30p">
                          <mat-list dense class="bor-1" class="m-h-200 overflow-auto" *ngIf="item.rulesSubKeys && item.rulesSubKeys.length">
                            <h3 matSubheader>Title Keys</h3>  
                            <ng-container *ngFor="let key of item.rulesSubKeys">
                              <mat-list-item class="bb-1"><code class="w-68p">{{key}}</code>
                                <span class="float-right r-1p">
                                  <button  mat-icon-button id="copyClip" (click)="commonService.copyClip(key)" color="primary">
                                    <mat-icon class="fs--20">file_copy</mat-icon>
                                  </button>
                                  <button  mat-icon-button id="addClip" (click)="addKeyfn('title', key, i)" color="primary">
                                    <mat-icon class="fs--20">add_box</mat-icon>
                                  </button>
                                </span>
                              </mat-list-item>
                            </ng-container>
                          </mat-list>
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="w-70p pr-4">
                          <div class="mr-3 fs1 mb-2 w150">Description</div>
                          <angular-editor required name="description{{i}}" [(ngModel)]="item.description_template"
                            [placeholder]="'create template here...'" (ngModelChange)="updateData($event)"
                            [config]="config" (blur)="updateData($event)"></angular-editor>
                        </div>
                        <div class="w-30p">
                          <mat-list dense class="bor-1" class="m-h-400 overflow-auto" *ngIf="item.rulesDataKeys && item.rulesDataKeys.length">
                            <h3 matSubheader>Description Keys</h3>
                            <ng-container *ngFor="let key of item.rulesDataKeys">
                              <mat-list-item class="bb-1"><code class="w-68p">{{key}}</code>
                                <span class="float-right r-1p">
                                  <button  mat-icon-button id="copyClip" (click)="commonService.copyClip(key)" color="primary">
                                    <mat-icon class="fs--20">file_copy</mat-icon>
                                  </button>
                                  <button  mat-icon-button id="addClip" (click)="addKeyfn('description', key, i)" color="primary">
                                    <mat-icon class="fs--20">add_box</mat-icon>
                                  </button>
                                </span>
                              </mat-list-item>
                            </ng-container>
                          </mat-list>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <div class="ml-2 mt-2">
                      <button mat-button="" class="mat-warn px-1 mr-2"
                        (click)="rule.rules.splice(i, 1); step= rule.rules.length" appPreventMultiClick
                        matTooltip="Delete Event">
                        <span class="d-flex flex-row align-items-center">
                          <i class="ms-Icon ms-font-lg ms-Icon--Delete px-1 ms-fontColor-themeDanger"></i>
                          <span class="ibtn pl-1" id="aDeleteCompany">Delete</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </mat-card>
              <div class="d-flex mt-4 flex-row align-items-center">
                <div class="spacer"></div>
                <button mat-raised-button (click)="addEditRules = !addEditRules; getNotificationRulesData();"
                  class="mr-2">Cancel</button>
                <button mat-raised-button id="SaveNotificationRule" *ngIf="!rule._id" color="primary"
                  [disabled]="!tagsForm.form.valid"
                  (click)="saveNotificationRule()">Save</button>
                <button mat-raised-button id="SaveNotificationRule" *ngIf="rule._id" color="primary"
                  [disabled]="!tagsForm.form.valid"
                  (click)="updateNotificationRule()">Update</button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>