import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {BaseRequestService} from '../../_services/base.service';
import {CommonService} from '../../_services/common.services';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit {
  @Input() data: any;
  @Input() tableType = 'property';
  objectKeys = Object.keys;
  vulsData: any = [];
  showData = false;
  propertyTable: any = [];
  constructor(private loaderService: LoaderService, public baseService: BaseRequestService, public commonService: CommonService) { }

  ngOnInit(): void {
    this.processTable();
  }

  processTable(): void {
    if (this.tableType === 'property') {
      this.data.forEach((rec: any, index: number) => {
        const a = rec.split(' : ');
        if (!a[1] && a[1] !== '') {
          a[0] = ''; a[1] = '-';
        }
        if (a[1].trim() === '') {
          if (this.data[index + 1] && this.data[index + 1].indexOf(' : ') === -1) {
            a[1] = this.data[index + 1];
          }
        }
        if (rec.indexOf(' : ') > -1) {
          this.data[index] = a;
        } else {
          a[0] = '';
          this.data[index] = a;
        }
      });
    }
  }
  navToAsset(company: any, asset: any): void {
    localStorage.setItem('cmp', company);
    localStorage.setItem('asset', asset);
    localStorage.setItem('newTab', 'cveAssets');
    window.open('/companies/company', '_blank');
  }
}
