import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '../../_services/authentication.service';
import { MyToastrService } from '../../_services/toastr.service';
import { BaseRequestService } from '../../_services/base.service';
import { LoaderService } from '../../_services/loader.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { ModalService } from '../../_services/modal.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map, tap } from 'rxjs/operators';
import { IntegrationActionsService } from '../../_services/integration-actions.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Tag } from '../../admin/assessment/assess/assess.component';
import { AgentService, CompanyService } from 'src/app/api/services';
@Component({
  selector: 'app-pii-scan',
  templateUrl: './pii-scan.component.html',
  styleUrls: ['./pii-scan.component.scss'],
})
export class PiiScanComponent implements OnInit, OnDestroy {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public agentCtrl: FormControl = new FormControl();
  public agentFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  agentList: any = [];
  addOnBlur = true;
  removable = true;
  selectable = true;
  ignoreTags: any = [];
  includeTags: any = [];
  isLoading = false;
  deniedAppCtrl = new FormControl();
  mandatoryAppCtrl = new FormControl();
  deniedMandatoryTagCtrl = new FormControl();
  @ViewChild('snav', { static: true }) snav: MatSidenav;
  @Input() currentCompany: any;
  @Input() mode: any;
  scanProfileColOptions: any;
  scanProfileQuery: any = {};
  agentIncludeLocation: any = '';
  agentExcludeLocation: any = '';
  linuxIncludeLocation: any = '';
  linuxExcludeLocation: any = '';
  fileIncludeLocation: any = '';
  fileExcludeLocation: any = '';
  companyHash: any = {};
  loading: any;
  currentScanProfile: any = {
    name: '',
    scanType: 'PII Scan',
    dbUrl: '',
    dataStores: '',
    scanSettings: [],
    pathSettings: {
      lightWeightAgent: {
        includeLocations: [],
        excludeLocations: [],
      },
      fileServerShares: {
        includeLocations: [],
        excludeLocations: [],
      },
      linuxServer: {
        includeLocations: [],
        excludeLocations: [],
      },
    },
  };
  currentScanProfile1: any = {
    name: '',
    os_type: 'windows',
    osname: 'windows',
    type: 'application',
    deniedApplications: [],
    mandatoryApplications: [{ serviceName: '', regularExpression: false }],
    deniedTags: [],
    mandatoryTags: [],
    deniedService: [
      { serviceName: '', applicationName: '', regularExpression: false },
    ],
    mandatoryService: [
      { serviceName: '', applicationName: '', regularExpression: false },
    ],
  };
  dynamicColumns: any = [];
  scanSettingList: any = [];
  dataStores: any = [];
  mandatory: any;
  mandappregex = false;
  denied: any;
  deniedappregex = false;
  deniedservice: any;
  deniedapp: any;
  deniedregex: any;
  mandatoryservice: any;
  mandatoryapp: any;
  mandatoryappregex = false;
  deniedtag: any;
  mandatorytag: any;
  getListOfOS: any = [];
  deniedTags: any = [];
  mandatoryTags: any = [];
  mandatoryApplications: any = [];
  mandatoryService: any = [];
  deniedApplications: any = [];
  deniedService: any = [];
  private actEve: Subscription;
  tempDeninedSerivce: string;
  allComp: any = [];
  formElements: any = [];
  scanParams: any = {};
  credModel: any;
  disabledSubmit = false;
  patEve: any;
  allowedExtensionTypes: any = [];
  defaultExtensionTypes: any = [];
  constructor(
    public aS: AuthenticationService,
    public loaderService: LoaderService,
    private toast: MyToastrService, private agentService: AgentService,
    private modalService: ModalService,
    public integrationActionService: IntegrationActionsService,
    private baseService: BaseRequestService,
    private confirmDialog: ConfirmDialogService,
    private companyService: CompanyService
  ) {
    this.patEve = integrationActionService.integrationValidationEVE.subscribe((res: any) => {
      this.disabledSubmit = res;
    });
    this.getCompanies();
  }

  ngOnDestroy(): void {
    this.actEve?.unsubscribe();
  }

  getCompanies(search?: string): void {
    if (!this.aS || !this.aS.isAuthenticated) {
      setTimeout(() => {
        this.getCompanies();
      }, 2000);
      return;
    }
    let cq: any;
    const cmpq = {
      query: {
        bool: {
          must: [{ exists: { field: 'description' } }],
          must_not: [
            { match: { isAssessment: true } },
            { exists: { field: 'companyRef' } },
          ],
        },
      },
    };
    const asmq = {
      query: {
        bool: {
          must: [
            { match: { isAssessment: true } },
            { exists: { field: 'description' } },
          ],
          must_not: [{ exists: { field: 'companyRef' } }],
        },
      },
    };
    cq = this.baseService.showAssessment ? asmq : cmpq;
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{ 'name.keyword': { order: 'asc' } }]);
    const fields = JSON.stringify(['name', 'c']);
    this.searching = true;
    this.companyService
      .getAllApiCompanyGet({ q, skip, limit, sort, fields })
      .subscribe(
        (result: any) => {
          if (result.data.length) {
            for (const c of result.data) {
              if (c._id) {
                this.companyHash[c._id] = c.name;
              }
            }
            result.data.sort((a: any, b: any) => {
              const c = a.name ? a.name.toLowerCase() : '';
              const d = b.name ? b.name.toLowerCase() : '';
              if (c < d) {
                return -1;
              } else if (c > d) {
                return 1;
              } else {
                return 0;
              }
            });
            this.allComp = result.data;
            this.filteredCompanies.next(result.data.slice());

            this.searching = false;
          } else {
            this.filteredCompanies.next([]);
            this.searching = false;
          }
        },
        (error: any) => {
          this.searching = false;
        }
      );
  }
  hasSelected(key: any): boolean {
    // @ts-ignore
    const len = this[key].filter((x: any) => x.selected);
    if (len && len.length) {
      return true;
    } else {
      return false;
    }
  }

  isAllSelected(key: any): boolean {
    // @ts-ignore
    const len = this[key].filter((x: any) => x.selected).length;
     // @ts-ignore
    if (this[key].length === len) {
      return true;
    }
    return false;
  }
  
  selectAllToggle(event: any, key: any): void {
    // @ts-ignore
    this[key].forEach((obj: any) => {
      obj.selected = event.checked;
    });
  }

  addLoaction(key: any, subkey: any, value: any): void {
    // @ts-ignore
    if(!this[value]) {return;}
    // @ts-ignore
    this.currentScanProfile.pathSettings[key][subkey].push(this[value]);
    // @ts-ignore
    this[value] = '';
  }

  actionCalback(idata: any): void {
    if (idata.action.text === 'Edit') {
      this.scanSettingList.filter((x: any) =>(x.selected = idata.row.scanSettings.indexOf(x.id) !== -1 ? true : false));
      this.allowedExtensionTypes.filter((x: any) =>(x.selected = idata.row.allowedExtensionTypes && idata.row.allowedExtensionTypes?.indexOf(x.id) !== -1 ? true : false));
      if(idata.row.dataStores !== 'files'){
        this.scanParams = idata.row.database[0];
        this.formElements = this.credModel[idata.row.dataStores].fieldsRequired;
      }
      if(this.mode === 'agent' && idata.row.agentid && idata.row.agentid.length !== 1 && idata.row.agentid.length === this.agentList.length){
        idata.row.agentid = ['*'];
      }
      this.currentScanProfile = idata.row;
      this.snav.open();
    } else if (idata.action.text === 'Delete') {
      this.deleteScanProfile(idata.row);
    }
  }

  addEditAppBaseline(): void {
    this.currentScanProfile = {
      name: '',
      scanType: 'PII Scan',
      dbUrl: '',
      dataStores: '',
      scanSettings: [],
      pathSettings: {
        lightWeightAgent: {
          includeLocations: [],
          excludeLocations: [],
        },
        fileServerShares: {
          includeLocations: [],
          excludeLocations: [],
        },
        linuxServer: {
          includeLocations: [],
          excludeLocations: [],
        },
      },
    };
    this.scanSettingList.map((s: any) => (s.selected = false));
    this.allowedExtensionTypes.map((s: any) => (s.selected = false));
    this.snav.open();
  }

  ngOnInit(): void {
    this.dynamicColumns = [
      {
        col: 'name',
        header: 'Profile Name',
        colFilters: { type: 'text', hKey: true },
        isKeyword: true,
        noFilter: true,
      },
      {
        col: 'scanType',
        header: 'Profile Type',
        colFilters: { type: 'text', hKey: true },
        isKeyword: true,
        noFilter: true,
      },
      {
        col: 'u',
        header: 'Created On',
        colFilters: { type: 'text', hKey: false },
        isKeyword: false,
        filter: 'utcToLocale',
      },
    ];
    this.getScanProfile();
    this.getInitialData();
  }

  getScanProfile(): void {
    this.scanProfileQuery = {
      query: {
        bool: {
          must: [
            { exists: { field: 'scanType' } },
            { exists: { field: 'scanSettings' } },
          ],
        },
      },
    };
    if(this.mode === 'agent'){
      this.scanProfileQuery.query.bool.must.push({"exists": {"field": "agentid"}}, {"terms":{ 'companyid.keyword': [this.currentCompany._id + '' ]}});
    }else{
      this.scanProfileQuery.query.bool.must_not = [{"exists": {"field": "agentid"}}];
    }
    this.scanProfileColOptions = {};
    setTimeout(() => {
      this.scanProfileColOptions = {
        id: 'scanProfile',
        title: 'PII Scan Profile',
        refresh: true,
        cFilter: true,
        pSize: 5,
        pList: [1, 5, 10, 25, 50, 100, 200],
        pagination: true,
        loading: true,
        customCols: true,
        showAction: true,
        add: true,
        faClass: 'PdScanSettings',
        serverSide: {
          service: 'pdScanSettingsService',
          fn: 'getAllApiPdscansettingsGet',
          q: this.scanProfileQuery,
          sort: [{ u: { order: 'desc' } }],
        },
        lMsg: 'Getting scan profile...',
        local: false,
        dataList: [],
        columns: this.dynamicColumns,
        actionMenuItems: [
          { text: 'Edit', icon: 'edit', isGlobal: false, callback: 'detailFn' },
          {
            text: 'Delete',
            icon: 'delete',
            isGlobal: false,
            callback: 'detailFn',
          },
        ],
      };
    });
  }

  deleteScanProfile(idata: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to delete this profile ' + idata.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: boolean) => {
      if (res) {
        this.baseService
          .doRequest(`/api/pdscansettings/${idata._id}`, 'delete')
          .subscribe((result: any) => {
            if (result) {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => {
                this.getScanProfile();
              }, 3000);
            } else {
              this.toast.sToast('error', result);
            }
          });
      }
    });
  }

  getAgents(id: any): void {
    this.loaderService.display(true, 'Getting agent data...');
    let query: any; query = {
      query: {
        bool: {
          must: [ {exists: {field: 'agent_type'}}, {match: {'companyRef.id.keyword': this.currentCompany?._id}}, {"terms": {"agent_type": [1, 3]}}]
        }
      }
    };
    const q = JSON.stringify(query);
    const skip = 0;
    const limit = 1000;
    this.agentService.getAllApiAgentGet({q, skip, limit}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result && result.data.length) {
        result.data.sort((a: any, b: any) => {
          const c = a.name.toLowerCase();
          const d = b.name.toLowerCase();
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        this.agentList = result.data;
      }
    });
  }
  
  
  getInitialData(): void {
    this.loaderService.display(true);
    this.baseService
      .doRequest(`/api/pdscansettings/getpdscansettings`, 'post', {})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          result.scanSettings?.sort((a: any, b: any) => {
            const c = (a.name) ? a.name.toLowerCase() : ''; const d = (b.name) ? b.name.toLowerCase() : '';
            if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
          });
          this.scanSettingList = result.scanSettings;
          this.allowedExtensionTypes = [];
          this.defaultExtensionTypes = [];
          this.defaultExtensionTypes = result.defaultExtensionTypes;
          result.allowedExtensionTypes?.map((x: any) => this.allowedExtensionTypes.push({id: x, name: x}));
          this.dataStores = (this.mode === 'agent') ? result.dataStores : result.dataStores?.filter((x:any) => x.level === this.mode);
          this.credModel = result.credModel;
          (this.mode === 'agent') ? this.getAgents(this.currentCompany._id) : null;
        }
      });
  }

  changeDataStore(event: any): void {
    if(event === 'files'){ return;};
    this.formElements = [];
    this.scanParams = {};
    this.credModel[event.value].fieldsRequired?.map((s:any) => {
      this.scanParams[s.name] = '';
    })
    this.formElements = this.credModel[event.value].fieldsRequired;
    
  }
  saveScanProfile(): void {
    const resData: any = JSON.parse(JSON.stringify(this.currentScanProfile));
    resData.scanSettings = this.scanSettingList.filter((x: any) => x.selected).map((s: any) => s.id);
    resData.allowedExtensionTypes = this.allowedExtensionTypes.filter((x: any) => x.selected).map((s: any) => s.id);
    if(!resData.scanSettings || !resData.scanSettings.length){
      this.toast.sToast('error', 'Please select atleast one scan type setting');
      return;
    }
    if(resData.dataStores === 'files'){
      let empty = Object.keys(resData.pathSettings).map(path => Object.keys(resData.pathSettings[path]).filter(key=> resData.pathSettings[path][key].length > 0)).map((path,i)=>({id:i,path:path.join(',')})).filter(path=>path.path);
      if(!empty || !empty.length){
        this.toast.sToast('error', 'Please add atleast one Path setting/File Server Shares/Linux Server');
        return;
      }
    }
    if(this.mode === 'agent' && (!resData.agentid || !resData.agentid.length)){
      this.toast.sToast('error', 'Please select atleast one agent');
      return;
    }
    if(this.mode !== 'agent' && (!resData.companyid || !resData.companyid.length)){
      this.toast.sToast('error', 'Please select atleast one company');
      return;
    }
    if(resData.dataStores !== 'files'){
      resData.database = [this.scanParams];
      resData.pathSettings = {
        lightWeightAgent: {
          includeLocations: [],
          excludeLocations: [],
        },
        fileServerShares: {
          includeLocations: [],
          excludeLocations: [],
        },
        linuxServer: {
          includeLocations: [],
          excludeLocations: [],
        },
      }
    }
    if(this.mode === 'agent'){
    resData.companyid = [this.currentCompany?._id];
    }
    if(this.mode === 'agent' && resData.agentid && resData.agentid[0] === '*'){
      resData.agentid = this.agentList.map((a:any) => a._id);
    }
    this.loaderService.display(true);
    if (this.currentScanProfile && this.currentScanProfile._id) {
      this.baseService
        .doRequest(`/api/pdscansettings`, 'put', resData)
        .subscribe((result: any) => {
          this.loaderService.display(false);
          if (result) {
            this.toast.sToast('success', 'Updated successfully');
            setTimeout(() => this.getScanProfile(), 1000);
            this.snav.close();
          } else {
            this.toast.sToast('error', result);
          }
        });
    } else {
      this.baseService
        .doRequest(`/api/pdscansettings`, 'post', resData)
        .subscribe((result: any) => {
          this.loaderService.display(false);
          if (result) {
            this.toast.sToast('success', 'Saved successfully');
            setTimeout(() => this.getScanProfile(), 1000);
            this.snav.close();
          } else {
            this.toast.sToast('error', result);
          }
        });
    }
  }

  actionChange(): void {}
}
