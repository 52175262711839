<mat-card class="stats bor-1 br-10 pointer" (click)="setFilter(count, title)">
  <div class="row mb-1">
    <div class="col-lg-8 fs1-2 font-weight-bold fw2 text-left">{{title }}</div>
    <div class="pointer col-lg-4 fs1-2 font-weight-bold fw5 text-right text-primary"
         matTooltip="{{count}}">{{count | shortNumber}}</div>
  </div>
  <div class="d-flex mb-1">
    <span class="spacer"></span>
    <img class="pic-img" [src]="img | safe" alt="{{title}}">
    <span class="spacer"></span>
  </div>
</mat-card>
