import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-stat-count',
  templateUrl: './stat-count.component.html',
  styleUrls: ['./stat-count.component.scss']
})
export class StatCountComponent implements OnInit {
  @Input() title: any;
  @Input() img: any;
  @Input() count: any;
  @Output() callbackCountFilter = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  setFilter(count: any, title: string): void {
    this.callbackCountFilter.next({value: count, key: title});
  }

}
