<ng-container *ngFor="let item of gettingStartedList; let i = index;">
  <mat-card class="w-100 mat-elevation-z0 pull-up p-0 mb-2">
    <div class="d-flex flex-row align-items-center bor-1 p-0">
      <div class="w-75 p-3 border-right">
        <h2>{{item.title}}</h2>
        <span class="w-100 tprime mat-small">{{item.time}}</span>
        <p class="fw2" [innerHTML]="item.description|safeHTML"></p>
        <h4 class="t-b" *ngIf="item.highlight">{{item.highlight}}</h4>
        <div class="d-flex d-flex-row align-item-center mat-small">
          <div class="p-t-1">
            <mat-icon class="mat-12">info_outline</mat-icon>
          </div>
          <div class="ml-1">{{item.linkDesc}}</div>
          <div class="ml-1">
            <a class="text-primary" [href]="item.link" target="_blank">{{item.linkText}}</a>
          </div>
          <div class="ml-1 p-t-1 text-primary">
            <mat-icon class="mat-12">link</mat-icon>
          </div>
          <span class="spacer">
          </span>
          <!-- <div class="p-0">
              <mat-icon>bolt</mat-icon>
          </div> -->
          <div class="p-t-1">
            <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Scan Now"
              *ngIf="item.button==='ScanNow'" (click)="externalScan()">
              <span class="ibtn pl-1" id="GettingStartExternalScan">Scan Now</span>
            </button>
            <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Connect Now"
              *ngIf="item.button==='ConnectNow'" (click)="Connectnow()">
              <span class="ibtn pl-1" id="GettingStartConnectNow">Connect Now</span>
            </button>
            <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Connect Active Directory"
              *ngIf="item.button==='ConnectAD'" (click)="ConnectAD()">
              <span class="ibtn pl-1" id="GettingStartConnectAD">Connect AD</span>
            </button>
            <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="integrate with providers"
              *ngIf="item.button==='ConnectInt'" (click)="Connectint()">
              <span class="ibtn pl-1" id="GettingStartInt">Integrate Now</span>
            </button>
          <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="View Remediation Plan Now"
            *ngIf="item.button==='RemediationPlan'" (click)="RemediationPlan()">
            <span class="ibtn pl-1" id="GettingStartRemediationPlan">View Now</span>
          </button>
          <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="View Now"
            *ngIf="item.button==='Patching'" (click)="RemediationPlan()">
            <span class="ibtn pl-1" id="GettingStartPatching">View Now</span>
          </button>
        <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Create Notification Rule Now"
        *ngIf="item.button==='NotificationRule'" (click)="NotificationRule()">
        <span class="ibtn pl-1" id="GettingStartNotificationRule">Create Now</span>
      </button>
      <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Manage Application Baseline Now"
        *ngIf="item.button==='ApplicationBaseline'" (click)="ApplicationBaseline()">
        <span class="ibtn pl-1" id="GettingStartApplicationBaseline">Manage Now</span>
      </button>
      <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Create Roles Now"
        *ngIf="item.button==='Roles'" (click)="Roles()">
        <span class="ibtn pl-1" id="GettingStartRoles">Create Now</span>
      </button>
      <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Manage Users Now"
        *ngIf="item.button==='Users'" (click)="Users()">
        <span class="ibtn pl-1" id="GettingStartUsers">Manage Now</span>
      </button>
      <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Schedule Now"
        *ngIf="item.button==='Scheduler'" (click)="Scheduler()">
        <span class="ibtn pl-1" id="GettingStartScheduler">Schedule Now</span>
      </button>
      <button mat-button="" class="tprime px-1 ml-1 mt--10" appPreventMultiClick matTooltip="Report Now"
        *ngIf="item.button==='ReportBuilder'" (click)="ReportBuilder()">
        <span class="ibtn pl-1" id="GettingStartReportBuilder">Report Now</span>
      </button>
          </div>
        </div>
      </div>
      <div class="w-25 p-3 text-center">
        <img [src]="item.img" alt="CyberCNS" class="md-img">
      </div>
    </div>
  </mat-card>
</ng-container>
<h1>&nbsp;</h1>
