<mat-card class="bor-1 br-10" *ngIf="showData && data">
  <mat-card-header [ngClass]="{'critical-ip': data['Vulnerabilities'] && data['Vulnerabilities'].length && data['Vulnerabilities'][0]}">
    <mat-card-title class="text-primary font-weight-bold fs1">{{ data['IP Address'] }}</mat-card-title>
    <mat-card-subtitle class="font-weight-bold">
      ASN: <span class="badge badge-primary p-2 ml-1 mr-2">{{ data['ASN'] }}</span>
      Location: <span class="badge badge-primary p-2">{{ data['Location'] }}</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="data['ports'] && data['ports'].length && data['ports'][0]">
      <h3 class="font-weight-bold mb-2">Open Port/Protocol <span class="badge badge-primary">{{data['ports'].length}}</span></h3>
      <mat-chip-list class="m-h-400 overflow-auto">
        <ng-container  *ngFor="let item of data['ports']">
          <mat-chip class="small" [ngClass]="{'scompliant text-white': item === 443, 'scritical text-white': item !== 443}">
            {{ item }} - <span [matTooltip]="commonService.portInfo[item].description"
                               *ngIf="commonService.portInfo[item]">{{commonService.portInfo[item].protocol}}</span>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
    <!--<h3 class="font-weight-bold mt-4 mb-2">ASN</h3>
    <p class="text-primary font-weight-bold fs1"></p>-->
    <div *ngIf="data['ASN Description']">
      <h3 class="font-weight-bold mt-4 mb-2">ASN Description</h3>
      <p class="text-primary font-weight-bold fs1">{{ data['ASN Description'] }}</p>
    </div>
    <div *ngIf="data['Vulnerabilities'] && data['Vulnerabilities'].length && data['Vulnerabilities'][0]">
      <h3 class="font-weight-bold mt-4 mb-2">Vulnerabilities <span class="badge badge-primary">{{data['Vulnerabilities'].length}}</span></h3>
      <mat-chip-list class="m-h-400 overflow-auto" *ngIf="false">
        <mat-chip class="scritical text-white" *ngFor="let item of data['Vulnerabilities']">
          {{ item.cve }}
        </mat-chip>
      </mat-chip-list>
      <app-custom-table [data]="data['Vulnerabilities']" [tableType]="'cve'"></app-custom-table>
    </div>
  </mat-card-content>
</mat-card>
