import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-vulnerability-stats',
  templateUrl: './vulnerability-stats.component.html',
  styleUrls: ['./vulnerability-stats.component.scss']
})

export class VulnerabilityStatsComponent implements OnInit {
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  @Input() currentCompany: any;
  @Input() mode: any;

  vulStatsColOptions: any = {};
  dynamicColumns: any = [];
  vulsData: any;
  vulsColumns: any;
  currentVulStats: any = {};
  vulStatsQuery: any = {};
  severityHtmlCols = {
    Critical: `<span class=" fw4  px-1 badge scritical">CRITICAL</span>`,
    High: `<span class=" fw4  px-1 badge shigh">HIGH</span>`,
    Medium: `<span class=" fw4  px-1 badge smedium">MEDIUM</span>`,
    Low: `<span class=" fw4  px-1 badge slow">LOW</span>`,
    Info: `<span class=" fw4  px-1 badge sinfo">INFO</span>`
  };
  vulsColOptions: any;

  constructor(public aS: AuthenticationService) { }

  viewVuls(idata: any): void {
    this.currentVulStats = idata.row;
    this.snav.open();
    this.getVulsData();
  }

  close(status?: any): void {
    if (status) { return; }
    this.snav.close();
  }

  ngOnInit(): void {
    this.dynamicColumns = [
      {
        col: 'u', header: 'osname', colFilters: {type: 'text', hKey: false}, hyperLinkCall: {
          filter: 'utcToLocale'
        }
      },
      {
        col: 'status', header: 'Status', isKeyword: true,
        htmlCols: {
          Open: `<span class=" fw4 ml-3 px-1 badge badge-danger">Open</span>`,
          Closed: `<span class=" fw4 ml-3 px-1 badge badge-success">Closed</span>`
        },
        colFilters: {
          type: 'select', hKey: false,
          options: [{value: 'Open', name: 'Open'}, {value: 'Closed', name: 'Closed'}]
        },
      },
      {col: 'name', header: 'Name', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'description', header: 'Description', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'assetRef.name', header: 'Asset Name', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
    ];
    this.vulsColumns = [
      {
        col: 'vul_id', header: 'CVE', hyperLink: {hotLink: 'https://nvd.nist.gov/vuln/detail/', match: 'CVE'},
        isKeyword: true, colFilters: {type: 'text', hKey: true}, noFilter: false
      },
      {col: 'title', header: 'Title', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {
        col: 'category',
        header: 'Category',
        colFilters: {type: 'text', hKey: true},
        isKeyword: true,
        noFilter: true
      },
      {
        col: 'product',
        header: 'Product',
        colFilters: {type: 'text', hKey: true},
        isKeyword: true,
        noFilter: true
      },
      {col: 'port', header: 'Port', colFilters: {type: 'text', hKey: false}, noFilter: true},
      {col: 'ref', header: 'Ref', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'severity', header: 'Severity', htmlCols: this.severityHtmlCols,
        isKeyword: true, colFilters: {type: 'text', hKey: true}, noFilter: false
      },
      {col: 'score.cvss_score', header: 'CVSS Score', colFilters: {type: 'text', hKey: false}, noFilter: true}
    ];
    this.getVulStatsData();
  }

  getVulsData(): void {
    this.vulsColOptions = {
          id: 'vulsColOptions',
          title: 'Vulnerabilities',
          refresh: true,
          cFilter: true,
          pSize: 5,
          pList: [1, 5, 10, 25, 50, 100, 200],
          pagination: true,
          loading: true,
          customCols: true,
          faClass: 'Vulnerability',
          serverSide: {
            service: 'vulnerabilityTimeSeriesService', fn: 'getAllApiVulnerabilitytimeseriesGet', q: {
              query: {bool: {must: [
                      {match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
                      {exists: {field: 'uniqueid'}}, {exists: {field: 'vul_id'}}]}}
            }, sort: [{}]
          },
          lMsg: 'Getting network vulnerabilities list...',
          local: false,
          dataList: [],
          columns: [
            {
              col: 'vul_id', header: 'CVE', hyperLink: {hotLink: 'https://nvd.nist.gov/vuln/detail/', match: 'CVE'},
              isKeyword: true, colFilters: {type: 'text', hKey: true}, noFilter: false
            },
            {col: 'title', header: 'Title', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
            {
              col: 'category',
              header: 'Category',
              colFilters: {type: 'text', hKey: true},
              isKeyword: true,
              noFilter: true
            },
            {
              col: 'product',
              header: 'Product',
              colFilters: {type: 'text', hKey: true},
              isKeyword: true,
              noFilter: true
            },
            {col: 'port', header: 'Port', colFilters: {type: 'text', hKey: false}, noFilter: true},
            {col: 'ref', header: 'Ref', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
            {col: 'severity', header: 'Severity', htmlCols: this.severityHtmlCols,
              isKeyword: true, colFilters: {type: 'text', hKey: true}, noFilter: false
            },
            {col: 'score.cvss_score', header: 'CVSS Score', colFilters: {type: 'text', hKey: false}, noFilter: true}
          ]
        };
  }

  totalCallbackCheck($event: any): void { // @ts-ignore
    if (this[$event.id]) { // @ts-ignore
      this[$event.id].hideTable = ($event.value === 0);
    } else {
      console.log($event.id + ' not available');
    }
  }

  getVulStatsData(): void {
    this.vulStatsQuery = {};
    if (this.mode === 'company' && this.currentCompany && this.currentCompany._id) {
      this.vulStatsQuery = {companyid: this.currentCompany._id};
    }
    this.vulStatsColOptions = {};
    setTimeout(() => {
      this.vulStatsColOptions = {
        id: 'vulStatsColOptions',
        title: 'Vulnerability Overview',
        refresh: true,
        cFilter: true,
        pSize: 50,
        pList: [1, 5, 10, 25, 50, 100, 200],
        pagination: true,
        loading: true,
        customCols: true,
        faClass: 'VulnerabilityOverview',
        lMsg: 'Getting vulnerability overview...',
        local: true,
        dataList: [],
        columns: this.dynamicColumns
      };
    });
  }
}
