<div class="remedia-container" >
  <mat-sidenav-container class="h-100vh remedia-sidenav-container">
    <mat-sidenav-content>
      <app-s-table aria-label="Agents" *ngIf="agentsView === 'agents'" role="table" [sTableOptions]="agenTableOptions" (addCallback)="addAgent()"
        (refreshCallback)="getAgents()" (actionCallback)="allActioncall($event)" (filterCallback)="filterCall($event)"
        (colFilterCallback)="colFilterCall($event)" (sortCallback)="sortCall($event)"
        (pageCallback)="agentsPagecallback($event)" (globalActionCallback)="globalActionCall($event)">
      </app-s-table>
      <mat-card *ngIf="agentsView == 'Discovery Settings'">
        <button class="float-right" mat-icon-button aria-label="close icon" matTooltip="Close"
          (click)="closeDiscoverySettings()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>{{this.currentAgent.name}} - Discovery Settings</mat-card-title>
          <mat-card-subtitle>{{this.currentAgent.agent_type}} - IP Ranges, SNMP, Active Directory and Master
            Credentials</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="currentCompany">
          <app-discovery-settings [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-discovery-settings>
        </mat-card-content>
        <mat-card-actions align="start">
          <button mat-button (click)="agentsView = 'agents'">Close</button>
        </mat-card-actions>
      </mat-card>
    </mat-sidenav-content>
    <mat-sidenav #migrate [mode]="'over'" fixedTopGap="56" class="w-50 z-index-150" position="end">
      <mat-card class="mat-elevation-z0 w-100">
        <button class="position-absolute r-1p mt--10" mat-icon-button (click)="migrate.close()"> <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>Agent Migration</mat-card-title>
        </mat-card-header>
        <form #adusr="ngForm" name="credentials" autocomplete="off">
          <mat-card-content>
            <div class="d-flex flex-row mb-1">
              <span class="fs--8 fw5">Source Company:</span>
              <span class="ml-2 fs--8 fw5">
                {{currentCompany?.name}}
              </span>
            </div>
            <span style="color:red" class="fs--8 fw5">Note: Online agents only will be migrated.</span>
            <mat-form-field appearance="outline" class="form-element w-100 mt-2">
              <mat-select #companySelect [multiple]="false" required class="" placeholder="Select destination company"
                name="destinationCompany" [(ngModel)]="migrationData.destination_companyid" [formControl]="companyCtrl"
                (selectionChange)="destinationCompanySelecte($event)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="companyFilterCtrl" [placeholderLabel]="'Search Company'"
                    [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option [matTooltip]="company.name"
                  *ngFor="let company of filteredCompanies | async"
                  [value]="company._id">
                  {{company.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div mat-dialog-actions class="mt-2">
              <button type="button" [disabled]="!migrationData.destination_companyid" mat-raised-button color="primary"
                class="mr-2" (click)="agentMigration()">Migrate
              </button>
              <button mat-raised-button (click)="migrate.close()">Cancel</button>
            </div>
          </mat-card-content>
        </form>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>

<s-modal id="addAgent" *ngIf="agentsView == 'agents'">
  <form #addA="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
      <div class="d-flex flex-row align-items-center list-right list-right w-25">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Agent" width="200px">
          <p class="mat-body-1">Add an agent text <strong>goes</strong> here</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addAgentWidth">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
          (click)="modalService.close('addAgent');">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Add agent</div>
        <mat-card-content class="">
          <mat-form-field class="w-75 mb-2" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter agent name" type="text" trim="blur" required name="agentname"
              [(ngModel)]="agent.name" autocomplete="off">
            <mat-hint>Ex. Southern avenue agent</mat-hint>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" (click)="saveAgent()"
            [disabled]="!addA.form.valid || isLoading" id="save1">Save
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('addAgent')" id="cancel1">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
<s-modal id="eventAgentLogs" *ngIf="currentCompany && [1,3].indexOf(selectedAgent) !== -1">
  <form #downloadA="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
      <div class="d-flex flex-row align-items-center list-right list-right w-25">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Download Agent" width="200px">
          <p class="mat-body-1">Fetch an agent event logs.</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addAgentWidth">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
          (click)="modalService.close('eventAgentLogs');">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">{{currentEventLogs.name}} Event Logs</div>
        <mat-card-content class="mt-2 h-200">
          <div class="d-flex ml-3 flex-column">
            <mat-form-field class="w-70p" appearance="outline">
              <input matInput [matDatepicker]="startpicker" placeholder="Start Date" [max]="fromMaxStartDate"
                [(ngModel)]="currentEventLogs.startDate" (dateChange)="changeEvent($event, 'startDate')" name="startDate">
              <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
              <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="w-70p" appearance="outline">
              <input matInput [matDatepicker]="endpicker" placeholder="End Date" [disabled]="!currentEventLogs.startDate" [min]="fromMinEndDate" [max]="fromMaxEndDate"
                [(ngModel)]="currentEventLogs.endDate" (dateChange)="changeEvent($event, 'endDate')" name="endDate">
              <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
              <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" (click)="downloadAgentEventLogs()"
            [disabled]="!downloadA.form.valid" id="save1">Fetch
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('eventAgentLogs')" id="cancel1">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
<s-modal id="agentLogs" *ngIf="currentCompany && [1,3].indexOf(selectedAgent) !== -1">
  <mat-card class="modal mat-elevation-z0 overflow-hidden" [style.width.px]="addlogsWidth">
    <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
      (click)="modalService.close('agentLogs');">
      <mat-icon>close</mat-icon>
    </button>
    <div class="mat-title ml-1">Agent Log
      <span class="fs-1rem">({{agentLogData.name}})</span>
    </div>
    <mat-card-content class="mt-2">
      <div class="terminal br-4 p-2 br-1 fw3">
        <div class="h-545" [innerHTML]="agentLogData.log | safeHTML"></div>
      </div>
    </mat-card-content>
  </mat-card>
</s-modal>
<s-modal id="probeScan" *ngIf="currentCompany && selectedAgent === 1" >
  <form #assetScan="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="companyScanWidth">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Probe Agent Scan</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('probeScan');">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>Select Scan Type </mat-card-title>
        </mat-card-header>
        <mat-card-content class="">
          <span style="color:red" class="fs--8 fw5">Note : Offline Agents will be discarded from scanning.</span>
          <mat-form-field class="w-100 mt-2" appearance="outline">
            <!-- <mat-label>Select Scan Type</mat-label> -->
            <mat-select [multiple]="false" name="scanType" required placeholder="Choose Scan Type"
              [(ngModel)]="probeScanData.scantype">
              <mat-option *ngFor="let item of scanTypeList; let i = index" [value]="item.value">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" (click)="scanCompanyNow('probe')"
            [disabled]="!assetScan.form.valid || isLoading" id="savesc11">Scan Now
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('probeScan')" id="cancescl11">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
<s-modal id="lightWeightScan" *ngIf="currentCompany && selectedAgent === 3" >
  <form #assetlightweightScan="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="companyScanWidth">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">LightWeight Agent Scan</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('lightWeightScan');">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>Select Scan Type </mat-card-title>
        </mat-card-header>
        <mat-card-content class="">
          <span style="color:red" class="fs--8 fw5">Note : Offline Agents will be discarded from scanning</span>
          <mat-form-field class="w-100 mt-2" appearance="outline">
            <!-- <mat-label>Select Scan Type</mat-label> -->
            <mat-select [multiple]="false" name="scanType" required placeholder="Choose Scan Type"
              [(ngModel)]="probeScanData.scantype">
              <mat-option *ngFor="let item of scanTypeLightWeightList; let i = index" [value]="item.value">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" (click)="scanCompanyNow('lightweight')"
            [disabled]="!assetlightweightScan.form.valid || isLoading" id="savesc1">Scan Now
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('lightWeightScan')" id="cancescl11">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
<s-modal id="updatePerformanceManagement" *ngIf="selectedAgent === 1" >
  <form #performanceScan="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="companyScanWidthPerformance">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Update Performance Management Count</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="modalService.close('updatePerformanceManagement');">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title>
            <div class="d-flex align-items-center fs1-2 mb-2">
              <span class="ms-Icon ms-font-lg px-2 ms-Icon--SkypeCircleCheck text-success"></span>
              <span class="mb-1">Maximum Allowed Process:</span>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="">
          <div class="infoccns_new mt-2 ml-3 mb-3">
            <p>
              Note: <br>
              <span class="ml-4">This tab allows you to allocate the required number of processes to be executed at a time, when a scan is triggered.</span><br>
              <span class="ml-4">This is based on the system configuration where the agent is installed.</span><br>
              <span class="ml-4">(Ex - 4 Core Processor - 16 Processes)</span><br>
              <span class="ml-4">Initially when the setting is empty / when the value is set to zero, the agent will run with according to the system configuration i.e. X Core Processes - 4 threads.</span>
            </p>
          </div>
          <div class="col-lg-8">
            <mat-form-field class="w-100 mb-2 ml-2" appearance="outline">
              <mat-label>Count</mat-label>
              <input matInput name="count"
                     [(ngModel)]="probeScanData.maxThreadCount" type="number" placeholder=""  min="0">
              <mat-hint>Ex. Min 0 </mat-hint>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" (click)="performanceManagement()"
          [disabled]="probeScanData.maxThreadCount==='' || probeScanData.maxThreadCount===null" id="savesc11">Update
          </button>
          <button mat-stroked-button class="mat-warn" (click)="modalService.close('updatePerformanceManagement')" id="cancescl11">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>