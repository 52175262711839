<app-s-table aria-label="Snmpv3" role="table" [sTableOptions]="snmpv3TableOptions" (sortCallback)="snmpv3sortCall($event)"
             (filterCallback)="snmpv3filterCall($event)" (actionCallback)="snmpv3actionCall($event)"
             (pageCallback)="snmpv3pageCall($event)" (refreshCallback)="getSnmpv3()"
             (globalActionCallback)="snmpv3globalActionCall($event)" (addCallback)="snmpv3addTableData()"
             (timerCallback)="snmpv3timerCallData($event)"></app-s-table>
<s-modal id="addSnmpv3">
  <form #addI="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
      <div class="d-flex flex-row align-items-center list-right list-right w-25">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Agent" width="200px">
          <p class="mat-body-1">Add Snmpv3 <strong>goes</strong> here</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addSnmpv3Width">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close" type="button"
                (click)="modalService.close('addSnmpv3');">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Snmpv3</div>
        <mat-card-content class="fcard-content" *ngIf="snmpv3 && snmpv3.agentRef">
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter name" type="text" trim="blur" required name="name"
                   [(ngModel)]="snmpv3.name" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Security Name</mat-label>
            <input matInput placeholder="Enter Security Name" type="text" trim="blur" required name="securityName"
                   [(ngModel)]="snmpv3.securityName" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Auth Protocol</mat-label>
            <mat-select placeholder="Choose Auth Protocol" name="authProtocol" required
                        [(ngModel)]="snmpv3.authProtocol">
              <mat-option *ngFor="let item of authProtocol" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="snmpv3.authProtocol">
            <mat-label>Auth Password</mat-label>
            <input matInput placeholder="Enter Auth Password" type="password" [required]="snmpv3.authProtocol" name="authPass"
                   [(ngModel)]="snmpv3.authPass" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Privacy Protocol</mat-label>
            <mat-select placeholder="Choose Privacy Protocol" name="privacyProtocol"
                        [(ngModel)]="snmpv3.privacyProtocol">
              <mat-option *ngFor="let item of privacyProtocol" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="snmpv3.privacyProtocol">
            <mat-label>Privacy Password</mat-label>
            <input matInput placeholder="Enter Privacy Password" type="password" [required]="snmpv3.privacyPass" name="privacyPass"
                   [(ngModel)]="snmpv3.privacyPass" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="!currentAgent">
            <mat-label>Choose Probe/Agent</mat-label>
            <mat-select placeholder="Choose Probe/Agent" name="agentRef" required
                        (selectionChange)="changeAgent(snmpv3.agentRef.id)"
                        [(ngModel)]="snmpv3.agentRef.id">
              <mat-option *ngFor="let item of agentList| sortBy:'asc':'name'" [value]="item._id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" *ngIf="!snmpv3._id" type="button"
                  (click)="saveSnmpv3()" [disabled]="!addI.form.valid || isLoading"
                  id="saveip">Save</button>
          <button mat-raised-button class="mat-primary mr-2" *ngIf="snmpv3._id" type="button"
                  (click)="updateSnmpv3(snmpv3)" [disabled]="!addI.form.valid || isLoading"
                  id="updateip">Update</button>
          <button mat-stroked-button class="mat-warn" type="button" (click)="modalService.close('addSnmpv3')"
                  id="cancelip">Cancel</button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
<s-modal id="copySnmpv3ToProbe">
  <form #copyToProbeForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="'600'">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Copy To Other Probe</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="closeCopyProbe();">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Copy To Other Probe</div>
        <mat-card-content class="">
          <p><strong>Selected SNMP V2 Credential(s):</strong></p>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <span class="mr-2 py-1 px-2 badge badge-primary mb-2" *ngFor="let range of currentSnmpv3Cred"> {{range.name}}</span>
          </div>
          <hr class="">
          <mat-form-field *ngIf="probeList && probeList.length" appearance="fill" class="w-100">
            <mat-label>Choose Probe</mat-label>
            <mat-select name="agentRef" [(ngModel)]="agentRef.id" placeholder="Choose Probe To Copy" required>
              <mat-option value="">Select Probe</mat-option>
              <mat-option *ngFor="let agent of agentRef.agents| sortBy:'asc':'name';" [value]="agent?._id">{{agent?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
                  (click)="copyToSelectProbe(agentRef)" [disabled]="!copyToProbeForm.form.valid || isLoading"
                  id="copys">Copy
          </button>
          <button mat-stroked-button class="mat-warn" (click)="closeCopyProbe();"
                  id="cancels">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
<s-modal id="copySnmpv3ToProbe">
  <form #copyToProbeForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="'600'">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Copy To Other Probe</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="closeCopyProbe();">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Copy To Other Probe</div>
        <mat-card-content class="">
          <p><strong>Selected SNMP V2 Credential(s):</strong></p>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <span class="mr-2 py-1 px-2 badge badge-primary mb-2" *ngFor="let range of currentSnmpv3Cred"> {{range.name}}</span>
          </div>
          <hr class="">
          <mat-form-field *ngIf="probeList && probeList.length" appearance="fill" class="w-100">
            <mat-label>Choose Probe</mat-label>
            <mat-select name="agentRef" [(ngModel)]="agentRef.id" placeholder="Choose Probe To Copy" required>
              <mat-option value="">Select Probe</mat-option>
              <mat-option *ngFor="let agent of agentRef.agents| sortBy:'asc':'name';" [value]="agent?._id">{{agent?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
                  (click)="copyToSelectProbe(agentRef)" [disabled]="!copyToProbeForm.form.valid || isLoading"
                  id="copys">Copy
          </button>
          <button mat-stroked-button class="mat-warn" (click)="closeCopyProbe();"
                  id="cancels">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
