/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AzureadError } from '../models/azuread-error';
import { AzureadErrorCreate } from '../models/azuread-error-create';
import { AzureadErrorGetResp } from '../models/azuread-error-get-resp';

@Injectable({
  providedIn: 'root',
})
export class AzureadErrorService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiAzureadErrorGet
   */
  static readonly GetAllApiAzureadErrorGetPath = '/api/AzureadError/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiAzureadErrorGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiAzureadErrorGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<AzureadErrorGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, AzureadErrorService.GetAllApiAzureadErrorGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AzureadErrorGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiAzureadErrorGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiAzureadErrorGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<AzureadErrorGetResp> {

    return this.getAllApiAzureadErrorGet$Response(params).pipe(
      map((r: StrictHttpResponse<AzureadErrorGetResp>) => r.body as AzureadErrorGetResp)
    );
  }

  /**
   * Path part for operation updateApiAzureadErrorPut
   */
  static readonly UpdateApiAzureadErrorPutPath = '/api/AzureadError/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiAzureadErrorPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiAzureadErrorPut$Response(params: {
    body: AzureadError
  }): Observable<StrictHttpResponse<AzureadError>> {

    const rb = new RequestBuilder(this.rootUrl, AzureadErrorService.UpdateApiAzureadErrorPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AzureadError>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiAzureadErrorPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiAzureadErrorPut(params: {
    body: AzureadError
  }): Observable<AzureadError> {

    return this.updateApiAzureadErrorPut$Response(params).pipe(
      map((r: StrictHttpResponse<AzureadError>) => r.body as AzureadError)
    );
  }

  /**
   * Path part for operation createApiAzureadErrorPost
   */
  static readonly CreateApiAzureadErrorPostPath = '/api/AzureadError/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiAzureadErrorPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiAzureadErrorPost$Response(params: {
    body: AzureadErrorCreate
  }): Observable<StrictHttpResponse<AzureadError>> {

    const rb = new RequestBuilder(this.rootUrl, AzureadErrorService.CreateApiAzureadErrorPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AzureadError>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiAzureadErrorPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiAzureadErrorPost(params: {
    body: AzureadErrorCreate
  }): Observable<AzureadError> {

    return this.createApiAzureadErrorPost$Response(params).pipe(
      map((r: StrictHttpResponse<AzureadError>) => r.body as AzureadError)
    );
  }

  /**
   * Path part for operation getApiAzureadErrorIdGet
   */
  static readonly GetApiAzureadErrorIdGetPath = '/api/AzureadError/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiAzureadErrorIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiAzureadErrorIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<AzureadError>> {

    const rb = new RequestBuilder(this.rootUrl, AzureadErrorService.GetApiAzureadErrorIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AzureadError>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiAzureadErrorIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiAzureadErrorIdGet(params: {
    id: string;
  }): Observable<AzureadError> {

    return this.getApiAzureadErrorIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<AzureadError>) => r.body as AzureadError)
    );
  }

  /**
   * Path part for operation deleteApiAzureadErrorIdDelete
   */
  static readonly DeleteApiAzureadErrorIdDeletePath = '/api/AzureadError/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiAzureadErrorIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiAzureadErrorIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AzureadErrorService.DeleteApiAzureadErrorIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiAzureadErrorIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiAzureadErrorIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiAzureadErrorIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation azureadrefreshApiAzureadErrorAzureadrefreshPost
   */
  static readonly AzureadrefreshApiAzureadErrorAzureadrefreshPostPath = '/api/AzureadError/azureadrefresh';

  /**
   * Azureadrefresh.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `azureadrefreshApiAzureadErrorAzureadrefreshPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  azureadrefreshApiAzureadErrorAzureadrefreshPost$Response(params: {
    body: {  }
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AzureadErrorService.AzureadrefreshApiAzureadErrorAzureadrefreshPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Azureadrefresh.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `azureadrefreshApiAzureadErrorAzureadrefreshPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  azureadrefreshApiAzureadErrorAzureadrefreshPost(params: {
    body: {  }
  }): Observable<any> {

    return this.azureadrefreshApiAzureadErrorAzureadrefreshPost$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
