import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from '../../../_services/loader.service';
import {ModalService} from '../../../_services/modal.service';
import {MyToastrService} from '../../../_services/toastr.service';
import {MatSidenav} from '@angular/material/sidenav';
import {CompanySharedService} from '../../../_services/company-shared.service';
import {BaseRequestService} from '../../../_services/base.service';
import {ConfirmDialogService} from '../../../_services/confirmdialog.service';
import {CommonService} from '../../../_services/common.services';
import { MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-patch-jobs',
  templateUrl: './patch-jobs.component.html',
  styleUrls: ['./patch-jobs.component.scss']
})

export class PatchJobsComponent implements OnInit {
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  @Input() currentCompany: any;
  patcTableOptions: any;
  patccurrentPage = 0;
  patcfilterQuery: any;

  jobTableOptions: any;
  jobcurrentPage = 0;
  jobfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  scheduledId: any;
  showUpper = true;
  allPatchList: any;
  constructor(private loaderService: LoaderService, private toast: MyToastrService,
              private confirmDialog: ConfirmDialogService, private comS: CommonService,
              private cs: CompanySharedService, private baseService: BaseRequestService,
              public modalService: ModalService) {
    this.jobTableOptions = {
      columns: [
        {
          header: 'Asset',
          columnDef: 'assetRef.name',
          filter: '',
          cell: '(element: any) => `${element.assetRef.name}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },  {
          header: 'Product',
          columnDef: 'product',
          filter: '',
          cell: '(element: any) => `${element.product}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }, {
        header: 'From Version',
        columnDef: 'fromVersion',
        filter: '',
        cell: '(element: any) => `${element.fromVersion}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      },{
        header: 'To Version',
        columnDef: 'toVersion',
        filter: '',
        cell: '(element: any) => `${element.toVersion}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      },  {
        header: 'Patch Response',
        columnDef: 'patching_msg',
        filter: '',
        cell: '(element: any) => `${element.patching_msg}`',
        order: 1,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }, {
        header: 'Patching Status',
        columnDef: 'patching_status',
        cell: '(element: any) => `${element.patching_status}`',
        order: 9,
        img: true, conditionList: [
          {_img: '/assets/images/loading.gif', value: 1, class: 'imageWidth'},
          {_img: '/assets/images/loading.gif', value: 2, class: 'imageWidth'},
          {_img: '/assets/images/loading.gif', value: 3, class: 'imageWidth'},
          {_img: '/assets/images/auditLogins/tick.svg', value: 5, class: 'imageWidth'},
          {_img: '/assets/images/auditLogins/cross.svg', value: 6, class: 'imageWidth'},
          {_img: '/assets/images/auditLogins/alert.svg', value: 4, class: 'imageWidth'},
          {_img: '/assets/images/scheduled.png', value: 7, class: 'imageWidthDate'},
        ],
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        imgPath: '',
        isSort: true,
        iscolumnSearch: false,
        width: '50px'
      }, {
        header: 'Ticket Id',
        columnDef: 'ticketId',
        filter: '',
        cell: '(element: any) => `${element.ticketId}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }, {
        header: 'Ticket Status',
        columnDef: 'ticketStatus',
        filter: '',
        cell: '(element: any) => `${element.ticketStatus}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }],
      sortOptions: {active: 'c', direction: 'desc'},
      _pageData: [],
      tableOptions: {
        id: 'jobTableOptions',
        title: 'Jobs',
        isServerSide: true,
        selectText: 'job(s)',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        selectedTimer: "60",
        search: false,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        hideDownload: true,
        add: false,
        columnSearch: false,
        compareData: false
      }
    };
    this.patcTableOptions = {
      columns: [
        {
          header: 'Product',
          columnDef: 'product',
          filter: '',
          cell: '(element: any) => `${element.product}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }, {
        header: 'Scheduled At',
        columnDef: 'scheduledAt',
        filter: 'epochToDate',
        cell: '(element: any) => `${element.scheduledAt}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }, {
        header: 'Scheduled Status',
        columnDef: 'scheduledStatus',
        cell: '(element: any) => `${element.status}`',
        order: 9,
        img: true, conditionList: [
          {_img: '/assets/images/scheduled.png', value: 1, class: 'imageWidthDate'},
          {_img: '/assets/images/loading.gif', value: 2, class: 'imageWidth'},
          {_img: '/assets/images/auditLogins/tick.svg', value: 3, class: 'imageWidth'},
          {_img: '/assets/images/terminate.svg', value: 4, class: 'imageWidth'},
          {_img: '/assets/images/auditLogins/cross.svg', value: 5, class: 'imageWidth'},
        ],
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        imgPath: '',
        isSort: true,
        iscolumnSearch: false,
        width: '50px'
      }, {
        header: 'Status',
        columnDef: 'scheduledMsg',
        filter: '',
        cell: '(element: any) => `${element.scheduledMsg}`',
        order: 1,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        isstatuscontrol: true,
        isHyperlink: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
          header: 'Integration Name',
          columnDef: 'integrationName',
          filter: '',
          cell: '(element: any) => `${element.integrationName}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }, {
        header: 'Updated',
        columnDef: 'u',
        filter: 'utcToLocale',
        cell: '(element: any) => `${element.u}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }],
      sortOptions: {active: 'c', direction: 'desc'},
      _pageData: [],
      tableOptions: {
        id: 'patcTableOptions',
        title: 'Scheduled Patch Jobs',
        isServerSide: true,
        selectText: 'patch job(s)',
        loading: false,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {text: 'Details', icon: 'info', callback: 'detailFn', isGlobal: false},
          {text: 'Terminate', icon: 'stop_circle', callback: 'deleteFn', isGlobal: true},
          {text: 'Delete', icon: 'delete', isGlobal: true, callback: 'deleteFn'}
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        selectedTimer: "60",
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false
      }
    };
  }

  ngOnInit(): void {
    this.patcTableOptions.pageData = [];
    this.getPatchJobs();
  }

  jobshowHideLoading(status: any): void {
    const data = Object.assign({}, this.jobTableOptions);
    this.jobTableOptions = {};
    this.jobTableOptions = data;
    this.jobTableOptions.tableOptions.loading = status;
  }

  jobsortCall(idata: any): void {
    this.jobTableOptions.sortOptions = idata;
    this.getPatchData(this.scheduledId);
  }


  jobpageCall(event: any): void {
    this.jobTableOptions.tableOptions.pageSize = event.pageSize;
    this.jobcurrentPage = event.pageIndex;
    this.getPatchData(this.scheduledId);
  }

  patcshowHideLoading(status: any): void {
    const data = Object.assign({}, this.patcTableOptions);
    this.patcTableOptions = {};
    this.patcTableOptions = data;
    this.patcTableOptions.tableOptions.loading = status;
  }
  getPatchData(idata: any): void {
    this.jobTableOptions.pageData = [];
    this.scheduledId = (idata?.row?._id) ? idata.row._id : this.scheduledId
    const query: any = {
      query: {
        bool: {
          must: [{match: {'scheduledId.keyword': `${(idata?.row?._id) ? idata.row._id : this.scheduledId}`}},
            {exists: {field: 'patching_msg'}},
            {exists: {field: 'chocoName'}}
          ]
        }
      }
    };
    if (this.jobfilterQuery && this.jobfilterQuery.multi_match) {
      query.query.bool.must.push(this.jobfilterQuery);
    }
    if (this.jobfilterQuery && this.jobfilterQuery.filter) {
      query.query.bool.filter = this.jobfilterQuery.filter;
    }
    const q = JSON.stringify(query);
    const skip = this.jobcurrentPage;
    const limit = this.jobTableOptions.tableOptions.pageSize;
    let sort: any = [{}];
    if (this.jobTableOptions.sortOptions && this.jobTableOptions.sortOptions.direction
      && this.jobTableOptions.sortOptions.direction !== '') {
      const orderArr = ['_id', 'patching_status', 'patching_msg', 'product', 'chocoName', 'fromVersion', 'assetRef.name',
        'toVersion', 'integrationName', 'ticketId', 'ticketStatus'];
      if (orderArr.indexOf(this.jobTableOptions.sortOptions.active) > -1) {
        sort[0][this.jobTableOptions.sortOptions.active + '.keyword'] = {order: this.jobTableOptions.sortOptions.direction};
      } else {
        sort[0][this.jobTableOptions.sortOptions.active] = {order: this.jobTableOptions.sortOptions.direction};
      }
    }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['product', 'fromVersion', 'toVersion', 'assetRef.name', 'ticketId', 'ticketStatus', 'patching_msg', 'patching_status']);
    this.loaderService.display(true, 'Getting patch data...');
    this.baseService.doRequest('/api/patchingstatus', 'get', null, {q, skip, limit, sort})
    .subscribe((result: any) => {
      this.loaderService.display(false);
      if (result && result.data.length) {
        this.jobTableOptions.pageData = result.data;
        this.jobTableOptions.tableOptions.pageTotal = result.total;
        this.snav.open();
      } else {
         this.toast.sToast('info', 'Patching details not available!');
      }
    });
  }

  patclinkCall(idata: any): void {
    if (idata.col === 'assetRef.name') {
      localStorage.setItem('asset', idata.row.assetRef.id);
      this.cs.AssetEVE.emit({});
    }
  }

  patcsortCall(idata: any): void {
    this.patcTableOptions.sortOptions = idata;
    this.getPatchJobs();
  }

  patcactionCall(idata: any): void {
    if (idata.action.text === 'Details') {
      this.getPatchData(idata)
    }else if (idata.action.text === 'Delete') {
      this.deletePatchConfirmationDialog(idata);
    } else if (idata.action.text === 'Terminate') {
      if (idata.row.isActive && idata.row.scheduledStatus && idata.row.scheduledStatus === 1) {
        this.terminatePatch(idata);
      } 
      else {
        this.toast.sToast('error', 'This scheduler cannot be terminated');
      }
    }
    }

    
  
  

  deletePatchConfirmationDialog(data: any): void {
    const titleName = 'Delete message';
    const message = `Are you sure you want to delete this ${data?.row?.product} scheduled patch?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService
          .doRequest(`/api/patchscheduler/${data?.row?._id}`, 'delete', {
            id: data?.row?._id,
          })
          .subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getPatchJobs();
            }, 1000);
          });
      }
    });
  }

  patcfilterCall(idata: any): void {
    const fields: any[] = [];
    this.patcTableOptions.columns.forEach((obj: any) => {
      if (obj.columnDef !== 'c' || obj.columnDef !== 'u' || obj.columnDef !== 'patching_status') {
        fields.push(obj.columnDef);
      }
    });
    this.patcfilterQuery = (idata && idata.length > 0) ? {
      multi_match: {
        query: idata,
        type: 'phrase_prefix',
        fields,
        lenient: true
      }
    } : undefined;
    this.getPatchJobs();
  }

  patcpageCall(event: any): void {
    this.patcTableOptions.tableOptions.pageSize = event.pageSize;
    this.patccurrentPage = event.pageIndex;
    this.getPatchJobs();
  }

  patctimerCallData(idata: any): void {
    this.getPatchJobs();
  }

  getPatchJobs(): void {
    this.patcshowHideLoading(true);
    const query: any = {
      query: {
        bool: {
          must: [{match: {'companyId.keyword': `${this.currentCompany._id}`}},
            {exists: {field: 'scheduledAt'}}, {exists: {field: 'isActive'}}, {exists: {field: 'chocoName'}}]
        }
      }
    };
    if (this.currentCompany && this.currentCompany._id) {
      if (this.patcfilterQuery && this.patcfilterQuery.multi_match) {
        query.query.bool.must.push(this.patcfilterQuery);
      }
      if (this.patcfilterQuery && this.patcfilterQuery.filter) {
        query.query.bool.filter = this.patcfilterQuery.filter;
      }
      const q = JSON.stringify(query);
      const skip = this.patccurrentPage;
      const limit = this.patcTableOptions.tableOptions.pageSize;
      let sort: any = [{}];
      if (this.patcTableOptions.sortOptions && this.patcTableOptions.sortOptions.direction
        && this.patcTableOptions.sortOptions.direction !== '') {
        const orderArr = ['_id', 'patching_status', 'patching_msg', 'product', 'chocoName', 'fromVersion', 'assetRef.name',
          'toVersion', 'integrationName', 'ticketId', 'ticketStatus'];
        if (orderArr.indexOf(this.patcTableOptions.sortOptions.active) > -1) {
          sort[0][this.patcTableOptions.sortOptions.active + '.keyword'] = {order: this.patcTableOptions.sortOptions.direction};
        } else {
          sort[0][this.patcTableOptions.sortOptions.active] = {order: this.patcTableOptions.sortOptions.direction};
        }
      }
      sort = JSON.stringify(sort);
      const fields = JSON.stringify(['c', 'product', 'chocoName', 'fromVersion', 'toVersion', 'assetRef.name',
        'integrationName', 'ticketId', 'ticketStatus', 'patching_msg', 'patching_status', 'assets']);
      this.loaderService.display(true, 'Getting jobs data...');
      this.baseService.doRequest('/api/patchscheduler', 'get', null, {q, skip, limit, sort})
        .subscribe((result: any) => {
          this.loaderService.display(false);
          if (result && result.data.length) {
            result.data.map((list: any) => {

              if(list.scheduledMsg.length >0){
                list.scheduledMsg = list.scheduledMsg
            }else{
                list.scheduledMsg=[0,0,list.assets.length]
            }
            // list.all_status = (list.scheduledMsg) ? `Success - ${list.scheduledMsg[0]}, Failed - ${list.scheduledMsg[1]}, Pending - ${list.scheduledMsg[2]}` : `Success - 0, Failed - 0, Pending - ${list.assets.length}`
              list.integrationName = (list.integrationName) ? list.integrationName : 'Not defined'
              list.assets.map((asset: any, index: any) => {
                list.assets[index] = {assetname: asset}
              })
            })
            this.patcTableOptions.pageData = result.data;
            this.patcTableOptions.tableOptions.pageTotal = result.total;
            this.patcshowHideLoading(false);
          } else {
            this.patcTableOptions.pageData = [];
            this.patcTableOptions.tableOptions.pageTotal = 0;
            this.patcshowHideLoading(false);
          }
        });
    } else {
      setTimeout(() => {
        this.getPatchJobs();
      }, 1000);
    }
  }

  close(status?: any) {
    this.snav.close();
  }

  terminatePatch(idata: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to terminate this scheduled patch ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true, 'Terminating scheduled patch....');
        const terminateData = {scheduledId: idata.row._id}
        this.baseService.doRequest(`api/patchscheduler/patchterminate`, 'post', terminateData)
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => this.getPatchJobs(), 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }
  patcglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Terminate') {
      this.terminatePatchs(idata);
    }else{
      this.deletePatchConfirmationDialogForAll(idata)
    }
  }

  deletePatchConfirmationDialogForAll(data: any): void {
    let cnt = 0;
    // let supportMsg = '';
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete selected scheduled patch?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(false);
        this.allPatchList = data.row;
        data.row.forEach((element: any) => {
          this.baseService.doRequest(`/api/patchscheduler/${element._id}`,
            'delete').subscribe((result: any) => {
              cnt++;
              if (cnt === this.allPatchList.length) {
                this.toast.sToast('success', 'Scheduled patch removed successfully');
                this.getPatchJobs();
                this.loaderService.display(false);
              }
          });
        });
      }
    });
  }

  terminatePatchs(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to terminate the selected patch entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        idata.row.forEach((obj: any, index: number) => {
          if(obj.isActive && obj.scheduledStatus && obj.scheduledStatus === 1) {
            this.loaderService.display(true, `Terminating ${obj.product} patch....`);
            const terminateData = {scheduledId: obj._id}
            this.baseService.doRequest(`api/patchscheduler/patchterminate`, 'post', terminateData)
              .subscribe((result: any) => {
              this.loaderService.display(false);
              if (index === idata.row.length - 1) {
                this.loaderService.display(false);
                this.toast.sToast('success', result[1]);
                setTimeout(() => { this.getPatchJobs(); }, 2000);
              }
            });
          } else {
            this.loaderService.display(false);
            this.toast.sToast('error', `${obj.product} patch cannot be terminated`);
            if (index === idata.row.length - 1) {
              setTimeout(() => { this.getPatchJobs(); }, 2000);
            }
          }
        });
      }
    });
  }
}
