import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { IntegrationActionsService } from 'src/app/_services/integration-actions.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { LoaderService } from 'src/app/_services/loader.service';
import { MyToastrService } from 'src/app/_services/toastr.service';
import { BaseRequestService } from 'src/app/_services/base.service';

@Component({
  selector: 'app-dynamic-control',
  templateUrl: './dynamic-control.component.html',
  styleUrls: ['./dynamic-control.component.scss']
})
export class DynamicControlComponent{
  searchParamsList: any = [];

  constructor(public authService: AuthenticationService, private baseService: BaseRequestService, public loaderService: LoaderService, private toast: MyToastrService, private integration: IntegrationActionsService) {
  }
  @Output() companyChange = new EventEmitter();
  @Output() valChange = new EventEmitter();
  @Input() outputValue: any;
  @Input() formElement: any;
  @Input() form!: FormGroup;

  @ViewChild('menuRefMenuTrigger', { static: false })
  menuRefMenuTrigger: MatMenuTrigger;
  @ViewChild('searchMenuTrigger', {static: false}) searchMenuTrigger: MatMenuTrigger;
  searchActionParams: any = {};
  searchFormElements: any = [];
  Objectkeys = Object.keys;
  searchParams: any = {
    pageSize: 100,
    page: 0,
    name: 'a'
  };
  searching = false;
  /** control for the selected entity */
  public entityCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();

  /** list of entities filtered by search keyword */
  public filteredEntities: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  
  get isValid(): any {
    // @ts-ignore
    return this.form.controls[this.formElement.key].valid;
  }

  ngOnInit(): void {

    this.form.statusChanges
      .pipe()
      .subscribe(() => this.onFormValid(this.form.valid));

      if(this.formElement.searchElement){
        this.searchActionParams = this.formElement.searchElement;
      }
  }

  private onFormValid(status: any) {
    this.integration.integrationValidationEVE.next(status);
  }
  // tslint:disable-next-line:use-lifecycle-interface
  /*ngOnChanges(): void {
    if (this.form === undefined) {
      this.form = this.formControlService.toFormControl(this.formElement);
    }
  }*/

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  openHelpTab(url:any): void {
    window.open(url, '_blank');
  }
  
  onKeydownMain(e: any): void {
    let i = 0;
    if (e.target.value.length < 1) {
      if (e.keyCode === 32) {
        return;
      }
    } else {
      if (e.keyCode === 32) {
        if (i !== 0) {
          return;
        }
        i++;
      } else {
        i = 0;
      }
    }
  }

  selectedFile(file: any, key: any): void {
    this.outputValue[key] = file;
  }

  callBack($event: any, callback: boolean, formElement: any): void {
    if (callback) {
      this.outputValue[formElement] = $event;
      this.valChange.emit($event);
    }
  }

  searchParamsFn(): void {
    if(this.formElement.searchElement.parameters && this.formElement.searchElement.parameters.length){
      this.searchParams = {};
      this.formElement.searchElement.parameters.forEach((obj: any) => {
        this.searchParams[obj.name] = obj.required ? '' : obj.example;
      });
    }
    this.searchParamsList = this.formElement.searchElement?.parameters?.map((obj: any) => obj.name);
    this.searchMenuTrigger.openMenu();
  }

  async processParams(data: any): Promise<any> {
    const value: any = [];
    const drow = await data.row_values;
    if (!drow) { return value; }
    drow.forEach((obj: any) => {
      const obj2: any = {};
      data.column_definitions.forEach((obj1: any, index: number) => {
        const key = this.Objectkeys(obj1)[0];
        obj2[key] = obj[index];
      });
      value.push(obj2);
    });
    return value;
  }

  getSearchParameter(): void {
    this.loaderService.display(true, `Getting ${this.formElement.searchElement.name}...`);
    const params: any = {
      integrationName: this.formElement.searchElement.integrationName,
      integrationId: this.formElement.searchElement.credentialId,
      params: {
        action: {
          destination: this.formElement.searchElement.destination,
          verb: this.formElement.searchElement.verb,
          name: this.formElement.searchElement.name,
        },
        params: { requestparams: this.searchParams },
      },
    };
    this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params).subscribe((item: any) => {
      this.loaderService.display(false);
      if (!item.data) {
        this.toast.sToast(
          'info',
          'No data found! Please change the search key and try.'
        );
        return;
      }
      if (item.status === 'failed') {
        this.toast.sToast('error', item.data);
        return;
      }
      this.processParams(item.data).then((edata: any) => {
        this.loaderService.display(false);
        const options:any = [];
        edata.forEach((val: any) => {
          options.push({
            key: val[this.formElement.searchElement.DisplayFields[0]],
            value: val[this.formElement.searchElement.mappedValue] + '',
          });
        });
        this.formElement.options = [];
        this.formElement.options = options;
      });
    });
  }
}
