<div class="w-100 d-flex flex-row">
  <div class="w-25">
    <mat-selection-list #snmp [multiple]="false" name="snmpv" (selectionChange)="updateView($event)">
      <mat-list-option *ngFor="let snmp of snmpVersions"
                       [value]="snmp" [selected]="snmp === snmpv">
        <span class="fw2">{{snmp}}</span>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div class="w-75 mb-2">
    <app-snmpv2 *ngIf="snmpv === 'SNMP v1/v2'" [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-snmpv2>
    <app-snmpv3 *ngIf="snmpv === 'SNMP v3'" [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-snmpv3>
  </div>
</div>
