<app-s-table aria-label="Agents" role="table" [sTableOptions]="masterCredTableOptions"
             (refreshCallback)="getmasterCreds()" (addCallback)="addMaster()"></app-s-table>

             <s-modal id="addMaster">
                <form #addM="ngForm">
                  <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
                    <div class="d-flex flex-row align-items-center list-right list-right w-25">
                      <div class="col-lg-12 text-center">
                        <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Agent" width="200px">
                        <p class="mat-body-1">Add an Master text <strong>goes</strong> here</p>
                      </div>
                    </div>
                    <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addMasterWidth">
                      <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                              (click)="modalService.close('addMaster');">
                        <mat-icon>close</mat-icon>
                      </button>
                      <div class="mat-title">Add Matser Credentials</div>
                      <mat-card-content class="">
                        <mat-form-field class="w-75 mb-2" appearance="outline">
                          <mat-label>Name</mat-label>
                          <input matInput placeholder="Enter Master Credentials name" type="text" trim="blur" required name="mastercredname"
                                 [(ngModel)]="master.name"
                                 autocomplete="off">
                          <mat-hint></mat-hint>
                        </mat-form-field>
                      </mat-card-content>
                      <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
                        <button mat-raised-button class="mat-primary mr-2"
                                (click)="savemasterCreds()" [disabled]="!addM.form.valid || isLoading"
                                id="saveMaster">Save
                        </button>
                        <button mat-stroked-button class="mat-warn" (click)="modalService.close('addMaster')"
                                id="cancelMaster">Cancel
                        </button>
                      </mat-card-footer>
                    </mat-card>
                  </mat-card>
                </form>
              </s-modal>
