import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-probes-agent',
  templateUrl: './probes-agent.component.html',
  styleUrls: ['./probes-agent.component.scss']
})
export class ProbesAgentComponent implements OnInit {

  constructor() { }
  probeAgent: any = 1;
  externalAgent: any = 4;
  lightWeightAgent: any = 3;
  ngOnInit(): void {
  }

}
