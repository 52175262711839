<div class="d-flex flex-column w-100" *ngIf="!data || !data.target_ips">
  <h1 class="font-weight-bold">Attack Surface Mapper</h1>
  <p class="mb-4">
    Identify and analyze various aspects of an organization's infrastructure
    that could potentially be exploited by malicious actors. Scan network hosts,
    analyze web applications, enumerate subdomains, identify open ports, and
    perform other reconnaissance tasks. It helps in prioritizing security
    efforts, focusing on areas that require immediate attention, and mitigating
    potential risks.
  </p>
  <div class="d-flex flex-row align-items-center w-100">
    <mat-form-field appearance="outline" class="w-50 mr-2">
      <mat-label>Domain name</mat-label>
      <input
        matInput
        [(ngModel)]="domain"
        type="text"
        [disabled]="currentDomain && currentDomain.status === 'Running'"
        required
        placeholder="Enter domain name"
      />
      <mat-hint>Provide the domain name that you would like to scan.</mat-hint>
    </mat-form-field>
    <button
      (click)="scanDomain()"
      [disabled]="
        !domain ||
        domain.length < 5 ||
        (currentDomain && currentDomain.status === 'Running')
      "
      mat-raised-button
      class="mr-2 mb-3"
      color="primary"
    >
      Scan
    </button>
    <button
      (click)="getDomainDetails()"
      [disabled]="
        !currentDomain || (currentDomain && currentDomain.status !== 'Running')
      "
      mat-raised-button
      class="mr-2 mb-3"
      color="primary"
    >
      Refresh
    </button>
    <button (click)="backToScan()"
      *ngIf="currentDomain && currentDomain.status === 'Running'"
      mat-raised-button class="mb-3" color="warn"> Reset
    </button>
  </div>
  <div class="errBox my-2 mat-card mat-elevation-z0 card my-2" *ngIf="errMsg">
    <div class="d-flex flex-row">
      <span class="mr-2"><mat-icon class="mat-18">error_outline</mat-icon></span>
      <span>{{errMsg}}</span>
      <span class="spacer"></span>
      <span><mat-icon class="mat-18 pointer" (click)="backToScan()">close</mat-icon></span>
    </div>
  </div>
   <div class="d-flex flex-row align-items-center justify-center"
    *ngIf="currentDomain && currentDomain.status !== 'Completed' && !errMsg">
    <div class="spacer"></div>
    <img src="/assets/images/search.gif" alt="Searching" class="img-width"/>
    <div class="spacer"></div>
  </div>
</div>
<div class="d-flex flex-row align-items-center justify-center"
    *ngIf="data && data.status === 'Completed' && !data.target_ips && errMsg">
    <div class="spacer"></div>
    <div class="d-flex flex-column align-items-center justify-center">
    <img src="/assets/images/no-data-amico.svg" alt="No Data"/>
      <div>{{errMsg}}</div>
    </div>
    <div class="spacer"></div>
</div>
<div class="d-flex flex-column w-100" #navi *ngIf="data && data.target_ips">
  <button
    (click)="backToScan()"
    mat-stroked-button
    class="mr-2 mb-3 position-absolute r-2p"
    color="warn"
  >
    Back
  </button>
  <h1 class="font-weight-bold">Attack Surface - {{ domain }}</h1>
  <div class="d-flex flex-wrap align-items-center">
    <ng-container *ngFor="let item of summary">
      <app-stat-count
        [title]="item.title"
        [img]="item.img"
        [count]="item.count"
        (callbackCountFilter)="navigateTarget($event)"
      ></app-stat-count>
    </ng-container>
  </div>
  <div class="mb-4" #targetip>
    <h2>
      Target IP Addresses
      <span class="badge badge-primary">{{ data["target_ips"].length }}</span>
    </h2>
    <div class="d-flex flex-column">
      <app-ipv
        *ngFor="let ipData of data.target_ips"
        class="mb-4"
        [data]="ipData"
      ></app-ipv>
    </div>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.s3buckets &&
      data.s3buckets.length &&
      data.s3buckets[0] &&
      data.s3buckets[0][0]
    "
  >
    <h2>S3 Buckets <span class="badge badge-primary">{{ data.s3buckets[0].length }}</span></h2>
    <div class="d-flex flex-wrap align-items-center p-2 bor-1 br-10">
      <span
        class="mr-2 mb-2 badge badge-primary p-2 fs1 fw4"
        *ngFor="let s3 of data.s3buckets[0]"
      >
        {{ s3 }}
      </span>
    </div>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.dns_records &&
      data.dns_records.length &&
      data.dns_records[0] &&
      data.dns_records[0][0]
    "
  >
    <h2>
      DNS Records
      <span class="badge badge-primary">{{
        data["dns_records"][0].length
        }}</span>
    </h2>
    <app-custom-table
      [tableType]="'property'"
      [data]="data.dns_records[0]"
    ></app-custom-table>
  </div>
  <div
    class="mb-4"
    *ngIf=" false &&
      data.email_spoof_checks &&
      data.email_spoof_checks.length &&
      data.email_spoof_checks[0] &&
      data.email_spoof_checks[0][0]
    "
  >
    <h2>Email Spoof Checks</h2>
    <app-custom-table
      [tableType]="'property'"
      [data]="data.email_spoof_checks[0]"
    ></app-custom-table>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.mx && data.mx.hosts &&
      data.mx.hosts.length
    "
  >
    <h2>MX Records</h2>
    <app-sa-table [showHeaderUpperCase]="showUpper"
              [columnsList]="objectKeys(data.mx.hosts[0])" [customCols]="mxHostsCustomCols"
              [dataList]="data.mx.hosts"></app-sa-table>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.mx && data.mx.warnings &&
      data.mx.warnings.length
    "
  >
    <h2>MX Records Warnings</h2>
    <div class="mat-card card w-100">
      <p class="t-d" *ngFor="let err of data?.mx.warnings">{{err}}</p>
    </div>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.spf && data.spf.record
    "
  >
    <h2>Sender Policy Framework(SPF) Records</h2>
    <table class="rounded-corners">
      <tbody>
        <tr> <th>Record</th> <td> {{data.spf.record}} </td> </tr>
        <tr> <th>Valid</th> <td> <span *ngIf="data.spf.valid">
                <img src="/assets/images/devices/tick.svg" class="status-icon" alt="True">
              </span>
              <span *ngIf="!data.spf.valid">
                <img src="/assets/images/devices/cross.svg" class="status-icon" alt="False">
              </span>
        </td> </tr>
        <tr> <th>DNS Lookups</th> <td> {{data.spf.dns_lookups}} </td> </tr>
        <tr> <th>DNS Void Lookups</th> <td> {{data.spf.dns_void_lookups}} </td> </tr>
      </tbody>
    </table>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.dmarc && data.dmarc.record
    "
  >
    <h2>DMARC Records</h2>
    <table class="rounded-corners">
      <tbody>
        <tr> <th>Record</th> <td> {{data.dmarc.record}} </td> </tr>
        <tr> <th>Valid</th> <td> <span *ngIf="data.dmarc.valid">
                <img src="/assets/images/devices/tick.svg" class="status-icon" alt="True">
              </span>
              <span *ngIf="!data.dmarc.valid">
                <img src="/assets/images/devices/cross.svg" class="status-icon" alt="False">
              </span> </td> </tr>
        <tr> <th>location</th> <td> {{data.dmarc.location}} </td> </tr>
      </tbody>
    </table>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.raw_headers && data.raw_headers.server
    "
  >
    <h2>RAW Headers</h2>
    <table class="rounded-corners">
      <tbody>
        <tr *ngFor="let rec of objectKeys(data.raw_headers)"> <th>{{rec}}</th> <td> {{data.raw_headers[rec]}} </td> </tr>
      </tbody>
    </table>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.guessed_emails &&
      data.guessed_emails.length &&
      data.guessed_emails[0] &&
      data.guessed_emails[0][0]
    "
  >
    <h2>
      Guessed Emails
      <span class="badge badge-primary">{{
        data["guessed_emails"][0].length
        }}</span>
    </h2>
    <mat-card>
      <mat-card-content>
        <mat-chip-list class="m-h-400 overflow-auto">
          <ng-container *ngFor="let item of data.guessed_emails[0]">
            <mat-chip class="small" *ngIf="item">
              {{ item }}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div
    class="mb-4"
    #emails
    *ngIf="
      data.emails && data.emails.length && data.emails[0] && data.emails[0][0]
    "
  >
    <h2>
      Email(s)
      <span class="badge badge-primary">{{ data["emails"][0].length }}</span>
    </h2>
    <mat-card class="bor-1 br-10">
      <mat-card-content>
        <ng-container *ngFor="let item of data.emails[0]">
          <span
            class="badge badge-primary mr-2 mb-2 p-2 fs1 fw4"
            *ngIf="item"
          >{{ item }}</span
          >
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.hashes && data.hashes.length && data.hashes[0] && data.hashes[0][0]
    "
  >
    <h2>
      Hashes
      <span class="badge badge-primary">{{ data["hashes"][0]?.length }}</span>
    </h2>
    <mat-card class="bor-1 br-10">
      <mat-card-content>
        <mat-chip-list class="m-h-400 overflow-auto">
          <ng-container *ngFor="let item of data.hashes[0]">
            <mat-chip class="small" *ngIf="item">
              {{ item }}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div
    class="mb-4"
    #usernames
    *ngIf="
      data.usernames &&
      data.usernames.length &&
      data.usernames[0] &&
      data.usernames[0][0]
    "
  >
    <h2>
      Usernames
      <span class="badge badge-primary">{{
        data["usernames"][0]?.length
        }}</span>
    </h2>
    <mat-card class="bor-1 br-10">
      <mat-card-content>
        <ng-container *ngFor="let item of data.usernames[0]">
          <span
            class="badge badge-primary mr-2 mb-2 p-2 fs1 fw4"
            *ngIf="item"
          >{{ item }}</span
          >
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
  <div
    class="mb-4"
    *ngIf="
      data.employees &&
      data.employees.length &&
      data.employees[0] &&
      data.employees[0][0]
    "
  >
    <h2>
      Employees
      <span class="badge badge-primary">{{ data.employees[0]?.length }}</span>
    </h2>
    <mat-card class="bor-1 br-10">
      <mat-card-content>
        <mat-chip-list class="m-h-400 overflow-auto">
          <ng-container *ngFor="let item of data.employees[0]">
            <mat-chip class="small bg-info" *ngIf="item">
              {{ item }}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div
    class="mb-4"
    #subdomains
    *ngIf="
      data.subdomains &&
      data.subdomains.length &&
      data.subdomains[0] &&
      data.subdomains[0][0]
    "
  >
    <h2>
      Sub Domains
      <span class="badge badge-primary">{{
        data["subdomains"][0]?.length
        }}</span>
    </h2>
    <mat-list dense class="bor-1">
      <ng-container *ngFor="let item of data.subdomains[0]">
        <mat-list-item class="bb-1" *ngIf="item">
          <a href="javascript:" (click)="redirectUrl(item)" class="fs1 fw4">{{ item }}</a>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </div>
  <div
    class="mb-4"
    *ngIf="data.creds && data.creds.length && data.creds[0] && data.creds[0][0]"
  >
    <h2>
      Creds
      <span class="badge badge-primary">{{ data["creds"][0]?.length }}</span>
    </h2>
    <mat-card class="bor-1 br-10">
      <mat-card-content>
        <mat-chip-list class="m-h-400 overflow-auto">
          <ng-container *ngFor="let item of data.creds[0]">
            <mat-chip class="small bg-info" *ngIf="item">
              {{ item }}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
