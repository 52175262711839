<ng-container>
  <app-s-table *ngIf="!isAddOrEdit" aria-label="Schedulers" role="table" [sTableOptions]="scheTableOptions"
    (sortCallback)="sortCall($event)" (addCallback)="addScheduler()" (filterCallback)="filterCall($event)"
    (actionCallback)="actionCall($event)" (pageCallback)="pageCall($event)" (refreshCallback)="getSchedulers()"
    (timerCallback)="getSchedulers()" (globalActionCallback)="globalActionCall($event)"></app-s-table>
  <div *ngIf="isAddOrEdit">
    <mat-card>
      <form class="example-form" #scheForm="ngForm" (ngSubmit)='saveScheduler(scheDetailsObj, scheForm)'>
        <mat-card class="modal">
          <button type="button" class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
            (click)="close(scheForm);" id="schClsBtn1">
            <mat-icon>close</mat-icon>
          </button>
          <span class="mat-title">{{scheTitle}} Scheduler</span>
          <mat-card-content>
            <div class="form-container">
              <table cellspacing="0">
                <tr>
                  <td class="pr-6">
                    <mat-form-field class="pl-2" placeholder="Name">
                      <mat-label>Name</mat-label>
                      <input matInput type="text" [(ngModel)]="scheDetailsObj.name" required matInput autocomplete="off"
                        placeholder="Enter Scheduler Name" name="name" id="schName">
                      <mat-error>
                        Scheduler Name is required
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td class="pr-6">
                    <mat-form-field class="pl-2">
                      <mat-label>Scan Type</mat-label>
                      <mat-select name="scanType" id="scantypeDropdown" [(ngModel)]="scheDetailsObj.uniqueid"
                        (selectionChange)="changeScanType($event.value)" required>
                        <ng-container *ngFor="let scanType of schedulerTypeList | sortBy:'asc':'name'">
                          <mat-option *ngIf="['standard_report_generation', 'patching_scheduler', 'report_builder_generation'].indexOf(scanType.uniqueId) === -1"
                            [value]="scanType.uniqueId" id="{{scanType.name}}opt"> {{scanType.name}} </mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error> ScanType is required</mat-error>
                    </mat-form-field>
                  </td>
                  <td class="pr-6">
                    <mat-form-field class="pl-2" >
                      <mat-select #companySelect [multiple]="true" required class="" placeholder="Select companies"
                        name="Company" (openedChange)="closeCurrentCompany($event)"
                        [(ngModel)]="scheDetailsObj.companyid" [formControl]="companyCtrl"
                        (selectionChange)="scheDetailsObj.companyid = ($event.value.includes('*')) ? ['*'] : $event.value; updateCurrentSite($event)">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="companyFilterCtrl"
                            [placeholderLabel]="'Search Company'"
                            [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="!currentCompany" matTooltip="All Companies" value="*">All Companies</mat-option>
                        <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async"
                          [value]="company._id">
                          {{company.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="pl-2" *ngIf="scheDetailsObj.level === 'agent'">
                      <mat-label>Agents</mat-label>
                      <mat-select name="agents" id="agentsDropdown" multiple="true" required
                        [(ngModel)]="scheDetailsObj.agentid" (selectionChange)="updateCurrentAgent($event.value)">
                        <mat-option *ngFor="let agent of agentList| sortBy:'asc':'name'" [value]="agent._id" id="opt{{agent.name}}">
                          {{agent.name}} - {{agent.companyRef.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error> Agent is required</mat-error>
                    </mat-form-field>
                  </td>
                  <td class="pr-6">
                    <mat-form-field class="pl-2" *ngIf="!currentCompany">
                      <mat-select #companySelect [multiple]="true" class="" placeholder="Select excluded companies"
                        name="Company" (openedChange)="closeCurrentExCompany($event)"
                        [(ngModel)]="scheDetailsObj.excludecompany" [formControl]="companyExCtrl"
                        (selectionChange)="updateCurrentExSite($event)">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="companyExFilterCtrl"
                            [placeholderLabel]="'Search excluded company'"
                            [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="company.name" *ngFor="let company of allComp | search: companyExFilterCtrl.value"
                          [value]="company._id">
                          {{company.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="pr-6">
                    <mat-form-field class="pl-2" >
                      <mat-select #incTagSelect [multiple]="true" class="" placeholder="Select include tags"
                        name="IncTag" [(ngModel)]="scheDetailsObj.include_tags" [formControl]="incTagCtrl">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="incTagFilterCtrl"
                            [placeholderLabel]="'Search Tag'"
                            [noEntriesFoundLabel]="'No matching tag found'" [searching]="searching">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="tag" *ngFor="let tag of tagList | search: incTagFilterCtrl.value"
                          [value]="tag">
                          {{tag}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td class="pr-6">
                    <mat-form-field class="pl-2" >
                      <mat-select #incTagSelect [multiple]="true" class="" placeholder="Select exclude tags"
                        name="ExcTag" [(ngModel)]="scheDetailsObj.exclude_tags" [formControl]="excTagCtrl">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="excTagFilterCtrl"
                            [placeholderLabel]="'Search Tag'"
                            [noEntriesFoundLabel]="'No matching tag found'" [searching]="searching">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option [matTooltip]="tag" *ngFor="let tag of tagList| search: excTagFilterCtrl.value"
                          [value]="tag">
                          {{tag}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="pr-6">
                    <mat-form-field class="pl-2">
                      <mat-label>Scheduler</mat-label>
                      <mat-select id="schedulerDropdown"
                        (selectionChange)="changeSchedulerType(scheDetailsObj.scheduler)" required
                        [(ngModel)]="scheDetailsObj.scheduler" name="repeat">
                        <mat-option *ngFor="let scheduler of schList| sortBy:'asc':'text'" [value]="scheduler.value" required
                          id="{{scheduler.text}}opt"> {{scheduler.text}} </mat-option>
                      </mat-select>
                      <mat-error> Scheduler is required </mat-error>
                    </mat-form-field>
                  </td>
                  <td *ngIf="yearly" class="pr-6">
                    <mat-form-field class="pl-2">
                      <mat-label>Months</mat-label>
                      <mat-select multiple="true"
                        (selectionChange)="yearlySchedulerType(scheDetailsObj.settings.months)" required
                        [(ngModel)]="scheDetailsObj.settings.months" name="months">
                        <mat-option *ngFor="let year of monthList" [value]="year.value" required
                          id="{{year.text}}opt">
                          {{year.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td *ngIf="monthly || yearly" class="pr-6">
                    <mat-form-field *ngIf="monthly" class="pl-2">
                      <mat-label>Days</mat-label>
                      <input #caldays matInput required readonly [(ngModel)]="scheDetailsObj.settings.days" name="days"
                        (click)="modalService.open('calPopup')" id="daysInpt">
                    </mat-form-field>
                    <s-modal id="calPopup" [width]="'28%'">
                      <span class="mat-title p-10">Choose Date(s)</span>
                      <app-custom-calendar (cancelClick)="modalService.close('calPopup')" *ngIf="monthly"
                        (finalValueChange)="MonthFinalVal($event)" [inputarray]="daysList">
                      </app-custom-calendar>
                    </s-modal>
                  </td>
                  <td *ngIf="weekly && false" class="pr-6">
                    <mat-form-field class="pl-2" placeholder="week">
                      <mat-select [multiple]="true" class="mat-small" required [(ngModel)]="scheDetailsObj.settings.week" name="weeks"
                        placeholder="Repeat Every">
                        <mat-option *ngFor="let wk of weekl" [value]="wk.value" id="{{wk.name}}hr">
                          {{wk.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td *ngIf="weekly || hourly" class="pr-6">
                    <mat-form-field class="pl-2" placeholder="Hourly" *ngIf="hourly">
                      <mat-select class="mat-small" required [(ngModel)]="scheDetailsObj.settings.hourly" name="hourl"
                        placeholder="Hourly">
                        <mat-option *ngFor="let hr of hourl" [value]="hr.value" id="{{hr.name}}hr">
                          {{hr.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="pl-2" placeholder="Week Days" *ngIf="weekly">
                      <mat-select class="mat-small" multiple="true" required [(ngModel)]="scheDetailsObj.settings.weekdays"
                        name="weekd" placeholder="Week Days">
                        <mat-option *ngFor="let week of days" [value]="week.value" id="{{week.text}}days">
                          {{week.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td colspan="3" *ngIf="daily || weekly || monthly" class="pr-6">
                    <mat-form-field class="pl-2">
                      <mat-label>Time</mat-label>
                      <input matInput placeholder="12hr format" aria-label="12hr format" type="time" class="datepicker-input" name="hour"
                           [(ngModel)]="hourMintTime" id="hrInpt">
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="pr-6">
                    <mat-form-field class="pl-2">
                      <mat-label>End</mat-label>
                      <mat-select id="endDropdown" required
                        [(ngModel)]="scheDetailsObj.end" name="endl">
                        <mat-option *ngFor="let end of endList" [value]="end.value" required
                          id="{{end.text}}opt"> {{end.text}} </mat-option>
                      </mat-select>
                      <mat-error> End is required </mat-error>
                    </mat-form-field>
                  </td>
                  <td class="pr-6">
                    <mat-form-field class="w-44" *ngIf="scheDetailsObj.end === 'count'">
                      <input type="number" required matInput  name="EndCount" id="EndCount" [(ngModel)]="scheDetailsObj.end_count">
                    </mat-form-field>
                    <mat-form-field appearance="legacy"  *ngIf="scheDetailsObj.end === 'until'" class="w-44">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" [min]="fromMinDate" name="EndUntil" [(ngModel)]="scheDetailsObj.end_until">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </td>
                </tr>
              </table>
              <mat-form-field class="pl-2" *ngIf="asset">
                <mat-label>Assets</mat-label>
                <mat-select name="assets" multiple="true" required [(ngModel)]="scheDetailsObj.assets">
                  <mat-option *ngFor="let assets of deviceData| sortBy:'asc':'assetName'" [value]="assets._id" id="opt{{assets.ip}}">
                    {{assets.assetName}} - {{assets.ip}}
                  </mat-option>
                </mat-select>
                <mat-error> Asset is required </mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
          <div class="ml-2 mt-2 mb-2 d-flex flex-row alight-item-center">
            <span class="mt-3 mb-3 mr-3">Active</span>
            <mat-slide-toggle name="isActive" id="schrTglBtn" class="example-margin mt-3" color="primary"
              [(ngModel)]="scheDetailsObj.isActive">
            </mat-slide-toggle>
          </div>
          <div mat-dialog-actions>
            <button type="button" mat-raised-button color="primary"
              [disabled]="!scheForm.form.valid && isEmptyArray(scheDetailsObj.email)" type="submit" class="mr-2"
              id="schSavebtn">Save
            </button>
            <button type="button" mat-button (click)="close(scheForm);" id="schClsbtn">Close</button>
          </div>
        </mat-card>
      </form>
    </mat-card>
  </div>
</ng-container>
<s-modal id="scanModal" >
  <mat-toolbar-row class="p-0">
    <mat-icon  class="float-left mb-2 ml-2 mat-warn">warning</mat-icon>
    <h2 class="ml-1 mt-1">Time Error</h2>
  </mat-toolbar-row>
  <div mat-dialog-content>
    <p class="center ml-2 mr-2">Time of 12:00 AM is not supported. Please set the time as either 11:59 PM or 12:01 AM.</p>
    <div mat-dialog-actions class="mt-4 ">
      <button type="button" mat-button (click)="modalService.close('scanModal');" mat-raised-button color="primary" class="mb-2 ml-2">Ok </button>
    </div>
  </div>
 </s-modal>
