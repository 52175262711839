<div class="over-flow w-100">
  <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
    <thead>
      <tr class="mat-header-row">
        <th mat-sort-header="vul_id" class="mat-header-cell text-uppercase">Vuls ID</th>
        <th mat-sort-header="cvss_score" class="mat-header-cell text-uppercase">CVSS Score</th>
        <th mat-sort-header="severity" class="mat-header-cell text-uppercase">Severity</th>
        <th mat-sort-header="port" class="mat-header-cell text-uppercase">Port</th>
      </tr>
    </thead>
    <tbody>
      <tr class="mat-row" *ngFor="let asset of sortedData;">
        <td class="mat-cell">{{asset.vul_id}}</td>
        <td class="mat-cell">{{asset.cvss_score}} </td>
        <td class="mat-cell">
          <span *ngIf="asset.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
          <span *ngIf="asset.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
          <span *ngIf="asset.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
          <span *ngIf="asset.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
          <span *ngIf="asset.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
        </td>
        <td class="mat-cell">{{asset.port}}</td>
      </tr>
    </tbody>
  </table>
</div>
