/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssetpermissionGetResp } from '../models/assetpermission-get-resp';
import { UserPermissions } from '../models/user-permissions';
import { UserPermissionsCreate } from '../models/user-permissions-create';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiUserPermissionsGet
   */
  static readonly GetAllApiUserPermissionsGetPath = '/api/UserPermissions/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiUserPermissionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiUserPermissionsGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<AssetpermissionGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, UserPermissionsService.GetAllApiUserPermissionsGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssetpermissionGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiUserPermissionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiUserPermissionsGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<AssetpermissionGetResp> {

    return this.getAllApiUserPermissionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<AssetpermissionGetResp>) => r.body as AssetpermissionGetResp)
    );
  }

  /**
   * Path part for operation updateApiUserPermissionsPut
   */
  static readonly UpdateApiUserPermissionsPutPath = '/api/UserPermissions/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiUserPermissionsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiUserPermissionsPut$Response(params: {
    body: UserPermissions
  }): Observable<StrictHttpResponse<UserPermissions>> {

    const rb = new RequestBuilder(this.rootUrl, UserPermissionsService.UpdateApiUserPermissionsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPermissions>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiUserPermissionsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiUserPermissionsPut(params: {
    body: UserPermissions
  }): Observable<UserPermissions> {

    return this.updateApiUserPermissionsPut$Response(params).pipe(
      map((r: StrictHttpResponse<UserPermissions>) => r.body as UserPermissions)
    );
  }

  /**
   * Path part for operation createApiUserPermissionsPost
   */
  static readonly CreateApiUserPermissionsPostPath = '/api/UserPermissions/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiUserPermissionsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiUserPermissionsPost$Response(params: {
    body: UserPermissionsCreate
  }): Observable<StrictHttpResponse<UserPermissions>> {

    const rb = new RequestBuilder(this.rootUrl, UserPermissionsService.CreateApiUserPermissionsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPermissions>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiUserPermissionsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiUserPermissionsPost(params: {
    body: UserPermissionsCreate
  }): Observable<UserPermissions> {

    return this.createApiUserPermissionsPost$Response(params).pipe(
      map((r: StrictHttpResponse<UserPermissions>) => r.body as UserPermissions)
    );
  }

  /**
   * Path part for operation getApiUserPermissionsIdGet
   */
  static readonly GetApiUserPermissionsIdGetPath = '/api/UserPermissions/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiUserPermissionsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiUserPermissionsIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UserPermissions>> {

    const rb = new RequestBuilder(this.rootUrl, UserPermissionsService.GetApiUserPermissionsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPermissions>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiUserPermissionsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiUserPermissionsIdGet(params: {
    id: string;
  }): Observable<UserPermissions> {

    return this.getApiUserPermissionsIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserPermissions>) => r.body as UserPermissions)
    );
  }

  /**
   * Path part for operation deleteApiUserPermissionsIdDelete
   */
  static readonly DeleteApiUserPermissionsIdDeletePath = '/api/UserPermissions/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiUserPermissionsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiUserPermissionsIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UserPermissionsService.DeleteApiUserPermissionsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiUserPermissionsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiUserPermissionsIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiUserPermissionsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
