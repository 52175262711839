import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-scheduler-details',
  templateUrl: './scheduler-details.component.html',
  styleUrls: ['./scheduler-details.component.scss']
})
export class SchedulerDetailsComponent implements OnInit {
  @Input() currentCompany: any;
  @Input() schedulerType: any;
  @Input() mode: any;
  constructor() { }

  ngOnInit(): void {
  }

}
