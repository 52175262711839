<mat-card *ngIf="cView === 'EventSet'">
  <mat-card-content>
    <app-s-table aria-label="Alert Rules" role="table" [sTableOptions]="alertTableOptions"
      (actionCallback)="alertactionCall($event)" (pageCallback)="alertpageCall($event)"
      (sortCallback)="alertsortCall($event)" (refreshCallback)="getAlertRules()" (addCallback)="addAlertRule()"
      (timerCallback)="getAlertRules()"></app-s-table>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="cView === 'IntegrationSetting'">
  <mat-card-content>
    <app-s-table aria-label="Notification Rule" role="table" [sTableOptions]="integrationTableOptions"
      (actionCallback)="actionCall($event)" (pageCallback)="pageCall($event)" (sortCallback)="sortCall($event)"
      (refreshCallback)="getIntegrationRules()" (addCallback)="addIntegrationRule()"
      (timerCallback)="getIntegrationRules()"></app-s-table>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="cView === 'addEditEventSet'">
  <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
    (click)="cView = 'EventSet'; getAlertRules();" mat-icon-button="">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-header>
    <mat-card-title> Event Set</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form #addevent="ngForm">
      <div class="row">
        <mat-form-field floatPlaceholder="auto" class="w-50 ml-3" appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="eventData.name" autocomplete="off" name="ruleName" id="EventName" type="text"
            required placeholder="Enter name">
        </mat-form-field>
      </div>
      <!-- <button (click)="selectDeselectAll(true)" class="mr-2 mb-2" mat-stroked-button="">Select All</button>
      <button (click)="selectDeselectAll(false)" class="mr-2 mb-2" mat-stroked-button="">Deselect All</button> -->
      <ng-container *ngIf="loading">
        <mat-card>
          <mat-card-content>
            <div class="loader-class">
              <img src="/assets/images/loading.gif" width="30" alt="Loading">
              <p>Getting alert notification rules... </p>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="!loading">
        <ng-container *ngFor="let item of alertRule; let i = index;">
          <mat-accordion>
            <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title class="tprimary">
                  <div class="d-flex align-items-center justify-center">
                    <img [src]="iconList[item.name].icon" width="25px" class="mr-3" [alt]="item.name">
                  </div>
                  <span class=""><b>{{item.name|camelToHuman:true}}</b></span>
                </mat-panel-title>
                <mat-panel-description>
                  {{iconList[item.name].description}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-container *ngFor="let it of item.alerts;">
                <ng-container *ngFor="let event of it.events">
                  <mat-list class="w-100" *ngIf="!event.isclose || event.isclose.toLowerCase() !== 'yes'">
                    <mat-list-item class="bb-1">
                      <span class="mr-2">
                        <mat-checkbox required [(ngModel)]="event.selected" color="primary" [id]="event.id"
                          [name]="event.id"></mat-checkbox>
                      </span>
                      <span class="mb-2 ml-2" [matTooltip]="it.name">{{event.name}}</span>
                    </mat-list-item>
                  </mat-list>
                </ng-container>
                <ng-container *ngFor="let event of it.eventGroups let i = index">
                  <div class="mb-3 mt-1">
                    <p *ngIf="item.name === 'remediation'" class="badge badge-info tbg-info text-primary py-2 fw6 fs--8 px-3 mb-2">
                      <b *ngIf="i===0">Remediation Group</b> <b *ngIf="i===1"> Remediation Filters</b>
                    </p>
                    <div class="notes notes1 mt-1 mb-1" *ngIf="i===1">
                      <span><b>Note:</b><br>
                        <span class="ml-2">Please select any one of the event set from the Remediation Group list, to be able to select the event set from the Remediation filter list.</span>
                      </span>
                    </div>
                  <mat-radio-group [(ngModel)]="event.value" *ngIf="event.name" [name]="event.name" [disabled]="i===1 && !it.eventGroups[0]?.value">
                    <ng-container *ngFor="let eve of event.events">
                      <mat-list class="w-100" *ngIf="!eve.isclose || eve.isclose.toLowerCase() !== 'yes'">
                        <mat-list-item class="bb-1">
                          <span class="mr-2">
                                <mat-radio-button color="primary" [value]="eve.id" [id]="eve.id">
                                </mat-radio-button>
                          </span>
                          <span class="mb-2 ml-2" [matTooltip]="it.name">{{eve.name}}</span>
                        </mat-list-item>
                      </mat-list>
                    </ng-container>
                  </mat-radio-group></div>
                  <div class="mt-3 mb-5">
                    <button mat-raised-button color="primary" class="float-right" (click)="event.value=''">Clear</button>
                  </div>
                </ng-container>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
        <p class="fw3 pt-1 mat-small">Please select either 'Remediation By Company' or 'Remediation By Asset' or
          'Remediation By Product' or 'Remediation By Asset And Product'</p>
      </ng-container>
      <div mat-dialog-actions class="mt-4 float-right">
        <button mat-raised-button color="primary" class="mr-3" (click)="saveAlertRules()">Save</button>
        <button class="mat-raised-button" (click)="cView = 'EventSet';" mat-button>Cancel</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="cView === 'addEditIntegrationSetting'">
  <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
    (click)="cView = 'IntegrationSetting'; getIntegrationRules();" mat-icon-button="">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-header>
    <mat-card-title> Integration Profile</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form #addinte="ngForm">
      <mat-form-field floatPlaceholder="auto" class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="currentIntegrationRule.name" autocomplete="off" name="ruleName"
          id="NotificationRuleName" type="text" required placeholder="Enter name">
      </mat-form-field>
      <mat-form-field floatPlaceholder="auto" class="w-100" appearance="outline">
        <mat-label>Choose Credential Profile</mat-label>
        <mat-select name="credD" [(ngModel)]="currentIntegrationRule.credentialId" required
          placeholder="Choose Credential" (selectionChange)="setCred($event.value)">
          <mat-option [value]="cred._id" *ngFor="let cred of integrationsData">{{cred.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-dynamic-form class="col-lg-8" [isSaveBtn]="false" [isCloseBtn]="false" *ngIf="defaultActionIntegration.actionData
                          && defaultActionIntegration.formElements && defaultActionIntegration.formElements.length"
        (valueUpdate)="actionChange($event)" [listOfFormElements]="defaultActionIntegration.formElements"
        [Valuesoutput]="defaultActionIntegration.actionData">
      </app-dynamic-form>
      <div mat-dialog-actions class="mt-52 float-right">
        <button class="mat-raised-button mr-3" mat-button color="primary" (click)="saveIntegrationRules()"
          [disabled]="isLoading || !addinte.form.valid || !disabledSubmit" *ngIf="defaultActionIntegration.actionData
          && defaultActionIntegration.formElements && defaultActionIntegration.formElements.length">Save</button>
        <button class="mat-raised-button mr-3" mat-button color="primary" (click)="saveIntegrationRules()"
          [disabled]="isLoading || !addinte.form.valid" *ngIf="!defaultActionIntegration.actionData
          || !defaultActionIntegration.formElements || !defaultActionIntegration.formElements.length">Save</button>
        <button class="mat-raised-button" (click)="cView = 'IntegrationSetting';" mat-button>Cancel</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
