/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PdScanSettings } from '../models/pd-scan-settings';
import { PdScanSettingsCreate } from '../models/pd-scan-settings-create';
import { PdScanSettingsGetResp } from '../models/pd-scan-settings-get-resp';
import { GetPdScanSettingsInput } from '../models/get-pd-scan-settings-input';
import { SavePdScanSettingsInput } from '../models/save-pd-scan-settings-input';

@Injectable({
  providedIn: 'root',
})
export class PdScanSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiPdscansettingsGet
   */
  static readonly GetAllApiPdscansettingsGetPath = '/api/pdscansettings/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiPdscansettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiPdscansettingsGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<PdScanSettingsGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.GetAllApiPdscansettingsGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettingsGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiPdscansettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiPdscansettingsGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<PdScanSettingsGetResp> {

    return this.getAllApiPdscansettingsGet$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettingsGetResp>) => r.body as PdScanSettingsGetResp)
    );
  }

  /**
   * Path part for operation updateApiPdscansettingsPut
   */
  static readonly UpdateApiPdscansettingsPutPath = '/api/pdscansettings/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiPdscansettingsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiPdscansettingsPut$Response(params: {
    body: PdScanSettings
  }): Observable<StrictHttpResponse<PdScanSettings>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.UpdateApiPdscansettingsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettings>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiPdscansettingsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiPdscansettingsPut(params: {
    body: PdScanSettings
  }): Observable<PdScanSettings> {

    return this.updateApiPdscansettingsPut$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettings>) => r.body as PdScanSettings)
    );
  }

  /**
   * Path part for operation createApiPdscansettingsPost
   */
  static readonly CreateApiPdscansettingsPostPath = '/api/pdscansettings/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiPdscansettingsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiPdscansettingsPost$Response(params: {
    body: PdScanSettingsCreate
  }): Observable<StrictHttpResponse<PdScanSettings>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.CreateApiPdscansettingsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettings>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiPdscansettingsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiPdscansettingsPost(params: {
    body: PdScanSettingsCreate
  }): Observable<PdScanSettings> {

    return this.createApiPdscansettingsPost$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettings>) => r.body as PdScanSettings)
    );
  }

  /**
   * Path part for operation getApiPdscansettingsIdGet
   */
  static readonly GetApiPdscansettingsIdGetPath = '/api/pdscansettings/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPdscansettingsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPdscansettingsIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<PdScanSettings>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.GetApiPdscansettingsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettings>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiPdscansettingsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPdscansettingsIdGet(params: {
    id: string;
  }): Observable<PdScanSettings> {

    return this.getApiPdscansettingsIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettings>) => r.body as PdScanSettings)
    );
  }

  /**
   * Path part for operation deleteApiPdscansettingsIdDelete
   */
  static readonly DeleteApiPdscansettingsIdDeletePath = '/api/pdscansettings/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiPdscansettingsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPdscansettingsIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.DeleteApiPdscansettingsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiPdscansettingsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPdscansettingsIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiPdscansettingsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getPdscanSettingsApiPdscansettingsGetpdscansettingsPost
   */
  static readonly GetPdscanSettingsApiPdscansettingsGetpdscansettingsPostPath = '/api/pdscansettings/getpdscansettings';

  /**
   * Get Pdscan Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPdscanSettingsApiPdscansettingsGetpdscansettingsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPdscanSettingsApiPdscansettingsGetpdscansettingsPost$Response(params: {
    body: GetPdScanSettingsInput
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.GetPdscanSettingsApiPdscansettingsGetpdscansettingsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Get Pdscan Settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPdscanSettingsApiPdscansettingsGetpdscansettingsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPdscanSettingsApiPdscansettingsGetpdscansettingsPost(params: {
    body: GetPdScanSettingsInput
  }): Observable<any> {

    return this.getPdscanSettingsApiPdscansettingsGetpdscansettingsPost$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation savePdscanDataApiPdscansettingsSavepdscandataPost
   */
  static readonly SavePdscanDataApiPdscansettingsSavepdscandataPostPath = '/api/pdscansettings/savepdscandata';

  /**
   * Save Pdscan Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePdscanDataApiPdscansettingsSavepdscandataPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePdscanDataApiPdscansettingsSavepdscandataPost$Response(params: {
    body: SavePdScanSettingsInput
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.SavePdscanDataApiPdscansettingsSavepdscandataPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Save Pdscan Data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePdscanDataApiPdscansettingsSavepdscandataPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePdscanDataApiPdscansettingsSavepdscandataPost(params: {
    body: SavePdScanSettingsInput
  }): Observable<any> {

    return this.savePdscanDataApiPdscansettingsSavepdscandataPost$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
