<mat-tab-group>
    <mat-tab label="Probes / Agent">
        <ng-template matTabContent>
            <div class="p-6">
                <app-agents [selectedAgent]= "probeAgent" [mode]="'global'"></app-agents>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Lightweight Agent">
        <ng-template matTabContent>
            <div class="p-6">
              <app-agents [selectedAgent]= "lightWeightAgent" [mode]="'global'"></app-agents>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Deprecated Agent">
        <ng-template matTabContent>
            <div class="p-6">
              <app-agents [isDeprecated]="true" [mode]="'global'"></app-agents>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>