import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, NavigationError, Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {CommonService} from '../../_services/common.services';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CompanyService } from 'src/app/api/services';

@Component({
  selector: 'app-networkasset',
  templateUrl: './networkasset.component.html',
  styleUrls: ['./networkasset.component.scss']
})
export class NetworkassetComponent implements OnInit {
  @Input() solution: any;
  @Input() currentCompany?: any;
  @Input() companyId?: any;
  assets: any = [];
  sortedData: any = [];
  companyHash: any = {};
  constructor(private cs: CommonService, private companyService: CompanyService,
    private baseService: BaseRequestService,) { }

  ngOnInit(): void {
    this.getAllCompany();
    this.assets = this.solution.assets;
    this.sortedData = this.assets.slice();
    console.log(this.assets);
  }

  sortData(sort: Sort): any {
    let sortOrder = ['Critical', 'High', 'Medium', 'Low', 'Info'];
    const data = this.assets.slice();
    if (sort.active === 'severity') {
      if (sort.direction === 'desc') {
        sortOrder = ['Info', 'Low', 'Medium', 'High', 'Critical'];
      }
      this.sortedData.sort((a: any, b: any) => {
        return sortOrder.indexOf(a[sort.active]) - sortOrder.indexOf(b[sort.active]);
      });
      return;
    }
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active) {
          return this.cs.compare(a[sort.active], b[sort.active], isAsc);
      }
    });
  }

  getAllCompany(): void {
    let cq: any;
    const cmpq = {query: {bool: {must: [{exists: {field: 'description'}}], must_not: [{match: {isAssessment: true}}, {exists: {field: 'companyRef'}}]}}};
    const asmq = {query: {bool: {must: [{match: {isAssessment: true}}, {exists: {field: 'description'}}], must_not: [{exists: {field: 'companyRef'}}]}}};
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    const fields = JSON.stringify(['name', 'customerInfo', 'c']);
    this.companyService.getAllApiCompanyGet({q, skip, limit, sort, fields}).subscribe((result: any) => {
      if (result.data.length) {
        result.data.map((x: any) => (x._id) ?  this.companyHash[x._id] = x : null);
      }
    });
  }

  redirectToAsset(asset: any): any {
    if (!this.cs.currentCompany) {
      this.cs.currentCompany = (!this.currentCompany || !this.currentCompany.name)
        ? this.companyHash[this.companyId] : this.cs.cHash[this.currentCompany._id];
    }
    localStorage.setItem('newTab', 'cveAssets');
    localStorage.setItem('cmp', (this.currentCompany?.name) ? this.currentCompany.name : this.companyHash[this.companyId].name);
    localStorage.setItem('asset', asset.id);
    window.open('/company/companies', '_blank');
  }
}