/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Edr } from '../models/edr';
import { EdrCreate } from '../models/edr-create';
import { EdrGetResp } from '../models/edr-get-resp';
import { GetdefaultedrParams } from '../models/getdefaultedr-params';

@Injectable({
  providedIn: 'root',
})
export class EdrService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiEdrGet
   */
  static readonly GetAllApiEdrGetPath = '/api/edr/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiEdrGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiEdrGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<EdrGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, EdrService.GetAllApiEdrGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EdrGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiEdrGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiEdrGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<EdrGetResp> {

    return this.getAllApiEdrGet$Response(params).pipe(
      map((r: StrictHttpResponse<EdrGetResp>) => r.body as EdrGetResp)
    );
  }

  /**
   * Path part for operation updateApiEdrPut
   */
  static readonly UpdateApiEdrPutPath = '/api/edr/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiEdrPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiEdrPut$Response(params: {
    body: Edr
  }): Observable<StrictHttpResponse<Edr>> {

    const rb = new RequestBuilder(this.rootUrl, EdrService.UpdateApiEdrPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Edr>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiEdrPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiEdrPut(params: {
    body: Edr
  }): Observable<Edr> {

    return this.updateApiEdrPut$Response(params).pipe(
      map((r: StrictHttpResponse<Edr>) => r.body as Edr)
    );
  }

  /**
   * Path part for operation createApiEdrPost
   */
  static readonly CreateApiEdrPostPath = '/api/edr/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiEdrPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiEdrPost$Response(params: {
    body: EdrCreate
  }): Observable<StrictHttpResponse<Edr>> {

    const rb = new RequestBuilder(this.rootUrl, EdrService.CreateApiEdrPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Edr>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiEdrPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiEdrPost(params: {
    body: EdrCreate
  }): Observable<Edr> {

    return this.createApiEdrPost$Response(params).pipe(
      map((r: StrictHttpResponse<Edr>) => r.body as Edr)
    );
  }

  /**
   * Path part for operation getApiEdrIdGet
   */
  static readonly GetApiEdrIdGetPath = '/api/edr/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiEdrIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiEdrIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Edr>> {

    const rb = new RequestBuilder(this.rootUrl, EdrService.GetApiEdrIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Edr>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiEdrIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiEdrIdGet(params: {
    id: string;
  }): Observable<Edr> {

    return this.getApiEdrIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Edr>) => r.body as Edr)
    );
  }

  /**
   * Path part for operation deleteApiEdrIdDelete
   */
  static readonly DeleteApiEdrIdDeletePath = '/api/edr/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiEdrIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiEdrIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, EdrService.DeleteApiEdrIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiEdrIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiEdrIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiEdrIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation edRgetdefaultedrApiEdrGetdefaultedrPost
   */
  static readonly EdRgetdefaultedrApiEdrGetdefaultedrPostPath = '/api/edr/getdefaultedr';

  /**
   * Edrgetdefaultedr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `edRgetdefaultedrApiEdrGetdefaultedrPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edRgetdefaultedrApiEdrGetdefaultedrPost$Response(params: {
    body: GetdefaultedrParams
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, EdrService.EdRgetdefaultedrApiEdrGetdefaultedrPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Edrgetdefaultedr.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `edRgetdefaultedrApiEdrGetdefaultedrPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  edRgetdefaultedrApiEdrGetdefaultedrPost(params: {
    body: GetdefaultedrParams
  }): Observable<any> {

    return this.edRgetdefaultedrApiEdrGetdefaultedrPost$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
