<ng-container *ngIf="!currentCompany">
  <div class="d-flex flex-row align-items-center mb-2" *ngIf="masterInsights">
    <div class="d-flex flex-column bor-1 card mat-elevation-z4 p-2 mr-4 pull-up col-lg-3">
      <span class="fs1-2 mb-3">{{masterInsights.totalCompanies}}</span>
      <span class="fs1 fw2">Total Companies</span>
    </div>
    <div class="d-flex flex-column bor-1 card mat-elevation-z4 p-2 mr-4 pull-up col-lg-3">
      <span class="fs1-2 mb-3">{{masterInsights.totalAssets}}</span>
      <span class="fs1 fw2">Total Assets</span>
    </div>
    <div class="spacer"></div>
    <button [matMenuTriggerFor]="menu" class="mr-2" mat-icon-button color="primary" matTooltip="Download Report">
      <mat-icon class="mat-24">file_download</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button (click)="downloadReport('', 'docx')" mat-menu-item>
        <img class="mat-32 me-2" src="/assets/images/reports/docx.png" alt="docx">
        <span>Download as docx</span>
      </button>
      <button (click)="downloadReport('', 'xlsx')" mat-menu-item>
        <img class="mat-32 me-2" src="/assets/images/reports/xlsx.png" alt="xlsx">
        <span>Download as xlsx</span>
      </button>
    </mat-menu>

  </div>
  <app-sa-table *ngIf="insightsData && insightsData.length"
                [showHeaderUpperCase]="showUpper" [columnsList]="insightsCols"
                [customCols]="insightsCustomCols" (refreshCallback)="getPartnerInsights()"
                (hyperlinkCallback)="showDetails($event)" [dataList]="insightsData">
  </app-sa-table>
  <p *ngIf="insightsData && !insightsData.length" class="text-center"> No data available. </p>
  <app-s-t-shimmer *ngIf="!insightsData"></app-s-t-shimmer>
  <h1>&nbsp;</h1>
</ng-container>
<mat-card *ngIf="currentCompany">
  <mat-card-header>
    <mat-card-title>{{currentCompany.companyName}}</mat-card-title>
    <mat-card-subtitle>
      <div class="d-flex flex-row align-items-center">
        <span class="mr-2">Total Assets: <span class="badge badge-primary fs--8">{{currentCompany.totalAssets}}</span></span>
        <span class="mr-2">Regular Assets: <span class="badge badge-primary fs--8">{{currentCompany.assets}}</span></span>
        <span class="mr-2">External Assets: <span class="badge badge-primary fs--8">{{currentCompany.external}}</span></span>
        <span class="spacer"></span>
        <span class="mr-2">
          <!--<button (click)="downloadReport(currentCompany.companyId)" mat-icon-button=""
           color="primary" matTooltip="Download Report">
            <mat-icon class="mat-24">file_download</mat-icon>
          </button>-->
          <button [matMenuTriggerFor]="assetMenu" class="mr-2" mat-icon-button color="primary" matTooltip="Download Report">
            <mat-icon class="mat-24">file_download</mat-icon>
          </button>
          <mat-menu #assetMenu="matMenu">
            <button (click)="downloadReport(currentCompany.companyId, 'docx')" mat-menu-item>
              <img class="mat-32 me-2" src="/assets/images/reports/docx.png" alt="docx">
              <span>Download as docx</span>
            </button>
            <button (click)="downloadReport(currentCompany.companyId, 'xlsx')" mat-menu-item>
              <img class="mat-32 me-2" src="/assets/images/reports/xlsx.png" alt="xlsx">
              <span>Download as xlsx</span>
            </button>
          </mat-menu>
        </span>
        <span class="mr-2">
          <button (click)="currentCompany = undefined" mat-icon-button="" color="primary" matTooltip="Back to overview">
            <mat-icon class="mat-24">close</mat-icon>
          </button>
        </span>
      </div>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="currentCompany.assets">
    <mat-list dense class="w-100 bor-1" >
      <mat-list-item class="bb-1">
        <span class="text-uppercase">OS</span><span class="spacer"></span>
        <span class="text-uppercase">Count</span>
      </mat-list-item>
      <mat-list-item class="bb-1" *ngFor="let data of currentCompany.details; let i = index;">
        <span>{{data.os}}</span>
        <span class="spacer"></span>
        <span class="badge badge-primary fs--8">{{data.count}}</span>
      </mat-list-item>
    </mat-list>
    <h1>&nbsp;</h1>
  </mat-card-content>
  <mat-card-content *ngIf="currentCompany.assets === 0">
    <mat-list dense class="w-100 bor-1">
      <span>&nbsp; No Assets Available.</span>
    </mat-list>
  </mat-card-content>
</mat-card>
