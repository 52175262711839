<div class="mt-2 pl-3 row mat-primary" *ngIf="true">
  <div class="search ml-3">
    <input  class="search-txt placeholder-text" autocomplete="off" [(ngModel)]="reportFilters" type="text" name="" id="search" placeholder="Search..." (keyup.enter)="handleClickValue()"  (click)="handleChangeFilter($event)" (keypress)="handleChangeFilter($event)">
    <button mat-icon-button class="search-btn" *ngIf="showSearch" [disabled]="true"><mat-icon class="mat-18 mb-1">search</mat-icon></button>
    <button mat-icon-button class="search-btn" *ngIf="!showSearch" (click)="reportFilters= ''; showSearch=true; showList=0"><mat-icon class="mat-18 mb-1">close</mat-icon></button>
    <ng-container *ngIf="showList === 1">
      <ul class="list">
        <li class="grptitle" *ngIf="!noFilters">Filter Key</li>
        <li class="grptitle" *ngIf="noFilters">No filter keys</li>
        <ng-container *ngFor="let item of Objectkeys(companyTags)">
          <li class="pointer listshow" (click)="handledFilterKey(item)" *ngIf="companyTags[item] && companyTags[item].length" [value]="item"> {{item}}</li>
        </ng-container>
      </ul>
    </ng-container>
    <ng-container *ngIf="showList === 2">
      <ul class="list">
        <li class="grptitle">Operators</li>
        <li class="pointer listshow" (click)="handleChangeOperator(item.key)" *ngFor="let item of operators" [value]="item">
          <span class="column">
            <span class="searchData">{{ reportFilters }}</span><span> {{item.key}}</span>
            <p class="opTitle">{{item.title}}</p>
          </span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="showList === 3">
      <ul class="list overflow-auto h_200">
        <li class="pointer listshow" (click)="handleClickValue(item)" *ngFor="let item of tagsList" [value]="item">{{item}}</li>
      </ul>
    </ng-container>
  </div>
  <mat-form-field class="col-10 mb-2 mt-1" appearance="outline">
    <mat-label>Filters</mat-label>
    <mat-chip-list #insecureList aria-label="port selection">
      <mat-chip class="insecureo" *ngFor="let filter of filteredList" [selectable]="selectable" [removable]="removable"
                (removed)="removeFilter(filter)">
        {{filter}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input type="text" trim="blur" readonly autocomplete="off" name="insecure"
              placeholder="" [matChipInputFor]="insecureList" >
    </mat-chip-list>
  </mat-form-field>
  <button type="button" class="tprime btn-styl px-1 mr-2 mb-4" (click)="filteredList=[]">
    <span class="d-flex flex-row align-items-center">
      <i class="ms-Icon ms-font-lg ms-Icon&#45;&#45;Delete px-1 ms-fontColor-themePrimary"></i>
      <span class="ibtn pl-1" id="AgentVulnerabilityScan"> Clear filters</span>
    </span>
  </button>
</div>
