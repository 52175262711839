import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CompanyService } from 'src/app/api/services';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CompanySharedService } from 'src/app/_services/company-shared.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { MyToastrService } from 'src/app/_services/toastr.service';

@Component({
  selector: 'app-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.scss']
})
export class ReportFiltersComponent implements OnInit,OnDestroy {
  @Input() companyId?: any;
  @Input() filterData: any;
  operators: any = [{
    key: "=",
    title: "equals",
    tag: ""
  },
  {
    key: "!=",
    title: "notEquals",
    tag: ""
  },
  {
    key: ":",
    title: "contains",
    tag: ""
  },
  {
    key: "!:",
    title: "doesNotContains",
    tag: ""
  }
  
  ];
  removable = true;
  selectable = true;
  noFilters = false;
  Objectkeys = Object.keys;
  tagsList: any = ["one", "two"];
  selectedFilter: any = '';
  filteredList: any =  [];
  companyTags: any = {};
  searchFilter = '';
  reportFilters: any = '';
  showSearch = true;
  showList = 0;
  filterRequest: any = {};
  companyChangeSubscription:any = [];

  constructor(private renderer: Renderer2,
    private loaderService: LoaderService, private toast: MyToastrService, public baseService: BaseRequestService,
    private companyService: CompanyService, private cs: CompanySharedService) { 
      this.companyChangeSubscription = this.cs.companyChange.subscribe((value: any) => {
        this.getReportFilters(value);
      });
      cs.FilterEVE.subscribe((value: any) => {
        this.filteredList = [];
        this.cs.filterList = {
          uifilters: [],
          reportfilters: {}
        }
      });
     }

  ngOnInit(): void {
    (this.companyId) ? this.getReportFilters(this.companyId): null;
    (this.filterData) ? this.filteredList = this.filterData : [];
  }

  ngOnDestroy() {
    if (this.companyChangeSubscription) {
      this.companyChangeSubscription.unsubscribe();
    }
  }

  handleChangeFilter(data: any): void {
    if(!data.target?.value.length && this.showList === 0){ this.showList = 1; this.showSearch = false;};
    if(data.target?.value.length === 0 && this.showList === 0) { this.showList = 0; this.showSearch = true;};
  }

  handledFilterKey(item: any): void {
    this.reportFilters = item;
    this.selectedFilter = item;
    this.showList = 2;
  }

  handleChangeOperator(oper: any): void {
    if(oper === ':' || oper === '!:'){
      this.showList = 4;
      setTimeout(() => this.renderer.selectRootElement('#search').focus(), 0);
    } else {
      this.tagsList = this.companyTags[this.reportFilters];
      this.showList = 3;
    }
    this.reportFilters = `${this.reportFilters}${oper}`;
  }

  closeList(): void {
    this.showSearch = true;
    this.showList = 0;
  }

  handleClickValue(val?:any): void {
    if(val){
      this.filteredList.push(`${this.reportFilters}${val}`);
    } else {
      let data = this.reportFilters.indexOf('!:')> -1 ? this.reportFilters.split('!:') : this.reportFilters.split(':');
      if(!data[1] || data[1] === '' || data[1] === ' ' || data[1] === '  '){
        this.toast.sToast('error', 'Please enter a value');
        return;
      }else {
        this.filteredList.push(`${this.reportFilters}`);
      }
    }
    this.reportFilters = '';
    this.showList = 0;
    this.showSearch = true;
    this.getFilter();
  }

  removeFilter(filter: any): void{
    const index = this.filteredList.indexOf(filter);
    if (index >= 0) {
      this.filteredList.splice(index, 1);
      this.getFilter();
    }
  }

  getFilter(): any {
    const filteredTags: any = {
      equals: {},
      contains: {},
      notEquals: {},
      doesNotContain: {}
    };
    this.filteredList.map((list:any) => {
      let data : any = [];
      if(list.indexOf('!=') > -1) {
        data = list.split('!=');
        if(!filteredTags.notEquals[data[0]]){ filteredTags.notEquals[data[0]] = [] };
        filteredTags.notEquals[data[0]].push(data[1])
      } else if (list.indexOf('!:') > -1) {
        data = list.split('!:');
        if(!filteredTags.doesNotContain[data[0]]){ filteredTags.doesNotContain[data[0]] = [] };
        filteredTags.doesNotContain[data[0]].push(data[1])
      } else if (list.indexOf('=') > -1) {
        data = list.split('=');
        if(!filteredTags.equals[data[0]]){ filteredTags.equals[data[0]] = [] };
        filteredTags.equals[data[0]].push(data[1])
      } else if (list.indexOf(':') > -1) {
        data = list.split(':');
        if(!filteredTags.contains[data[0]]){ filteredTags.contains[data[0]] = [] };
        filteredTags.contains[data[0]].push(data[1])
      }
    })
    this.cs.filterList = { reportfilters: filteredTags, uifilters: this.filteredList };
  }
  
  getReportFilters(id: any): void {
    this.baseService.doRequest('/api/standardreports/getReportFilters', 'post', {companyid: id}).subscribe((res: any) => {
      if(res) { this.companyTags = res;  this.noFilters = false;}
      else {this.noFilters = true;};
    });
  }
}
