<mat-card class="o-loading mb-2">
  <mat-card-content class="o-loading__body">
    <table class="mat-table table w-100">
      <thead><tr class="mat-header-row">
        <th class="mat-header-cell"><p class="shimmer-box w-100"></p></th>
        <th class="mat-header-cell"><p class="shimmer-box w-100"></p></th>
        <th class="mat-header-cell"><p class="shimmer-box w-100"></p></th>
        <th class="mat-header-cell"><p class="shimmer-box w-100"></p></th>
        <th class="mat-header-cell"><p class="shimmer-box w-100"></p></th>
      </tr></thead>
      <tbody><tr *ngFor="let row of [1, 2, 3]">
        <td class="mat-cell"><p class="shimmer-box w-100"></p></td>
        <td class="mat-cell"><p class="shimmer-box w-100"></p></td>
        <td class="mat-cell"><p class="shimmer-box w-100"></p></td>
        <td class="mat-cell"><p class="shimmer-box w-100"></p></td>
        <td class="mat-cell"><p class="shimmer-box w-100"></p></td>
      </tr></tbody>
    </table>
  </mat-card-content>
</mat-card>
