/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ADuserdata } from '../models/a-duserdata';
import { ADuserdataCreate } from '../models/a-duserdata-create';
import { AssetAdGetResp } from '../models/asset-ad-get-resp';

@Injectable({
  providedIn: 'root',
})
export class ADuserdataService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiADuserdataGet
   */
  static readonly GetAllApiADuserdataGetPath = '/api/ADuserdata/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiADuserdataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiADuserdataGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<AssetAdGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, ADuserdataService.GetAllApiADuserdataGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssetAdGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiADuserdataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiADuserdataGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<AssetAdGetResp> {

    return this.getAllApiADuserdataGet$Response(params).pipe(
      map((r: StrictHttpResponse<AssetAdGetResp>) => r.body as AssetAdGetResp)
    );
  }

  /**
   * Path part for operation updateApiADuserdataPut
   */
  static readonly UpdateApiADuserdataPutPath = '/api/ADuserdata/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiADuserdataPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiADuserdataPut$Response(params: {
    body: ADuserdata
  }): Observable<StrictHttpResponse<ADuserdata>> {

    const rb = new RequestBuilder(this.rootUrl, ADuserdataService.UpdateApiADuserdataPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADuserdata>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiADuserdataPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiADuserdataPut(params: {
    body: ADuserdata
  }): Observable<ADuserdata> {

    return this.updateApiADuserdataPut$Response(params).pipe(
      map((r: StrictHttpResponse<ADuserdata>) => r.body as ADuserdata)
    );
  }

  /**
   * Path part for operation createApiADuserdataPost
   */
  static readonly CreateApiADuserdataPostPath = '/api/ADuserdata/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiADuserdataPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiADuserdataPost$Response(params: {
    body: ADuserdataCreate
  }): Observable<StrictHttpResponse<ADuserdata>> {

    const rb = new RequestBuilder(this.rootUrl, ADuserdataService.CreateApiADuserdataPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADuserdata>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiADuserdataPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiADuserdataPost(params: {
    body: ADuserdataCreate
  }): Observable<ADuserdata> {

    return this.createApiADuserdataPost$Response(params).pipe(
      map((r: StrictHttpResponse<ADuserdata>) => r.body as ADuserdata)
    );
  }

  /**
   * Path part for operation getApiADuserdataIdGet
   */
  static readonly GetApiADuserdataIdGetPath = '/api/ADuserdata/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiADuserdataIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiADuserdataIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ADuserdata>> {

    const rb = new RequestBuilder(this.rootUrl, ADuserdataService.GetApiADuserdataIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ADuserdata>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiADuserdataIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiADuserdataIdGet(params: {
    id: string;
  }): Observable<ADuserdata> {

    return this.getApiADuserdataIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ADuserdata>) => r.body as ADuserdata)
    );
  }

  /**
   * Path part for operation deleteApiADuserdataIdDelete
   */
  static readonly DeleteApiADuserdataIdDeletePath = '/api/ADuserdata/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiADuserdataIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiADuserdataIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ADuserdataService.DeleteApiADuserdataIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiADuserdataIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiADuserdataIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiADuserdataIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
