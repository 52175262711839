/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Installedprogramwithoutpath } from '../models/installedprogramwithoutpath';
import { InstalledprogramwithoutpathCreate } from '../models/installedprogramwithoutpath-create';
import { InstalledprogramwithoutpathGetResp } from '../models/installedprogramwithoutpath-get-resp';

@Injectable({
  providedIn: 'root',
})
export class InstalledprogramwithoutpathService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiInstalledprogramwithoutpathGet
   */
  static readonly GetAllApiInstalledprogramwithoutpathGetPath = '/api/Installedprogramwithoutpath/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiInstalledprogramwithoutpathGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiInstalledprogramwithoutpathGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }
): Observable<StrictHttpResponse<InstalledprogramwithoutpathGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, InstalledprogramwithoutpathService.GetAllApiInstalledprogramwithoutpathGetPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
      })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstalledprogramwithoutpathGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllApiInstalledprogramwithoutpathGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiInstalledprogramwithoutpathGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }
): Observable<InstalledprogramwithoutpathGetResp> {

    return this.getAllApiInstalledprogramwithoutpathGet$Response(params).pipe(
      map((r: StrictHttpResponse<InstalledprogramwithoutpathGetResp>) => r.body as InstalledprogramwithoutpathGetResp)
    );
  }

  /**
   * Path part for operation updateApiInstalledprogramwithoutpathPut
   */
  static readonly UpdateApiInstalledprogramwithoutpathPutPath = '/api/Installedprogramwithoutpath/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiInstalledprogramwithoutpathPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiInstalledprogramwithoutpathPut$Response(params: {
    body: Installedprogramwithoutpath
  }
): Observable<StrictHttpResponse<Installedprogramwithoutpath>> {

    const rb = new RequestBuilder(this.rootUrl, InstalledprogramwithoutpathService.UpdateApiInstalledprogramwithoutpathPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Installedprogramwithoutpath>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateApiInstalledprogramwithoutpathPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiInstalledprogramwithoutpathPut(params: {
    body: Installedprogramwithoutpath
  }
): Observable<Installedprogramwithoutpath> {

    return this.updateApiInstalledprogramwithoutpathPut$Response(params).pipe(
      map((r: StrictHttpResponse<Installedprogramwithoutpath>) => r.body as Installedprogramwithoutpath)
    );
  }

  /**
   * Path part for operation createApiInstalledprogramwithoutpathPost
   */
  static readonly CreateApiInstalledprogramwithoutpathPostPath = '/api/Installedprogramwithoutpath/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiInstalledprogramwithoutpathPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiInstalledprogramwithoutpathPost$Response(params: {
    body: InstalledprogramwithoutpathCreate
  }
): Observable<StrictHttpResponse<Installedprogramwithoutpath>> {

    const rb = new RequestBuilder(this.rootUrl, InstalledprogramwithoutpathService.CreateApiInstalledprogramwithoutpathPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
      })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Installedprogramwithoutpath>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApiInstalledprogramwithoutpathPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiInstalledprogramwithoutpathPost(params: {
    body: InstalledprogramwithoutpathCreate
  }
): Observable<Installedprogramwithoutpath> {

    return this.createApiInstalledprogramwithoutpathPost$Response(params).pipe(
      map((r: StrictHttpResponse<Installedprogramwithoutpath>) => r.body as Installedprogramwithoutpath)
    );
  }

  /**
   * Path part for operation getApiInstalledprogramwithoutpathIdGet
   */
  static readonly GetApiInstalledprogramwithoutpathIdGetPath = '/api/Installedprogramwithoutpath/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiInstalledprogramwithoutpathIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiInstalledprogramwithoutpathIdGet$Response(params: {
    id: string;
  }
): Observable<StrictHttpResponse<Installedprogramwithoutpath>> {

    const rb = new RequestBuilder(this.rootUrl, InstalledprogramwithoutpathService.GetApiInstalledprogramwithoutpathIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
        })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Installedprogramwithoutpath>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiInstalledprogramwithoutpathIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiInstalledprogramwithoutpathIdGet(params: {
    id: string;
  }
): Observable<Installedprogramwithoutpath> {

    return this.getApiInstalledprogramwithoutpathIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Installedprogramwithoutpath>) => r.body as Installedprogramwithoutpath)
    );
  }

  /**
   * Path part for operation deleteApiInstalledprogramwithoutpathIdDelete
   */
  static readonly DeleteApiInstalledprogramwithoutpathIdDeletePath = '/api/Installedprogramwithoutpath/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiInstalledprogramwithoutpathIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiInstalledprogramwithoutpathIdDelete$Response(params: {
    id: string;
  }

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, InstalledprogramwithoutpathService.DeleteApiInstalledprogramwithoutpathIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApiInstalledprogramwithoutpathIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiInstalledprogramwithoutpathIdDelete(params: {
    id: string;
  }

): Observable<any> {

    return this.deleteApiInstalledprogramwithoutpathIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
