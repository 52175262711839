import {Component,Input,ViewChild, OnInit} from '@angular/core';
import {NavigationEnd, NavigationError, Router} from '@angular/router';
import { LayoutComponent } from 'src/app/admin/layout/layout.component';
import { CompanySharedService } from 'src/app/_services/company-shared.service';
import { MyToastrService } from 'src/app/_services/toastr.service';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit {
  gettingStartedList: any = [];
  @Input() gettingStarted = false;
  @Input() sidenavVisible = false;
  constructor( private sidenavcomp: LayoutComponent, private router: Router, private cs: CompanySharedService, private toast: MyToastrService) {
    this.gettingStartedList = [
      {
        title: 'Welcome to CyberCNS',
        description: '<strong>Discover</strong>: Automatically discover all your Network assets with our deep asset discovery capability.<br>\n' +
          ' <strong>Optimize</strong>: Monitor your Network Assets for Uptime, Configuration issues and much more.<br>\n' +
          ' <strong>Secure</strong>: Track Network Vulnerabilities and Exploits including support for Active Directory, Office 365 and more.',
        highlight: '',
        link: 'https://cybercns.atlassian.net/l/c/aesHmSqj',
        linkDesc: 'Check out the ',
        linkText: 'Glossary of Terms',
        img: '/assets/getting-started/welcome.png',
        time: '',
      },
      {
        title: 'Scan your external attack surface',
        description: 'Add your websites and internet-facing IP addresses and CyberCNS will scan them from external scanners installed on the CyberCNS instance.',
        highlight: 'Ideal for company websites, VPN servers, and firewalls.',
        link: 'https://cybercns.atlassian.net/l/cp/S0w1NSon',
        linkDesc: 'Learn more about ',
        linkText: 'External Scans',
        img: '/assets/getting-started/worldwide.png',
        time: '',
        button: 'ScanNow'
      },
      {
        title: 'Connect a device directly',
        description: 'CyberCNS Light Weight Agent monitors single endpoint from anywhere and offers the most in-depth scans with zero configuration.',
        highlight: 'Ideal for mobile or isolated assets, such as laptops and cloud servers',
        link: 'https://cybercns.atlassian.net/l/c/dg0013zX',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Lightweight Agents',
        img: '/assets/getting-started/key.png',
        time: '',
        button: 'ConnectNow'
      },
      {
        title: 'Connect your Network Devices',
        description: 'CyberCNS Probe/Agent monitors all networks and all connected assets including IoT, networking gear, and other unmanaged assets.',
        highlight: 'Deploy on servers, workstations, or as a virtual appliance',
        link: 'https://cybercns.atlassian.net/l/c/jPRMNiRY',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Probes/Agents',
        img: '/assets/getting-started/agents.png',
        time: '',
        button: 'ConnectNow'
      },
      {
        title: 'Connect your Active Directory',
        description: 'The Active Directory Scan is performed using the SMB protocol. Once the scan is initiated, it will collect relevant data from the Active Directory server and send that to your CyberCNS instance',
        highlight: 'Deploy it on Domain Controllers, servers, or workstations',
        link: 'https://cybercns.atlassian.net/l/c/iZXsztjG',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Active Directory SCAN',
        img: '/assets/getting-started/ad.png',
        time: '',
        button: 'ConnectAD'
      },
      {
        title: 'Integrate with many providers, vendors',
        description: 'CyberCNS supports PSA Integrations such as ConnectWise, AutoTask & SyncroMSP to help integrate to ticketing systems, and other third-party products, (email and such)',
        highlight: 'Other integrations like Jira, Microsoft Teams, Email, Slack, Generic Webhook',
        link: 'https://cybercns.atlassian.net/l/c/Et0kQADk',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Integrations',
        img: '/assets/getting-started/worldwide.png',
        time: '',
        button: 'ConnectInt'
      },
      {
        title: 'View Remediation Plan',
        description: 'CyberCNS remediation plan is generated based on successful scans performed on your Assets. This plan helps the security team to plan your next steps for securing the assets.',
        highlight: 'Missing security patches for the operating system and latest application versions',
        link: 'https://cybercns.atlassian.net/l/c/6fhZ9cDP',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Remediation Plan',
        img: '/assets/getting-started/bug.png',
        time: '',
        button: 'RemediationPlan'
      },
      {
        title: 'Patching',
        description: 'CyberCNS has capability to patch 3rd party applications using the Remediation plan.',
        highlight: 'Security patches for the operating system and latest application versions',
        link: 'https://cybercns.atlassian.net/l/c/6fhZ9cDP',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Patching',
        img: '/assets/getting-started/bug.png',
        time: '',
        button: 'Patching'
      },
      {
        title: 'Create Notification Rules',
        description: 'In CyberCNS, you can get notified of certain conditions based on your rule-set. This user-defined rule can be applied at the Company level or Globally.',
        highlight: 'Get notified using the notification rule-set',
        link: 'https://cybercns.atlassian.net/l/c/aHUdJQxv',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Notification Rules',
        img: '/assets/getting-started/reminder.png',
        time: '',
        button: 'NotificationRule'
      },
      {
        title: 'Manage Application Baseline',
        description: 'CyberCNS can help you define a Mandatory or Denied list of application(s) at the Company level and report non compliant applications, based on the rule-set.',
        highlight: 'Define a Mandatory or Denied list of application(s)',
        link: 'https://cybercns.atlassian.net/l/c/H2Zu7kLV',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Application Baseline',
        img: '/assets/getting-started/application.png',
        time: '',
        button: 'ApplicationBaseline'
      },
      {
        title: 'Create Roles',
        description: 'CyberCNS supports Role Based Access Control. Users can be created, access to specific tasks can be restricted to specific uses.',
        highlight: 'Create a new role and assign tasks',
        link: 'https://cybercns.atlassian.net/l/c/wfvEKF5W',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Roles',
        img: '/assets/getting-started/role.png',
        time: '',
        button: 'Roles'
      },
      {
        title: 'Manage Users',
        description: 'The User view allows creating User logins for all your customer support and security specialists. It can also define the role they will play (eg. User or administrator).',
        highlight: 'Assign RBAC to users',
        link: 'https://cybercns.atlassian.net/l/c/3jX0GX3m',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Users',
        img: '/assets/getting-started/users.png',
        time: '',
        button: 'Users'
      },
      {
        title: 'Manage Scheduler',
        description: 'CyberCNS allows you to Schedule repetitive tasks for different Scan Types such as the creation of reports using the Scheduler functionality.',
        highlight: '',
        link: 'https://cybercns.atlassian.net/l/c/mx1jckuf',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Scheduler',
        img: '/assets/getting-started/scheduler.png',
        time: '',
        button: 'Scheduler'
      },
      {
        title: 'Reports and Report Builder',
        description: 'CyberCNS provides very exhaustive reports in various usable formats such as Excel, Word, Powerpoint. CyberCNS Report Builder allows you to build a report with different templates.',
        highlight: 'Generate, Schedule Reports',
        link: 'https://cybercns.atlassian.net/l/c/ZWm2NS9t',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Reports',
        img: '/assets/getting-started/report.png',
        time: '',
        button: 'ReportBuilder'
      },
      {
        title: 'Get support',
        description: '<p>\n' +
          '          Visit our Knowledge Base' +
          '          for detailed instructions, FAQs, and more.\n' +
          '          You can also submit a support request email to \n' +
          '          <a class="text-primary" href="mailto:support@cybercns.com" target="_top">support@cybercns.com</a>.\n' +
          '        </p>',
        highlight: '',
        link: 'https://cybercns.atlassian.net/wiki/spaces/Verison2/pages/1569947788/CyberCNS+V2+Overview',
        linkDesc: 'Learn more about ',
        linkText: 'CyberCNS Knowledge Base',
        img: '/assets/getting-started/support.png',
        time: '',
      },
    ];
  }

  ngOnInit(): void {
  }

  externalScan() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('companies/company');
    setTimeout(() => {
      this.cs.ExtScanEVE.next({});
    });
  }

  Connectnow() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('companies/company');
    setTimeout(() => {
      this.cs.AgentpopupEVE.next({});
    });
  }

  ConnectAD() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('companies/company');
    setTimeout(() => {
      this.cs.ADScanEVE.next({});
    });
  }

  Connectint() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('/global');
    setTimeout(() => {
      this.cs.getstartintEVE.next({value: 'Integrations'});
    });
  }

  RemediationPlan() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('companies/company');
    setTimeout(() => {
      this.cs.getstartRemEVE.next({});
    });
  }
  NotificationRule() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('companies/company');
    setTimeout(() => {
      this.cs.getstartNotificationEVE.next({});
    });
  }

  ApplicationBaseline() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('companies/company');
    setTimeout(() => {
      this.cs.getstartApplicationBaselineEVE.next({});
    });
  }

  Roles() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('/global');
    setTimeout(() => this.cs.getstartRolesEVE.next({}),2000);
    
  }

  Users() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('/global');
    setTimeout(() => {
      this.cs.getstartUsersEVE.next({});
    },2000);
    
  }

  Scheduler() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('/global');
    setTimeout(() => {
      this.cs.getstartSchedulerEVE.next({});
    },2000);
    
  }

  ReportBuilder() {
    this.sidenavVisible = false;
    setTimeout(() => this.sidenavcomp.sidenav.close());
    this.router.navigateByUrl('/global');
    setTimeout(() => {
      this.cs.getstartReportBuilderEVE.next({});
    },1000);
  }

}
