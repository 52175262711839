<ng-container>
    <app-s-table *ngIf="!isAddOrEdit" aria-label="Schedulers" role="table" [sTableOptions]="scheTableOptions"
      (sortCallback)="sortCall($event)" (addCallback)="addScheduler()" (filterCallback)="filterCall($event)"
      (actionCallback)="actionCall($event)" (pageCallback)="pageCall($event)" (refreshCallback)="getSchedulers()"
      (timerCallback)="getSchedulers()" (globalActionCallback)="globalActionCall($event)"></app-s-table>
    <div *ngIf="isAddOrEdit">
      <mat-card>
        <form class="example-form" #scheForm="ngForm" (ngSubmit)='saveScheduler(scheDetailsObj, scheForm)'>
          <mat-card class="modal">
            <button type="button" class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
              (click)="close(scheForm);" id="schClsBtn1">
              <mat-icon>close</mat-icon>
            </button>
            <span class="mat-title">{{scheTitle}} Scheduler</span>
            <mat-card-content>
              <div class="form-container">
                <table cellspacing="0">
                  <tr>
                    <td class="pr-6">
                      <mat-form-field class="pl-2" placeholder="Name">
                        <mat-label>Name</mat-label>
                        <input matInput type="text" [(ngModel)]="scheDetailsObj.name" required matInput autocomplete="off"
                          placeholder="Enter Scheduler Name" name="name" id="schName">
                        <mat-error>
                          Scheduler Name is required
                        </mat-error>
                      </mat-form-field>
                    </td>
                    <td class="pr-6" *ngIf="false">
                      <mat-form-field class="pl-2">
                        <mat-label>Scheduler Type</mat-label>
                        <mat-select name="schedulertype" id="schedulerDropdown"
                          [(ngModel)]="scheDetailsObj.scheduler_type"
                          (selectionChange)="($event.value !== 'scan') ? changeScanType($event.value) : null"
                          required>
                          <mat-option *ngFor="let scheduler of schedulerTypeListNew| sortBy:'asc':'name'" [value]="scheduler.uniqueId"
                            id="{{scheduler.name}}opt"> {{scheduler.name}} </mat-option>
                        </mat-select>
                        <mat-error> Scheduler Type is required</mat-error>
                      </mat-form-field>
                    </td>
                    <td class="pr-6" *ngIf="scheDetailsObj.scheduler_type === 'scan'">
                      <mat-form-field class="pl-2">
                        <mat-label>Scan Type</mat-label>
                        <mat-select name="scanType" id="scantypeDropdown" [(ngModel)]="scheDetailsObj.uniqueid"
                          (selectionChange)="changeScanType($event.value)" required>
                          <ng-container *ngFor="let scanType of schedulerTypeList| sortBy:'asc':'name'">
                            <mat-option *ngIf="scanType.uniqueId !== 'standard_report_generation'"
                              [value]="scanType.uniqueId" id="{{scanType.name}}opt"> {{scanType.name}} </mat-option>
                          </ng-container>
                        </mat-select>
                        <mat-error> ScanType is required</mat-error>
                      </mat-form-field>
                    </td>
                    <td class="pr-6">
                      <mat-form-field class="pl-2" *ngIf="scheDetailsObj.level === 'company'">
                        <mat-select #companySelect [multiple]="true" required class="" placeholder="Select companies"
                          name="Company" (openedChange)="closeCurrentCompany($event)"
                          [(ngModel)]="scheDetailsObj.companyid" [formControl]="companyCtrl"
                          (selectionChange)="scheDetailsObj.companyid = ($event.value.includes('*')) ? ['*'] : $event.value; updateCurrentSite($event)">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="companyFilterCtrl"
                              [placeholderLabel]="'Search Company'"
                              [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngIf="!currentCompany" matTooltip="All Companies" value="*">All Companies</mat-option>
                          <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async"
                            [value]="company._id">
                            {{company.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="pl-2" *ngIf="scheDetailsObj.level === 'agent'">
                        <mat-label>Agents</mat-label>
                        <mat-select name="agents" id="agentsDropdown" multiple="true" required
                          [(ngModel)]="scheDetailsObj.agentid" (selectionChange)="updateCurrentAgent($event.value)">
                          <mat-option *ngFor="let agent of agentList| sortBy:'asc':'name'" [value]="agent._id" id="opt{{agent.name}}">
                            {{agent.name}} - {{agent.companyRef.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error> Agent is required</mat-error>
                      </mat-form-field>
                    </td>
                    <td class="pr-6" *ngIf="scheDetailsObj.level === 'company'">
                      <mat-form-field class="pl-2">
                        <mat-select #companySelect [multiple]="true" class="" placeholder="Select excluded companies"
                          name="Company" (openedChange)="closeCurrentExCompany($event)"
                          [(ngModel)]="scheDetailsObj.excludecompany" [formControl]="companyExCtrl"
                          (selectionChange)="updateCurrentExSite($event)">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="companyExFilterCtrl"
                              [placeholderLabel]="'Search excluded company'"
                              [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option [matTooltip]="company.name" *ngFor="let company of allComp | search: companyExFilterCtrl.value"
                            [value]="company._id">
                            {{company.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-6">
                      <mat-form-field class="pl-2" placeholder="Select" *ngIf="scheDetailsObj.sublevel">
                        <mat-select #reportSelect [multiple]="true" class="mat-small"
                          [placeholder]="sublevelData.title" [required]="sublevelData.isReq" name="reportApp"
                          (selectionChange)="scheDetailsObj.subvalue = ($event.value.includes('*')) ? ['*'] : $event.value"
                          [(ngModel)]="scheDetailsObj.subvalue" [formControl]="reportCtrl">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="reportFilterCtrl" [placeholderLabel]="'Search data'"
                              [noEntriesFoundLabel]="'No matching data found'" [searching]="searching">
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option [matTooltip]="opt.name"
                            *ngFor="let opt of sublevelData.options | sortBy:'asc':'name' | search:reportFilterCtrl.value"
                            [value]="opt.value">
                            {{opt.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td *ngIf="scheDetailsObj.thirdlevel" class="pr-6">
                      <mat-form-field class="pl-2" placeholder="Select">
                        <mat-select class="mat-small" multiple="true" [(ngModel)]="scheDetailsObj.thirdvalue"
                          (selectionChange)="updateThirdLevel($event.value)" [required]="thirdlevelData.isReq"
                          name="thirdleveldata" [placeholder]="thirdlevelData.title">
                          <mat-option *ngFor="let opt of thirdlevelData.options| sortBy:'asc':'name'" [value]="opt.value"
                            id="{{opt.name}}thirdvalue">
                            {{opt.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td *ngIf="scheDetailsObj.ispassword" class="pr-6">
                      <mat-form-field class="pl-2" placeholder="Password for scheduled reports">
                        <input matInput type="password" [(ngModel)]="scheDetailsObj.password" matInput autocomplete="new-password"
                          placeholder="Enter Password" appTrim name="password" id="schNamePass">
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-6">
                      <mat-form-field class="pl-2">
                        <mat-label>Scheduler</mat-label>
                        <mat-select id="schedulerDropdown"
                          (selectionChange)="changeSchedulerType(scheDetailsObj.scheduler)" required
                          [(ngModel)]="scheDetailsObj.scheduler" name="repeat">
                          <mat-option *ngFor="let scheduler of schList| sortBy:'asc':'text'" [value]="scheduler.value" required
                            id="{{scheduler.text}}opt"> {{scheduler.text}} </mat-option>
                        </mat-select>
                        <mat-error> Scheduler is required </mat-error>
                      </mat-form-field>
                    </td>
                    <td *ngIf="yearly" class="pr-6">
                      <mat-form-field class="pl-2">
                        <mat-label>Months</mat-label>
                        <mat-select multiple="true"
                          (selectionChange)="yearlySchedulerType(scheDetailsObj.settings.months)" required
                          [(ngModel)]="scheDetailsObj.settings.months" name="months">
                          <mat-option *ngFor="let year of monthList" [value]="year.value" required
                            id="{{year.text}}opt">
                            {{year.text}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td *ngIf="monthly || yearly" class="pr-6">
                      <mat-form-field *ngIf="monthly" class="pl-2">
                        <mat-label>Days</mat-label>
                        <input #caldays matInput required readonly [(ngModel)]="scheDetailsObj.settings.days" name="days"
                          (click)="modalService.open('calPopup')" id="daysInpt">
                      </mat-form-field>
                      <s-modal id="calPopup" [width]="'28%'">
                        <span class="mat-title p-10">Choose Date(s)</span>
                        <app-custom-calendar (cancelClick)="modalService.close('calPopup')" *ngIf="monthly"
                          (finalValueChange)="MonthFinalVal($event)" [inputarray]="daysList">
                        </app-custom-calendar>
                      </s-modal>
                    </td>
                    <!--<td *ngIf="weekly">
                <mat-form-field class="pl-2" placeholder="Week">
                  <mat-select class="mat-small" multiple="true" [(ngModel)]="scheDetailsObj.settings.week"
                              name="weekl" placeholder="Week">
                    <mat-option *ngFor="let week of weekl" [value]="week.value" id="{{week.text}}week">
                      {{week.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>-->
                    <td *ngIf="weekly || hourly" class="pr-6">
                      <mat-form-field class="pl-2" placeholder="Hourly" *ngIf="hourly">
                        <mat-select class="mat-small" required [(ngModel)]="scheDetailsObj.settings.hourly" name="hourl"
                          placeholder="Hourly">
                          <mat-option *ngFor="let hr of hourl" [value]="hr.value" id="{{hr.name}}hr">
                            {{hr.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="pl-2" placeholder="Week Days" *ngIf="weekly">
                        <mat-select class="mat-small" required multiple="true" [(ngModel)]="scheDetailsObj.settings.weekdays"
                          name="weekd" placeholder="Week Days">
                          <mat-option *ngFor="let week of days" [value]="week.value" id="{{week.text}}days">
                            {{week.text}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td colspan="3" *ngIf="daily || weekly || monthly || lastDay" class="pr-6">
                      <mat-form-field class="pl-2">
                        <mat-label>Time</mat-label>
                        <input matInput placeholder="12hr format" aria-label="12hr format" type="time" class="datepicker-input" name="hour"
                           [(ngModel)]="hourMintTime" id="hrInpt">
                      </mat-form-field>
                    </td>
                  </tr>
                </table>
                <mat-form-field class="pl-2" *ngIf="asset">
                  <mat-label>Assets</mat-label>
                  <mat-select name="assets" multiple="true" required [(ngModel)]="scheDetailsObj.assets">
                    <mat-option *ngFor="let assets of deviceData| sortBy:'asc':'assetName'" [value]="assets._id" id="opt{{assets.ip}}">
                      {{assets.assetName}} - {{assets.ip}}
                    </mat-option>
                  </mat-select>
                  <mat-error> Asset is required </mat-error>
                </mat-form-field>

              </div>
            </mat-card-content>
            <div class="row">
              <div class="col-lg-12" *ngIf="scheDetailsObj.uniqueid === 'standard_report_generation' || scheDetailsObj.scheduler_type === 'standard_report_generation'">
                <p><strong>Report Notification Emails:</strong></p>
                <div class="InfoBox mb20" *ngIf="!hideEmail">
                  Note: Email integration settings not found. Please click
                  <a href="" (click)="changeToIntegration()" color="primary">here</a> to add an Email integration
                  settings.
                </div>
                <mat-list dense class="w-50 bor-1 mat-small">
                  <mat-list-item class="bb-1"
                    *ngFor="let email of scheDetailsObj.email; let i = index; trackBy:loaderService.trackByFn">
                    <mat-form-field class="w-75">
                      <input type="text" [(ngModel)]="scheDetailsObj.email[i]" matInput autocomplete="off"
                        placeholder="Enter Email Address" name="email{{i}}" id="email{{i}}">
                    </mat-form-field>
                    <span>
                      <button color="primary" mat-icon-button type="button" (click)="scheDetailsObj.email.push('')">
                        <mat-icon class="mat-18">add</mat-icon>
                      </button>
                      <button *ngIf="i !== 0" color="primary" mat-icon-button type="button"
                        (click)="scheDetailsObj.email.splice(i, 1)">
                        <mat-icon class="mat-18">delete</mat-icon>
                      </button>
                    </span>
                  </mat-list-item>
                </mat-list>

              </div>
              <div class="col-lg-12 mt-3"
                *ngIf="scheDetailsObj.uniqueid === 'standard_report_generation' || scheDetailsObj.scheduler_type === 'standard_report_generation'">
                <p><strong>Report Filters:</strong></p>
                <app-report-filters #filter [filterData]="filterData" [companyId]="companyId"></app-report-filters>
              </div>
            </div>
            <div class="ml-2 mt-2 mb-2 d-flex flex-row alight-item-center">
              <span class="mt-3 mb-3 mr-3">Active</span>
              <mat-slide-toggle name="isActive" id="schrTglBtn" class="example-margin mt-3" color="primary"
                [(ngModel)]="scheDetailsObj.isActive">
              </mat-slide-toggle>
            </div>
            <div mat-dialog-actions>
              <button type="button" mat-raised-button color="primary"
                [disabled]="!scheForm.form.valid && isEmptyArray(scheDetailsObj.email)" type="submit" class="mr-2"
                id="schSavebtn">Save
              </button>
              <button type="button" mat-button (click)="close(scheForm);" id="schClsbtn">Close</button>
            </div>
          </mat-card>
        </form>
      </mat-card>
    </div>
  </ng-container>
  <s-modal id="reportModal" >
    <mat-toolbar-row class="p-0">
      <mat-icon  class="float-left mb-2 ml-2 mat-warn">warning</mat-icon>
      <h2 class="ml-1 mt-1">Time Error</h2>
    </mat-toolbar-row>
    <div mat-dialog-content>
      <p class="center ml-2 mr-2">Time of 12:00 AM is not supported. Please set the time as either 11:59 PM or 12:01 AM.</p>
      <div mat-dialog-actions class="mt-4 ">
        <button type="button" mat-button (click)="modalService.close('reportModal');" mat-raised-button color="primary" class="mb-2 ml-2">Ok </button>
      </div>
    </div>
   </s-modal>
